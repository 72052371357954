import { observable, runInAction, action } from 'mobx';
import {
  getStorageLocationChildren,
  updateStorageLocation,
  createStorageLocation,
  deleteStorageLocation,
  getStorageLocation
} from 'api/storageLocation';
import { createStorageItem, updateStorageItem, deleteStorageItem, reorderStorageItems } from 'api/storageItem';
import { getMaterialStorageLocations } from 'api/material';
import {
  StorageLocationDTO,
  UpdateStorageLocationDTO,
  CreateStorageLocationDTO,
  MaterialStorageLocationDTO,
  MaterialStorageLocationsDTO,
  SimpleStorageLocationDTO,
  LocationSource
} from 'dto/storageLocation';
import { UpdateStorageItemDTO, CreateStorageItemDTO, UpdateSortStorageItemDTO } from 'dto/storageItem';
import { MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import DomainStore from './domainStore';
import LoadingStore from './loadingStore';

interface StorageLocationBufferDTO {
  storageLocations: SimpleStorageLocationDTO[];
  parentStorageLocationId?: string;
}

export default class StorageLocationStore {
  @observable
  private domainStore: DomainStore;

  @observable
  private loadingStore: LoadingStore;

  @observable
  materialLikeOrPackageId?: MaterialLikeIdOrPackageIdDTO;

  @observable
  materialStorageLocations: MaterialStorageLocationsDTO[] = [];

  @observable
  storageLocationsBuffer: StorageLocationBufferDTO[] = [];

  @observable
  selectedStorageLocation?: StorageLocationDTO;

  @observable
  selectedMaterialStorageLocation?: StorageLocationDTO;

  @observable
  selectedStorageLocationIndex = 0;

  @observable
  currentIndex = 0;

  @observable
  selectedIndexToCreate = 0;

  @observable
  isCreateFlyoutOpen = false;

  @observable
  isDeleteFlyoutOpen = false;

  @observable
  selectedParentId?: string;

  // isDetailDrawerOpen is only for mobile
  @observable
  isDetailDrawerOpen = false;

  @observable
  isSelectLocationFlyoutOpen = false;

  @observable
  isSelectMaterialLocationFlyoutOpen = false;

  @observable
  selectedMaterialFlyoutCallback?: (storageLocationId: string | null) => Promise<void>;

  @observable
  private lastSelectedLocationSource?: LocationSource;

  constructor(domainStore: DomainStore, loadingStore: LoadingStore) {
    this.domainStore = domainStore;
    this.loadingStore = loadingStore;
  }

  async loadLocations() {
    const storageLocations = await this.loadingStore.withLoadingBar(() =>
      getStorageLocationChildren({
        functionalAreaId: this.domainStore.currentFunctionalArea.id,
        locationSource: LocationSource.KPS
      })
    );
    runInAction(() => {
      this.storageLocationsBuffer = [
        {
          storageLocations
        }
      ];
    });
  }

  async loadMaterialLikeLocations(preselectById?: string, locationSource?: LocationSource) {
    if (!this.materialLikeOrPackageId) return;
    const materialStorageLocations = await this.loadingStore.withLoadingBar(async () => {
      if (!this.materialLikeOrPackageId) return undefined;
      return getMaterialStorageLocations({
        ...this.materialLikeOrPackageId,
        functionalAreaId: this.domainStore.currentFunctionalArea.id,
        locationSource
      });
    });
    await runInAction(() => {
      if (!materialStorageLocations) {
        return;
      }
      this.lastSelectedLocationSource = locationSource;
      this.materialStorageLocations = materialStorageLocations;
      materialStorageLocations.forEach(materialStorageLocation => {
        materialStorageLocation.endStorageLocations.forEach(storageLocation => {
          if (storageLocation.storageLocationId === preselectById) {
            this.setSelectedMaterialStorageLocation(storageLocation, false);
          }
        });
      });
    });
  }

  async refreshLocations(selectedIndex: number, parentStorageLocationId?: string) {
    const storageLocations = await this.loadingStore.withLoadingBar(() =>
      getStorageLocationChildren({
        storageLocationId: parentStorageLocationId,
        functionalAreaId: selectedIndex === 0 ? this.domainStore.currentFunctionalArea.id : undefined,
        locationSource: LocationSource.KPS
      })
    );
    runInAction(() => {
      if (this.storageLocationsBuffer.length - 1 < selectedIndex) {
        this.storageLocationsBuffer = [
          ...this.storageLocationsBuffer,
          {
            storageLocations,
            parentStorageLocationId
          }
        ];
      } else {
        this.storageLocationsBuffer = this.storageLocationsBuffer.map((storageLocationsBufferItem, index) => {
          if (index === selectedIndex) {
            return {
              storageLocations,
              parentStorageLocationId
            };
          }
          return storageLocationsBufferItem;
        });
      }
    });
  }

  async refreshSelectedStorageLocation() {
    if (this.selectedMaterialStorageLocation) {
      const selectedMaterialStorageLocation = await this.loadingStore.withLoadingBar(() =>
        getStorageLocation(this.selectedMaterialStorageLocation?.storageLocationId || '')
      );
      runInAction(() => {
        this.selectedMaterialStorageLocation = selectedMaterialStorageLocation;
      });
    }
    if (this.selectedStorageLocation) {
      const selectedStorageLocation = await this.loadingStore.withLoadingBar(() =>
        getStorageLocation(this.selectedStorageLocation?.storageLocationId || '')
      );
      runInAction(() => {
        this.selectedStorageLocation = selectedStorageLocation;
      });
    }
  }

  async createStorageLocation(storageLocation: CreateStorageLocationDTO, index: number) {
    const simpleStorageLocation = await this.loadingStore.withFlyoutLoadingBar(() => createStorageLocation(storageLocation));
    await this.refreshLocations(index, storageLocation.parentStorageLocationId);
    const newStorageLocation = {
      ...simpleStorageLocation,
      storageItems: []
    };
    this.setSelectedStorageLocation(newStorageLocation, index);
  }

  async deleteStorageLocation(storageLocationId: string, index: number, parentStorageLocationId?: string) {
    await this.loadingStore.withFlyoutLoadingBar(() => deleteStorageLocation(storageLocationId));
    runInAction(() => {
      const selectedStorageLocation = this.storageLocationsBuffer[index - 1]?.storageLocations.find(
        sl => sl.storageLocationId === parentStorageLocationId
      );
      if (selectedStorageLocation) {
        this.setSelectedStorageLocation(selectedStorageLocation, index - 1);
      } else {
        this.selectedStorageLocation = undefined;
      }
      this.refreshLocations(index, parentStorageLocationId);
    });
  }

  async updateStorageLocation(storageLocation: UpdateStorageLocationDTO, index: number, parentStorageLocationId?: string) {
    const simpleStorageLocation = await this.loadingStore.withLoadingBar(() => updateStorageLocation(storageLocation));
    if (this.materialLikeOrPackageId) {
      await this.loadMaterialLikeLocations(undefined, this.lastSelectedLocationSource);
      await this.refreshSelectedStorageLocation();
    } else {
      await this.refreshLocations(index, parentStorageLocationId);
    }
    runInAction(() => {
      if (this.selectedStorageLocation) {
        this.selectedStorageLocation = {
          ...this.selectedStorageLocation,
          ...simpleStorageLocation
        };
      }
    });
  }

  @action
  async setSelectedStorageLocation(selectedStorageLocation: SimpleStorageLocationDTO, currentIndex: number) {
    if (selectedStorageLocation.storageLocationId === this.selectedStorageLocation?.storageLocationId) {
      this.currentIndex = currentIndex;
      return;
    }
    const fullStorageLocation = await this.loadingStore.withLoadingBar(() => getStorageLocation(selectedStorageLocation.storageLocationId));
    runInAction(() => {
      this.selectedStorageLocation = fullStorageLocation;
      this.currentIndex = currentIndex;
      this.selectedStorageLocationIndex = currentIndex;
      this.selectedParentId = fullStorageLocation.storageLocationId;
      this.refreshLocations(currentIndex + 1, selectedStorageLocation.storageLocationId);
    });
  }

  @action
  async setSelectedMaterialStorageLocation(selectedMaterialStorageLocation: SimpleStorageLocationDTO, autoSelectChild: boolean) {
    if (autoSelectChild) {
      const endStorageLocations = this.materialStorageLocations.find(
        sl => sl.originStorageLocation.storageLocationId === selectedMaterialStorageLocation.storageLocationId
      )?.endStorageLocations;
      const fullStorageLocation = await this.loadingStore.withLoadingBar(async () => {
        if (endStorageLocations && endStorageLocations[0]) {
          return getStorageLocation(endStorageLocations[0].storageLocationId);
        }
        return undefined;
      });
      await runInAction(() => {
        this.selectedMaterialStorageLocation = fullStorageLocation;
      });
    } else {
      const fullStorageLocation = await this.loadingStore.withLoadingBar(() =>
        getStorageLocation(selectedMaterialStorageLocation.storageLocationId)
      );
      runInAction(() => {
        this.selectedMaterialStorageLocation = fullStorageLocation;
      });
    }
  }

  async createStorageItem(storageItem: CreateStorageItemDTO) {
    await this.loadingStore.withLoadingBar(() => createStorageItem(storageItem));
    await this.refreshSelectedStorageLocation();
    this.loadMaterialLikeLocations(undefined, this.lastSelectedLocationSource);
  }

  async updateStorageItem(storageItem: UpdateStorageItemDTO) {
    await this.loadingStore.withLoadingBar(() => updateStorageItem(storageItem));
    await this.refreshSelectedStorageLocation();
    this.loadMaterialLikeLocations(undefined, this.lastSelectedLocationSource);
  }

  async deleteStorageItem(storageItemId: string) {
    await this.loadingStore.withLoadingBar(() => deleteStorageItem(storageItemId));
    await this.refreshSelectedStorageLocation();
    this.loadMaterialLikeLocations(undefined, this.lastSelectedLocationSource);
  }

  async deleteStorageItemByMaterialLikeId() {
    if (!this.materialLikeOrPackageId || !this.selectedMaterialStorageLocation) return;
    const storageItem = this.selectedMaterialStorageLocation.storageItems.find(si => {
      if (!this.materialLikeOrPackageId) {
        return false;
      }
      if (si.material && si.material.materialId === this.materialLikeOrPackageId.materialId) {
        return true;
      }
      if (si.materialSet && si.materialSet.materialSetId === this.materialLikeOrPackageId.materialSetId) {
        return true;
      }
      if (si.package && si.package.packageId === this.materialLikeOrPackageId.packageId) {
        return true;
      }
      return false;
    });
    if (!storageItem) return;
    await this.loadingStore.withLoadingBar(() => deleteStorageItem(storageItem.storageItemId));
    await this.refreshSelectedStorageLocation();
    this.loadMaterialLikeLocations(undefined, this.lastSelectedLocationSource);
    runInAction(() => {
      this.selectedMaterialStorageLocation = undefined;
    });
  }

  async reorderStorageItems(storageItems: UpdateSortStorageItemDTO) {
    await this.loadingStore.withLoadingBar(() => reorderStorageItems(storageItems));
    await this.refreshSelectedStorageLocation();
  }

  async loadStorageItems(storageItems: UpdateSortStorageItemDTO, index: number, parentStorageLocationId?: string) {
    await this.loadingStore.withLoadingBar(() => reorderStorageItems(storageItems));
    await this.refreshLocations(index, parentStorageLocationId);
  }

  @action
  goToPreviousIndex() {
    if (this.currentIndex === 0 && this.storageLocationsBuffer[0]) {
      this.selectedStorageLocation = undefined;
      this.selectedParentId = undefined;
    } else {
      const selectedStorageLocation =
        this.storageLocationsBuffer[this.currentIndex - 1] &&
        this.storageLocationsBuffer[this.currentIndex - 1].storageLocations.find(
          s => s.storageLocationId === this.selectedStorageLocation?.parentStorageLocationId
        );
      this.currentIndex -= 1;
      if (selectedStorageLocation) {
        this.setSelectedStorageLocation(selectedStorageLocation, this.currentIndex);
      }
    }
  }

  @action
  setIsCreateFlyoutOpen(status: boolean) {
    this.isCreateFlyoutOpen = status;
  }

  @action
  setIsDeleteFlyoutOpen(status: boolean) {
    this.isDeleteFlyoutOpen = status;
  }

  @action
  setSelectedParentId(selectedParentId?: string) {
    this.selectedParentId = selectedParentId;
  }

  @action
  setSelectedIndexToCreate(selectedIndex: number) {
    this.selectedIndexToCreate = selectedIndex;
  }

  @action
  setIsDetailDrawerOpen(status: boolean) {
    this.isDetailDrawerOpen = status;
  }

  @action mapStorageLocation(materialLikeStorageLocations: MaterialStorageLocationDTO[]) {
    const storageLocations: StorageLocationDTO[] = materialLikeStorageLocations.map(storageLocation => ({
      ...storageLocation,
      storageItems: []
    }));
    return storageLocations;
  }

  @action setMaterialLikeId(materialLikeOrPackageId: MaterialLikeIdOrPackageIdDTO) {
    this.materialLikeOrPackageId = materialLikeOrPackageId;
  }

  @action
  setIsSelectLocationFlyoutOpen(status: boolean) {
    this.isSelectLocationFlyoutOpen = status;
  }

  @action
  setIsSelectMaterialLocationFlyoutOpen(
    status: boolean,
    selectedMaterialFlyoutCallback?: (storageLocationId: string | null) => Promise<void>
  ) {
    this.isSelectMaterialLocationFlyoutOpen = status;
    this.selectedMaterialFlyoutCallback = selectedMaterialFlyoutCallback;
  }

  @action
  refreshStore() {
    this.storageLocationsBuffer = [];
    this.selectedStorageLocation = undefined;
    this.selectedStorageLocationIndex = 0;
    this.currentIndex = 0;
    this.selectedIndexToCreate = 0;
    this.isDetailDrawerOpen = false;
    this.isSelectLocationFlyoutOpen = false;
    this.isSelectMaterialLocationFlyoutOpen = false;
  }

  @action
  refreshMaterialLikeData() {
    this.materialLikeOrPackageId = undefined;
    this.selectedMaterialStorageLocation = undefined;
  }
}
