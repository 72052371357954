import React from 'react';
import { Link } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';
import { PackageDTO } from 'dto/package';
import { GuideMaterialLikeDTO } from 'dto/guide';
import ImageCircle from 'components/ImageCircle';
import Popover from 'components/Popover';
import GetCameraImageButton from 'components/GetCameraImageButton';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import MaterialValueTags from 'components/MaterialValueTags';
import HoverWrapper from 'components/HoverWrapper';
import ListItemDropdownContainer from '../ListItemDropdownContainer';
import InstrumentListItem from '../InstrumentListItem';
import GuidePackageListItemMenu from './GuidePackageListItemMenu';
import './PackageListItem.css';

interface Props {
  packageItem: PackageDTO;
  guideData?: GuideMaterialLikeDTO;
  isClone?: boolean;
  filterListBy?: string;
}

const PackageListItem = ({ packageItem, guideData, isClone = false, filterListBy }: Props) => {
  const { guideDetailDrawerStore, guideStore, instrumentStore, domainStore } = useStores();
  const renderList = () => (
    <div className="content_holder no_padding padding_left in_matlist instruments-list">
      {packageItem.instruments.map(instrument => (
        <InstrumentListItem
          instrument={instrument}
          key={instrument.instrumentId}
          guideData={guideData}
          isClone={isClone}
          filterListBy={filterListBy}
        />
      ))}
    </div>
  );

  const onOpenPackageDetails = () => {
    if (!isClone) {
      guideDetailDrawerStore.setSelectedPackage(packageItem, guideData);
      guideDetailDrawerStore.setIsOpen(true);
    }
  };

  const onDeletePackageFromGuide = () => {
    if (guideStore.selectedGuide && guideData) {
      guideStore.deleteGuideMaterial({ guideMaterialId: guideData.guideMaterialId }, guideStore.selectedGuide.guideId);
    }
  };

  const onUpdateFile = async (fileId: string) => {
    const pictureFileIds = packageItem.pictures.map((file, index) => {
      if (index === 0) {
        return fileId;
      }
      return file.pictureFileId;
    });
    if (pictureFileIds.length === 0) {
      pictureFileIds.push(fileId);
    }

    await instrumentStore.updatePackage({ pictureFileIds, packageId: packageItem.packageId });
    if (filterListBy === 'all') {
      await instrumentStore.loadTemplates();
    }
    if (filterListBy === domainStore.currentDepartment.id) {
      await instrumentStore.loadTemplates(domainStore.currentDepartment.id);
    }
    if (guideStore.selectedGuide) {
      guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
  };

  return (
    <HoverWrapper>
      {({ hover }) => (
        <ListItemDropdownContainer classPrefix="package" list={renderList()} triggerExpandOnClick={isClone} inGuide={!!guideData}>
          <>
            <div className="item_count _1_lvl hidden" />
            {!!guideData && <div className="item_dropdown_holder" />}
            {packageItem.pictures.length > 0 ? (
              <ImageCircle
                pictures={packageItem.pictures}
                onClick={guideData && onOpenPackageDetails}
                instrumentLvl={guideData && 'lvl1'}
              />
            ) : (
              <div className="image_wrapper_50">
                <div className="image_border image-border" />
                <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
                {guideData && (
                  <div className="material_lvl1_line">
                    <div className="dot in_mat_list" />
                  </div>
                )}
              </div>
            )}
            {guideData ? (
              <div className="material_info w-inline-block package-info">
                <div className="material_text material-text" onClick={onOpenPackageDetails}>
                  <div>{packageItem.name}</div>
                </div>
                <div className="btn_show_detail" />
                <MaterialValueTags pack={packageItem} onClick={onOpenPackageDetails} />
                {!isClone ? (
                  <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                    {({ handleClose, isOpen }) => (
                      <GuidePackageListItemMenu
                        handleClose={handleClose}
                        isOpen={!!isOpen}
                        handleOpenDetails={onOpenPackageDetails}
                        handleDelete={onDeletePackageFromGuide}
                      />
                    )}
                  </Popover>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <Link to={`/package/overview?packageId=${packageItem.packageId}`} className="material_info w-inline-block package-info">
                <div className="material_text material-text">
                  <div>{packageItem.name}</div>
                </div>
                <MaterialValueTags pack={packageItem} />
                <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list image-arrow-list" />
              </Link>
            )}
          </>
        </ListItemDropdownContainer>
      )}
    </HoverWrapper>
  );
};

export default PackageListItem;
