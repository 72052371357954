import React from 'react';
import { observer } from 'mobx-react';

interface Props {
  groupType: string;
}

const MaterialSetOverviewRightMenu = observer(() => {
  return <></>;
});

export default MaterialSetOverviewRightMenu;
