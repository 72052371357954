import Switch from 'react-switch';
import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from 'util/mobx/stores';
import { SimpleFunctionalAreaDTO } from 'dto/functionalArea';
import { useTranslation } from 'react-i18next';

const SurgeryRoomFunctionalAreasList = observer(() => {
  const { t } = useTranslation('settings');
  const { settingsStore } = useStores();

  const onChange = (functionalArea: SimpleFunctionalAreaDTO, checked: boolean) => {
    settingsStore.setSelectedFunctionalArea(functionalArea);
    if (settingsStore.selectedSurgeryRoom && settingsStore.selectedFunctionalArea) {
      settingsStore.setSurgeryRoomFunctionalArea({
        active: checked,
        surgeryRoomId: settingsStore.selectedSurgeryRoom?.surgeryRoomId,
        functionalAreaId: settingsStore.selectedFunctionalArea.functionalAreaId
      });
    }
  };

  return (
    <div className="umkleide">
      <div className="h1">{t('surgeryRooms.surgeryRoomFunctionalAreas.headline')}</div>
      {settingsStore.surgeryRoomFunctionalAreas.map(sFa => {
        return (
          <div className="div-block-6-copy" key={sFa.functionalArea.functionalAreaId}>
            <div>{sFa.functionalArea.name}</div>
            <div className="div-block-11">
              <div>
                {sFa.active ? (
                  <span style={{ color: '#3adf27' }}>{t('surgeryRooms.surgeryRoomFunctionalAreas.active')}</span>
                ) : (
                  <span>{t('surgeryRooms.surgeryRoomFunctionalAreas.inactive')}</span>
                )}
              </div>
              <div className="div-block-9">
                <Switch
                  onColor="#3adf27"
                  offColor="#4d7cfe"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  width={40}
                  height={13}
                  onChange={checked => onChange(sFa.functionalArea, checked)}
                  checked={sFa.active}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default SurgeryRoomFunctionalAreasList;
