/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { getColorByRatio } from '../ReportGuides/reportGuidesUtils';
import { getMaxValue, colors } from './horizontalBarChartUtils';
import { HorizontalBarChartTypes, DepartmentReportDTO } from '../types';
import './HorizontalBarChart.css';

interface Props {
  selectedDepartment?: DepartmentReportDTO;
  departments: DepartmentReportDTO[];
  type: HorizontalBarChartTypes;
}

const HorizontalBarChart = observer(({ selectedDepartment, departments, type }: Props) => {
  const { t } = useTranslation('reports');
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(true);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIsAnimationInProgress(false);
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  if (type === HorizontalBarChartTypes.percentage) {
    const otherDepartments: DepartmentReportDTO[] = departments.filter(d => d.id !== (selectedDepartment && selectedDepartment.id));
    return (
      <div className="div-block-93 with_chart">
        {otherDepartments.map(department => {
          const percent = Math.round(department.value * 100);
          return (
            <div className="div-block-94" key={department.id}>
              <div>{department.name}</div>
              {percent > 0 && (
                <div
                  style={{
                    backgroundColor: getColorByRatio(department.value),
                    width: isAnimationInProgress ? 0 : `${percent}%`
                  }}
                  className="bar_chart bar-chart"
                >
                  {!isAnimationInProgress && <span style={{ marginLeft: percent < 5 ? percent * 0.1 + 20 : 0 }}>{percent}%</span>}
                </div>
              )}
              {department.value === 0 && <div className="bar_chart_empty">{t('guides.zeroText')}</div>}
            </div>
          );
        })}
      </div>
    );
  }
  const maxValue = getMaxValue(departments);
  return (
    <div className="div-block-93 with_chart">
      {departments.map((department, index) => {
        const amount = department.value;
        return (
          <div className="div-block-94" key={department.id}>
            <div>{department.name}</div>
            <div
              style={{
                backgroundColor: colors[index],
                width: isAnimationInProgress ? 0 : `${(amount / maxValue) * 100}%`
              }}
              className="bar_chart bar-chart"
            >
              {!isAnimationInProgress && <span style={{ marginLeft: amount < 5 ? amount * 0.1 + 20 : 0 }}>{amount}</span>}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default HorizontalBarChart;
