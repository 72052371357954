import React, { useEffect, useState } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import Guides from 'pages/guides';
import Guide from 'pages/guide';
import Material from 'pages/material';
import Package from 'pages/package';
import Instrument from 'pages/instrument';
import SingleInstrument from 'pages/singleInstrument';
import MaterialSet from 'pages/materialSet';
import Settings from 'pages/settings';
import LocationAdministration from 'pages/locationAdministration';
import { lockPortrait, addKeyboardListeners, addNetworkListeners, addAppListenerToCheckIfActive } from 'util/mobile/mobileUtils';
import { useStores } from 'util/mobx/stores';
import WifiIndicator from 'components/WifiIndicator';
import AccountManagement from 'pages/accountManagement';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Surgery from 'pages/surgery';
import SurgeryGuide from 'pages/surgeryGuide';
import Authentication from 'pages/authentication/Authentication';
import UserSettings from 'pages/userSettings';
import ErrorAlert from 'components/ErrorAlert';

const App = (): JSX.Element => {
  const { appNavigationStore } = useStores();
  const [isWifi, setIsWifi] = useState<boolean | undefined>();
  const { domainStore, authStore } = useStores();

  useEffect(() => {
    lockPortrait();
    addNetworkListeners(status => {
      setIsWifi(status.connected);
    });
    addAppListenerToCheckIfActive(() => authStore.logout());

    addKeyboardListeners(
      () => {
        appNavigationStore.setIsKeyboardOpen(true);
      },
      () => {
        appNavigationStore.setIsKeyboardOpen(false);
      }
    );
  }, [appNavigationStore, authStore]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Authentication} />
          <Route path="/guides" component={Guides} />
          <Route path="/guide" component={Guide} />
          <Route path="/material" component={Material} />
          <Route path="/package" component={Package} />
          <Route path="/instrument" component={Instrument} />
          <Route path="/single-instrument" component={SingleInstrument} />
          <Route path="/group/:groupType" component={MaterialSet} />
          <Route path="/accountManagement" component={AccountManagement} />
          <Route path="/settings" component={Settings} />
          <Route path="/user-settings" component={UserSettings} />
          <Route path="/administration/location" component={LocationAdministration} />
          <Route path="/surgery" component={Surgery} />
          <Route path="/surgery-guide" component={SurgeryGuide} />
        </Switch>
      </HashRouter>
      <ErrorAlert />
      {domainStore.isMobile ? <WifiIndicator isWifi={isWifi} /> : <></>}
    </MuiPickersUtilsProvider>
  );
};

export default App;
