import React from 'react';
import { FullGuideDTO } from 'dto/guide';
import { GroupedMaterialGuidesDTO } from 'dto/material';
import CollapsibleBox from 'components/CollapsibleBox';
import { GuideListItem } from 'components/ListItems';
import MaterialActions from '../MaterialActions';
import InstrumentActions from '../InstrumentActions';
import SingleInstrumentActions from '../SingleInstrumentActions';
import './ItemHead.css';

interface Props {
  groupedGuide: GroupedMaterialGuidesDTO;
  type: 'instrument' | 'material' | 'singleInstrument';
}

const getItemActions = (type: 'instrument' | 'material' | 'singleInstrument', guide: FullGuideDTO) => {
  if (type === 'material') {
    return <MaterialActions guide={guide} />;
  }
  if (type === 'instrument') {
    return <InstrumentActions guide={guide} />;
  }
  return <SingleInstrumentActions guide={guide} />;
};

const ItemHead = ({ groupedGuide, type }: Props) => {
  return (
    <div className="post_item_head_topic post-item-head">
      <CollapsibleBox
        head={
          <div>
            {groupedGuide.department.locationShortName} | {groupedGuide.department.functionalAreaShortName} | {groupedGuide.department.name}
          </div>
        }
      >
        {groupedGuide.guides.map((guide: FullGuideDTO) => (
          <GuideListItem key={guide.guideId} guide={guide} actions={getItemActions(type, guide)} />
        ))}
      </CollapsibleBox>
    </div>
  );
};

export default ItemHead;
