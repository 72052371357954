import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { savePackageFile } from 'api/file';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Form, { InputField, Select } from 'components/Form';
import { MaterialSetDTO } from 'dto/materialSet';
import Picture from 'components/Picture';

interface MaterialSetFormValues {
  name?: string;
  departmentId?: string;
}

interface Props {
  materialSetDetail: MaterialSetDTO;
  groupType: string;
}

const MaterialSetDetailForm = observer(({ materialSetDetail, groupType }: Props) => {
  const { flyoutStore, materialSetsStore, domainStore } = useStores();
  const { t } = useTranslation(groupType);
  const onSubmit = (values: MaterialSetFormValues) => {
    if (!values || Object.keys(values).length === 0) return;
    if (values.name || values.departmentId) {
      materialSetsStore.updateMaterialSet({
        materialSetId: materialSetDetail.materialSetId,
        name: values.name,
        departmentId: values.departmentId
      });
    }
  };

  const initialValues: MaterialSetFormValues = {
    name: materialSetDetail.name,
    departmentId: materialSetDetail.departmentId
  };

  const onUpdateFile = async (fileId: string) => {
    await materialSetsStore.updateMaterialSet({ materialSetId: materialSetDetail.materialSetId, pictureFileId: fileId });
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  useEffect(() => {
    const handleSubmitMyForm = () => {
      if (submitMyForm) submitMyForm();
    };
    return () => {
      handleSubmitMyForm();
    };
  });

  const handleAddImagesToView = () => {
    if (materialSetsStore.materialSetDetail?.picture) {
      flyoutStore.setImagesToView(materialSetsStore.materialSetDetail.picture);
    }
  };

  return (
    <div className="single_colum_content">
      <div className="div-block-45">
        <div className="stamm_big_image_wrapper">
          <div className="div-block-101">
            <div className="image_post_wide in_data" onClick={handleAddImagesToView}>
              {materialSetsStore.materialSetDetail?.picture ? (
                <Picture alt="" src={materialSetsStore.materialSetDetail?.picture} />
              ) : (
                <GetCameraImageButton buttonStyle="detailForm" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
              )}
            </div>
            <GetCameraImageButton
              isButtonVisible
              apiCall={savePackageFile}
              description={materialSetsStore.materialSetDetail?.pictureFileId ? t('details.button.toChange') : t('details.button.toAdd')}
              onUpdateFile={onUpdateFile}
              buttonStyle="square"
              isAddable
            />
          </div>
        </div>
      </div>
      <div className="div-block-44">
        <Form initialValues={initialValues} bindSubmitForm={bindSubmitForm} onSubmit={onSubmit}>
          {() => (
            <>
              <div className="content_field">
                <div>{t('details.label.name')}</div>
                <InputField name="name" placeholder={t('details.label.name')} />
              </div>
              <div className="content_field">
                <div>{t('details.label.department')}</div>
                <InputField
                  className="input_field top-margin"
                  mappedOptions={domainStore.departmentsDropdownOptions}
                  name="departmentId"
                  component={Select}
                  placeholder={t('details.label.department')}
                  isGrouped
                />
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
});

export default MaterialSetDetailForm;
