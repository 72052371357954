import React from 'react';
import { observer } from 'mobx-react';
import Picture from 'components/Picture';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { useStores } from 'util/mobx/stores';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import HoverWrapper from 'components/HoverWrapper';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Tags from 'components/ListItems/components/Tags';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import SurgeryGroupMaterialListItemMenu from './SurgeryGroupMaterialListItemMenu';
import SurgeryMaterialCheckItem from '../SurgeryMaterialCheckItem';
import './SurgeryGroupMaterialListItem.css';

interface Props {
  groupMaterial: GroupMaterialDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
}

const GroupMaterialListItem = observer(({ groupMaterial, surgeryGuide }: Props) => {
  const { surgeryGuideStore, guideDetailDrawerStore } = useStores();

  const onOpenDetails = () => {
    guideDetailDrawerStore.setSelectedMaterial(groupMaterial.material);
    guideDetailDrawerStore.setSelectedSurgeryGuideItem(surgeryGuide);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const usedMaterial = surgeryGuide.usedMaterials.find(m => m.groupMaterialId === groupMaterial.groupMaterialId);

  const onStatusChange = (status: UsedMaterialStatus) => {
    surgeryGuideStore.setUsedMaterial({
      surgeryGuideMaterialId: surgeryGuide.surgeryGuideMaterialId,
      groupMaterialId: groupMaterial.groupMaterialId,
      status
    });
  };

  return (
    <HoverWrapper className="list_item_material surgery-group-material group-material-list-item margin-right image-border-container">
      {({ hover }) => (
        <>
          <div className="item_count" onClick={onOpenDetails} />
          <div className="image_wrapper_50">
            <SurgeryMaterialCheckItem
              noCheck
              inSublist
              onStatusChanged={onStatusChange}
              status={usedMaterial?.status}
              surgeryGuide={surgeryGuide}
              usedMaterial={usedMaterial}
            />
            {groupMaterial.material.picture ? (
              <Picture src={groupMaterial.material.picture} width={50} alt="" className="image_circle_50" />
            ) : (
              <GetCameraImageButton buttonStyle="icon" description="" picturesOnly isAddable />
            )}
          </div>

          <div className="material_info group-material-info">
            <div className="material_text" onClick={onOpenDetails}>
              <div>{groupMaterial.material.name}</div>
              <Tags tags={groupMaterial.tags} />
            </div>
            <MaterialValueTags material={groupMaterial.material} />
            <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
              {({ handleClose, isOpen }) => (
                <SurgeryGroupMaterialListItemMenu
                  handleClose={handleClose}
                  isOpen={!!isOpen}
                  surgeryGuide={surgeryGuide}
                  usedMaterial={usedMaterial}
                  onOpenDetails={onOpenDetails}
                />
              )}
            </Popover>
          </div>
        </>
      )}
    </HoverWrapper>
  );
});

export default GroupMaterialListItem;
