import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from 'util/mobx/stores';
import { GroupedGuideMaterialsDTO } from 'dto/guide';
import FlyoutContainer from 'components/FlyoutContainer';
import GuideMaterialGroup from 'pages/guideMaterials/content/GuideMaterialGroup';
import CloneGuideOverview from './CloneGuideOverview';

export default observer(() => {
  const { t } = useTranslation('guide');
  const { guideStore } = useStores();
  const closePopUp = () => {
    guideStore.resetAllSelectedGuideParamsFromCloneFlyout();
  };
  const backPopUp = () => guideStore.resetSelectedGuideOwnerFromGuidesCloneFlyout();
  const onSubmit = () => {
    if (guideStore.selectedGuideCloneFlyout && guideStore.guideMaterialsToClone) {
      guideStore.cloneGuidesMaterial({ ...guideStore.guideMaterialsToClone, sourceGuideId: guideStore.selectedGuideCloneFlyout.guideId });
    }
    closePopUp();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_import.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={guideStore.isCloneGuideMaterialFlyoutOpen}
      closePopUp={guideStore.selectedGuideCloneFlyout ? backPopUp : closePopUp}
      onSubmit={onSubmit}
      submitLabel={guideStore.selectedGuideCloneFlyout && t('cloneGuideFlyout.button.submit')}
      isAllowedToSubmit
      title={t('cloneGuideFlyout.headline')}
      cancelLabel={guideStore.selectedGuideCloneFlyout ? t('cloneGuideFlyout.button.back') : t('cloneGuideFlyout.button.cancel')}
    >
      {guideStore.selectedGuideCloneFlyout ? (
        <div className="flyout_wrapper_more_content flyout-wrapper-displayed">
          <div className="flyout_box full_height">
            <div>{t('cloneGuideFlyout.label.pasteThisMaterialListToGuide')}</div>
            <div className="menu_list_scrollable in_flyout menu-list-min-height">
              {guideStore.guideMaterialsCloneFlyout &&
                guideStore.guideMaterialsCloneFlyout.map((guideMaterialGroup: GroupedGuideMaterialsDTO) => (
                  <GuideMaterialGroup
                    guideMaterialGroup={guideMaterialGroup}
                    className="txt_section_flyout padding_left"
                    key={guideMaterialGroup.category}
                    isClone
                    noDrag
                  />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <CloneGuideOverview
          title={t('cloneGuideFlyout.label.chooseGuideForGuideMaterials')}
          guideOwnerList={guideStore.guideOwnerListClone}
          guidesClone={guideStore.selectedGuidesClone}
          className="flyout_scroll_wrapper flyout-wrapper-fixed-height"
        />
      )}
    </FlyoutContainer>
  );
});
