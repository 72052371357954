import React from 'react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Button from 'components/Form/Button';
import Popover from 'components/Popover';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import './ChangeChapterDropdownButton.css';

const ChangeChapterDropdownButton = observer(() => {
  const { t } = useTranslation('briefing');
  const { surgeryGuideStore } = useStores();

  const onChangeChapter = (chapter: string, handleClose: () => void) => {
    surgeryGuideStore.selectProcedureChapter(chapter);
    handleClose();
  };

  return (
    <Button className="btn_small_flyout change-chapter-button">
      <Popover trigger={<div className="txt_dropout_1 trigger-text">{t('briefingProcedures.drawer.buttons.changeChapter')}</div>}>
        {({ handleClose, isOpen }) => (
          <ItemMenu isOpen={!!isOpen} handleClose={handleClose} className="in_op-pilot">
            {surgeryGuideStore.groupedGuideProcedures.map(procedureItem => {
              const isDisabled = procedureItem.surgeryGuideProcedures.filter(p => p.content.isCheckable).length === 0;
              return (
                <ItemMenuButton
                  disabled={isDisabled}
                  className="in_op-pilot"
                  label={procedureItem.chapter}
                  isInPopover
                  handleClick={() => onChangeChapter(procedureItem.chapter, handleClose)}
                />
              );
            })}
          </ItemMenu>
        )}
      </Popover>
    </Button>
  );
});

export default ChangeChapterDropdownButton;
