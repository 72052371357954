import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { getDayName, parseFromDate, dateFormat } from 'util/date';

interface Props {
  mobileOnly?: boolean;
}

const SurgeryRoomDateInfo = observer(({ mobileOnly = false }: Props) => {
  const { t } = useTranslation('date');
  const { surgeryStore } = useStores();
  return (
    <div className={`h1 ${mobileOnly ? 'mobile_only' : ''}`}>
      {surgeryStore.selectedRoom?.name}
      <br />
      {surgeryStore.selectedFilterDate && (
        <>
          {getDayName(surgeryStore.selectedFilterDate, t)}, {parseFromDate(surgeryStore.selectedFilterDate)?.format(dateFormat)}
        </>
      )}
    </div>
  );
});

export default SurgeryRoomDateInfo;
