/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsOptional, IsBoolean, ValidateNested, IsUUID, IsNotEmptyObject } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { CreateContentElementMediaDTO, OptionalContentElementMediaDTO, ContentElementMediaDTO } from './contentElementMedia';
import { UserDTO } from './user';

export class ContentElementDTO {
  /**
   * the contentElementId is optional because in auto generated elements there is none
   */
  @IsUUID()
  @IsOptional()
  contentElementId?: string;

  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsOptional()
  description?: string;

  /**
   * The user which updated / created the CE
   */
  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => UserDTO)
  user!: UserDTO;

  @ValidateNested({ each: true })
  @Type(() => ContentElementMediaDTO)
  contentElementMedias!: ContentElementMediaDTO[];
}

export class ProcedureContentElementDTO extends ContentElementDTO {
  @IsBoolean()
  isCheckable!: boolean;
}

export class SurgeryProcedureContentElementDTO extends ProcedureContentElementDTO {}

export class OptionalContentElementDTO {
  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @ValidateNested({ each: true })
  @Type(() => OptionalContentElementMediaDTO)
  @IsOptional()
  contentElementMedias?: OptionalContentElementMediaDTO[];
}

export class OptionalProcedureContentElementDTO extends OptionalContentElementDTO {
  @IsBoolean()
  @IsOptional()
  isCheckable?: boolean;
}

export class OptionalSurgeryProcedureContentElementDTO extends OptionalProcedureContentElementDTO {}

export class CreateContentElementDTO {
  @IsString()
  @IsOptional()
  title?: string;

  @IsString()
  @IsOptional()
  description?: string;

  @ValidateNested({ each: true })
  @Type(() => CreateContentElementMediaDTO)
  @IsOptional()
  contentElementMedias?: CreateContentElementMediaDTO[];
}

export class CreateProcedureContentElementDTO extends CreateContentElementDTO {
  @IsBoolean()
  isCheckable!: boolean;
}

export class CreatePostContentElementDTO {
  @IsString()
  @IsOptional()
  description!: string;

  @ValidateNested({ each: true })
  @Type(() => CreateContentElementMediaDTO)
  @IsOptional()
  contentElementMedias?: CreateContentElementMediaDTO[];
}
