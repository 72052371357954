import React from 'react';
import CollapsibleBox from 'components/CollapsibleBox';
import PostItem from 'components/PostItem';
import { useTranslation } from 'react-i18next';
import MaterialKnowledge from 'components/MaterialKnowledge';
import { InstrumentDTO } from 'dto/instrument';
import { IconType } from 'dto/procedure';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import DrawerStorageLocationContent from '../../DrawerStorageLocationContent';

interface Props {
  instrument: InstrumentDTO;
}

const Content = observer(({ instrument }: Props) => {
  const { t } = useTranslation('instruments');

  const { materialStore, guideDetailDrawerStore } = useStores();

  return materialStore.materialDetail ? (
    <div className="material_content">
      <div className="material-name">{instrument.material.name}</div>
      <DrawerStorageLocationContent />
      <MaterialKnowledge
        isCollapsible
        materialLikeId={{ materialId: instrument.material.materialId }}
        onCreateSynonym={() => guideDetailDrawerStore.openCreateMaterialSynonymFlyout()}
      />
      <div className="post_item_head_topic">
        <CollapsibleBox
          head={
            <>
              <div>{t('drawer.productInformation')}</div>
            </>
          }
        >
          <PostItem title={t('instrumentDetail.label.lastSynchronisation')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{instrument.lastSynchronisation}</div>
          </PostItem>
          <PostItem title={t('instrumentDetail.label.manufacturerName')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{materialStore.materialDetail.manufacturerName}</div>
          </PostItem>
          <PostItem title={t('instrumentDetail.label.description')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{instrument.description}</div>
          </PostItem>
          <PostItem title={t('instrumentDetail.label.amount')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{instrument.amount}</div>
          </PostItem>
          <PostItem title={t('instrumentDetail.label.expectedAmount')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{instrument.expectedAmount}</div>
          </PostItem>
        </CollapsibleBox>
      </div>
    </div>
  ) : (
    <></>
  );
});

export default Content;
