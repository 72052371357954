import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import ArrowIcon from 'components/ArrowIcon';
import ImageCircle from 'components/ImageCircle';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';
import { ExtendedUserDTO, UserDTO } from 'dto/user';
import { AbilityContext, subjectArea, actions } from 'casl/setupCaslAbility';
import { useStores } from 'util/mobx/stores';
import Popover from 'components/Popover';

interface Props {
  user: UserDTO | ExtendedUserDTO;
  withUserInfoBoxRight?: boolean;
  hover?: boolean;
  selected?: boolean | null;
  disableMenu?: boolean;
  isCloneGuideFlyout?: boolean;
  className?: string;
}

const GuideOwnerListItemBody = (props: Props) => {
  const { t } = useTranslation('guide');
  const ability = useContext(AbilityContext);
  const { guideStore, domainStore } = useStores();

  const onDeleteAllGuidesOfUser = () => {
    guideStore.showDeleteGuideFlyout(undefined, props.user.userId);
  };

  const onDeleteAllGuidesOfUserInDepartment = () => {
    guideStore.showDeleteGuideFlyout(undefined, props.user.userId, domainStore.currentDepartment.id);
  };

  return (
    <>
      <ImageCircle highlight={!!(props.hover || props.selected)} user={props.user} withUserInfoBoxRight={props.withUserInfoBoxRight} />
      <div className={`item_name with-button ${props.className}`}>
        <div className="div-block-4">
          <div className="t2 bottom-margin">{props.user.title}</div>
          <div>
            {props.user.lastName}, {props.user.firstName}
          </div>
        </div>
      </div>

      {!props.disableMenu && (
        <div className="btn_show_detail btn-detail-position">
          {!props.isCloneGuideFlyout && ability.can(actions.deleteMany, subjectArea.guide) ? (
            <Popover trigger={<ArrowDotsVerticalIcon direction={!props.hover} />}>
              {({ handleClose, isOpen }) => (
                <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
                  <ItemMenuButton
                    label={t('button.deleteAllGuidesPerson')}
                    icon="icon_bin_16.svg"
                    warning
                    isInPopover
                    handleClick={onDeleteAllGuidesOfUser}
                  />
                  <ItemMenuButton
                    label={t('button.deleteAllGuides')}
                    icon="icon_card-remove_16.svg"
                    warning
                    isInPopover
                    handleClick={onDeleteAllGuidesOfUserInDepartment}
                  />
                </ItemMenu>
              )}
            </Popover>
          ) : (
            <ArrowIcon hover={props.hover} />
          )}
        </div>
      )}
    </>
  );
};

export default GuideOwnerListItemBody;
