import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import UsedGuide from 'pages/surgeryProcedures/UsedGuide';
import { SurgeryGuideMaterialLikeDTO, UpdateSortSurgeryGuideMaterialsDTO } from 'dto/surgeryGuide';
import SurgeryMaterialsList from './SurgeryMaterialsList';
import SurgeryMaterialsRightMenu from './SurgeryMaterialsRightMenu';
import './SurgeryMaterials.css';

interface Props {
  surgeryGuideId: string;
}

const SurgeryMaterials = observer(({ surgeryGuideId }: Props) => {
  const { appNavigationStore, surgeryGuideStore } = useStores();

  useEffect(() => {
    if (surgeryGuideStore.surgeryGuide) {
      surgeryGuideStore.loadGuideMaterials(surgeryGuideId);
    }
    return () => surgeryGuideStore.clearMaterials();
  }, [surgeryGuideStore, surgeryGuideStore.surgeryGuide, surgeryGuideId]);

  appNavigationStore.useSubPageIdSetter('materials');

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    let materialToBeMoved: SurgeryGuideMaterialLikeDTO;
    let newGuideMaterials = surgeryGuideStore.guideMaterials.map(guideMaterialGroup => {
      if (result.source?.droppableId === guideMaterialGroup.categoryId) {
        const draggableItem = guideMaterialGroup.materials.find(p => p.surgeryGuideMaterialId === result.draggableId);
        materialToBeMoved = draggableItem || materialToBeMoved;
        return {
          ...guideMaterialGroup,
          materials: guideMaterialGroup.materials.filter(p => p.surgeryGuideMaterialId !== result.draggableId)
        };
      }
      return guideMaterialGroup;
    });
    newGuideMaterials = newGuideMaterials.map(guideMaterialGroup => {
      if (result.destination?.droppableId === guideMaterialGroup.categoryId) {
        guideMaterialGroup.materials.splice(result.destination.index, 0, materialToBeMoved);
        return {
          ...guideMaterialGroup,
          materials: guideMaterialGroup.materials
        };
      }
      return guideMaterialGroup;
    });
    const positions: UpdateSortSurgeryGuideMaterialsDTO[] = newGuideMaterials.map(guideMaterial => {
      const surgeryGuideMaterialIds = guideMaterial.materials.map(m => m.surgeryGuideMaterialId);
      return {
        categoryId: guideMaterial.categoryId,
        surgeryGuideMaterialIds
      };
    });
    surgeryGuideStore.reorderMaterials(positions);
  };

  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <SurgeryMaterialsRightMenu />;
    });
  }, [appNavigationStore]);

  return (
    <>
      <div className="div-block-102_fixed" />
      <div className="div-block-102_white white-in-material-list" />
      <div className="single_colum_content flex_width">
        <UsedGuide inMaterials />
        <DragDropContext onDragEnd={onDragEnd}>
          {surgeryGuideStore.guideMaterials.map((guideMaterialItem, index) => (
            <Droppable droppableId={guideMaterialItem.categoryId} key={guideMaterialItem.categoryId}>
              {provided => (
                <div ref={provided.innerRef}>
                  <div id={guideMaterialItem.category}>
                    <div className={index > 0 ? 'h1 top-margin' : 'h1'}>{guideMaterialItem.category}</div>
                    <SurgeryMaterialsList categoryId={guideMaterialItem.categoryId} materials={guideMaterialItem.materials} />
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>
    </>
  );
});

export default SurgeryMaterials;
