/* eslint-disable import/no-cycle */
import { IsInt, Min, IsOptional } from 'class-validator';

export class PagingDTO {
  @IsInt()
  @Min(0)
  @IsOptional()
  page?: number;

  @IsInt()
  @Min(0)
  @IsOptional()
  pageSize?: number;
}
