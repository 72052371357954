/* eslint-disable @typescript-eslint/no-explicit-any */
// any disabled because we are using it very generic
import 'mobx-react/batchingForReactDom';
import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/klinik-puls.webflow.css';
import './css/global.css';
import './css/keyframes.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import 'typeface-roboto';
import { Provider } from 'mobx-react';
import { configure, spy, toJS } from 'mobx';
// eslint-disable-next-line import/extensions
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import ErrorStore from 'stores/errorStore';
import i18n from './util/i18n';
import App from './App';
import AppNavigationStore from './stores/appNavigationStore';
import GuideStore from './stores/guideStore';
import MaterialStore from './stores/materialStore';
import DomainStore from './stores/domainStore';
import ProcedureStore from './stores/procedureStore';
import ReportStore from './stores/reportStore';
import FlyoutStore from './stores/flyoutStore';
import PostStore from './stores/postStore';
import SettingsStore from './stores/settingsStore';
import InstrumentStore from './stores/instrumentStore';
import AnimationStore from './stores/animationStore';
import SearchStore from './stores/searchStore';
import GuideDetailDrawerStore from './stores/guideDetailDrawerStore';
import MaterialSetsStore from './stores/materialSetsStore';
import KnowledgeStore from './stores/knowledgeStore';
import StorageLocationStore from './stores/storageLocationStore';
import LoadingStore from './stores/loadingStore';
import UserStore from './stores/userStore';
import SurgeryStore from './stores/surgeryStore';
import SurgeryGuideStore from './stores/surgeryGuideStore';
import AuthStore from './stores/authStore';

configure({ enforceActions: 'observed', computedRequiresReaction: true });

const stores: Record<string, unknown> = {
  loadingStore: new LoadingStore(),
  appNavigationStore: new AppNavigationStore(),
  flyoutStore: new FlyoutStore(),
  animationStore: new AnimationStore(),
  errorStore: new ErrorStore()
};

// add stores which require the LoadingStore here
stores.domainStore = new DomainStore(stores.loadingStore as LoadingStore);
stores.guideStore = new GuideStore(stores.domainStore as DomainStore, stores.loadingStore as LoadingStore);
stores.settingsStore = new SettingsStore(stores.domainStore as DomainStore, stores.loadingStore as LoadingStore);
stores.storageLocationStore = new StorageLocationStore(stores.domainStore as DomainStore, stores.loadingStore as LoadingStore);
stores.materialStore = new MaterialStore(stores.loadingStore as LoadingStore);
stores.instrumentStore = new InstrumentStore(stores.loadingStore as LoadingStore);
stores.materialSetsStore = new MaterialSetsStore(stores.loadingStore as LoadingStore);
stores.postStore = new PostStore(stores.loadingStore as LoadingStore);
stores.procedureStore = new ProcedureStore(stores.loadingStore as LoadingStore);
stores.reportStore = new ReportStore(stores.loadingStore as LoadingStore);
stores.knowledgeStore = new KnowledgeStore(stores.loadingStore as LoadingStore);
stores.searchStore = new SearchStore(stores.loadingStore as LoadingStore);
stores.userStore = new UserStore(stores.loadingStore as LoadingStore);
stores.surgeryStore = new SurgeryStore(stores.domainStore as DomainStore, stores.loadingStore as LoadingStore);
stores.surgeryGuideStore = new SurgeryGuideStore(stores.domainStore as DomainStore, stores.loadingStore as LoadingStore);
stores.authStore = new AuthStore(stores.domainStore as DomainStore, stores.loadingStore as LoadingStore);
stores.guideDetailDrawerStore = new GuideDetailDrawerStore(stores.loadingStore as LoadingStore);

i18n.changeLanguage('de');

/* todo activate spy-level using config */
spy(event => {
  if (event.type === 'action') {
    console.log(`${event.name} with args: `, event.arguments);
  } else if (event.type === 'update') {
    console.log(`update ${event.name}: `, 'old value', toJS(event.oldValue), 'new value', toJS(event.newValue));
  }
});

const render = (Component: any) => {
  ReactDOM.render(
    <Provider {...stores}>
      <Component />
    </Provider>,
    document.getElementById('root')
  );
};

render(App);
defineCustomElements(window);
if ((module as any).hot) {
  (module as any).hot.accept('./App', () => {
    const NextApp = App;
    render(NextApp);
  });
}
