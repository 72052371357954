import React, { CSSProperties, useState } from 'react';
import { UserDTO } from 'dto/user';
import Picture from 'components/Picture';
import './ImageCircle.css';
import { PictureDTO, OptionalPictureDTO } from 'dto/file';
import GetCameraImageButton from 'components/GetCameraImageButton';
import UserInfoBox from 'components/UserInfoBox';

interface Props {
  user?: UserDTO;
  withUserInfoBoxRight?: boolean;
  withUserInfoBoxLeft?: boolean;
  builtinPictureName?: string;
  highlight?: boolean;
  size?: '40' | '50';
  className?: string;
  marginRight?: number;
  noWrapper?: boolean;
  noHover?: boolean;
  pictures?: (PictureDTO | OptionalPictureDTO)[];
  onClick?: () => void;
  instrumentLvl?: 'lvl1' | 'lvl2' | undefined;
}

const generateInitials = (firstName = '', lastName = '') => {
  if (firstName && lastName) {
    return firstName[0] + lastName[0];
  }
  return '';
};

const opacity = (showBorder: boolean) => (showBorder ? 1 : 0);

const ImageCircle = ({
  size = '50',
  marginRight,
  className = '',
  highlight = false,
  builtinPictureName,
  user,
  noWrapper = false,
  noHover = false,
  withUserInfoBoxRight = false,
  withUserInfoBoxLeft = false,
  pictures,
  onClick,
  instrumentLvl
}: Props) => {
  const initialsClassName = size === '50' ? 'div-block-3 txt_50' : 'div-block-3 size-40 txt_50';
  const [showUserInfoBox, setShowUserInfoBox] = useState(false);

  const wrapperClass = noWrapper ? '' : `image_wrapper_${size}`;

  const css: CSSProperties = {};
  if (marginRight !== undefined) {
    css.marginRight = `${marginRight}px`;
  }

  let firstPicture: PictureDTO | OptionalPictureDTO | undefined;

  if (pictures) {
    firstPicture = pictures[0];
  }

  if (user && user.pictureFileId) {
    firstPicture = {
      picture: user.picture,
      pictureFileId: user.pictureFileId,
      pictureThumbnail: user.pictureThumbnail
    };
  }

  if (firstPicture && firstPicture.picture && instrumentLvl === 'lvl1') {
    return (
      <div style={css} className={`${wrapperClass} ${className}`} onClick={onClick}>
        {!noHover && <div style={{ opacity: opacity(highlight) }} className="image_border" />}
        <Picture src={firstPicture.pictureThumbnail} width={size} sizes={`${size}px`} alt="" className={`image_circle_${size}`} />
        <div className="material_lvl1_line">
          <div className="dot in_mat_list" />
        </div>
      </div>
    );
  }

  if (firstPicture && firstPicture.picture && instrumentLvl === 'lvl2') {
    return (
      <div style={css} className={`${wrapperClass} ${className}`} onClick={onClick}>
        {!noHover && <div style={{ opacity: opacity(highlight) }} className="image_border" />}
        <Picture src={firstPicture.pictureThumbnail} width={size} sizes={`${size}px`} alt="" className={`image_circle_${size}`} />
        <div className="material_lvl2_line">
          <div className="dot in_mat_list" />
        </div>
      </div>
    );
  }
  if (firstPicture && firstPicture.picture) {
    return (
      <div
        style={css}
        className={`${wrapperClass} ${className}`}
        onClick={onClick}
        onMouseEnter={() => setShowUserInfoBox(true)}
        onMouseLeave={() => setShowUserInfoBox(false)}
      >
        {user && showUserInfoBox && withUserInfoBoxRight && <UserInfoBox user={user} userInfoBoxClassName="show-info-box-right" />}
        {user && showUserInfoBox && withUserInfoBoxLeft && <UserInfoBox user={user} userInfoBoxClassName="show-info-box-left" left />}
        {!noHover && <div style={{ opacity: opacity(highlight) }} className="image_border" />}
        <Picture src={firstPicture.pictureThumbnail} width={size} sizes={`${size}px`} alt="" className={`image_circle_${size}`} />
      </div>
    );
  }

  if (pictures && pictures.length === 0) {
    return (
      <div style={css} className={`${wrapperClass} ${className}`} onClick={onClick}>
        <div style={{ opacity: opacity(highlight) }} className="image_border" />
        <GetCameraImageButton buttonStyle="icon" description="" picturesOnly />;
      </div>
    );
  }

  return (
    <>
      <div
        style={css}
        className={`${wrapperClass} ${className}`}
        onClick={onClick}
        onMouseEnter={() => setShowUserInfoBox(true)}
        onMouseLeave={() => setShowUserInfoBox(false)}
      >
        {user && showUserInfoBox && withUserInfoBoxRight && <UserInfoBox user={user} userInfoBoxClassName="show-info-box-right" />}
        {user && showUserInfoBox && withUserInfoBoxLeft && <UserInfoBox user={user} userInfoBoxClassName="show-info-box-left" left />}
        <div style={{ opacity: opacity(highlight) }} className="image_border" />
        {builtinPictureName ? (
          <Picture src={`images/${builtinPictureName}.jpg`} width={size} sizes={`${size}px`} alt="" className={`image_circle_${size}`} />
        ) : (
          <div className={initialsClassName}>
            <div>{generateInitials(user?.firstName, user?.lastName)}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageCircle;
