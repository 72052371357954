import React, { useState } from 'react';

interface ChildrenProps {
  hover: boolean;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: (props: ChildrenProps) => JSX.Element | null;
}

const HoverWrapper = ({ children, ...rest }: Props) => {
  const [hover, setHover] = useState(false);
  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);
  return (
    <div {...rest} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children({ hover })}
    </div>
  );
};

export default HoverWrapper;
