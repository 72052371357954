/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, IsString, ValidateNested, IsNotEmptyObject, IsInt, IsOptional } from 'class-validator';
import { Type } from 'class-transformer';
import { MaterialLikeDTO, MaterialLikeIdDTO, SimpleMaterialLikeDTO } from './material';
import { NotesDTO, UpdateNotesDTO, FullGuideDTO } from './guide';
import { UsedMaterialDTO } from './usedMaterial';
import { SurgeryGuideStatisticDTO } from './statistic';

export class SurgeryGuideDTO {
  @IsUUID()
  surgeryGuideId!: string;

  @IsUUID()
  briefingId!: string;

  /**
   * Fallwagennummer
   */
  @IsInt()
  surgeryGuideNumber!: number;

  @ValidateNested()
  @Type(() => FullGuideDTO)
  guide!: FullGuideDTO;

  /**
   * statistic may be undefined if it is not loaded
   */
  @ValidateNested()
  @Type(() => SurgeryGuideStatisticDTO)
  statistic?: SurgeryGuideStatisticDTO;
}

export class FullSurgeryGuideDTO {
  @IsUUID()
  surgeryGuideId!: string;

  @IsUUID()
  briefingId!: string;

  /**
   * Fallwagennummer
   */
  @IsInt()
  surgeryGuideNumber!: number;

  @ValidateNested()
  @Type(() => FullGuideDTO)
  guide!: FullGuideDTO;
}

export class CreateSurgeryGuideDTO {
  @IsUUID()
  briefingId!: string;

  @IsUUID()
  sourceGuideId!: string;
}

export class SurgeryGuideIdDTO {
  @IsUUID()
  surgeryGuideId!: string;
}

export class SurgeryGuideMaterialLikeDTO extends MaterialLikeDTO {
  @IsUUID()
  surgeryGuideMaterialId!: string;

  @IsUUID()
  @IsOptional()
  storageLocationId?: string;

  @IsInt()
  amount!: number;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => NotesDTO)
  notes!: NotesDTO;

  /**
   * usedMaterials contains all materials and packages which have to be checked.
   *
   * If the surgeryGuideMaterial is a single material, this array only contains max one
   * used material.
   * If it is a set, it can contain several, one for each material in the set.
   * If it is a template, it can contain several, one for each package in the template.
   *
   * For sets it has to be interpreted the following way:
   * - One UsedMaterial contains only the surgeryGuideMaterialId and no groupMaterialId or packageId.
   *   This defines the "default" state of the groupMaterial checks (if these do not exist or have status "None").
   *   It gets set when the user sets the status for the whole group.
   * - Each groupMaterial can exist also and defines the actual state of it.
   *   It gets set if the user sets the status for one groupMaterial.
   * - The lot number is not used in the first case as a whole set does not have a lot number.
   */
  @ValidateNested({ each: true })
  @Type(() => UsedMaterialDTO)
  usedMaterials!: UsedMaterialDTO[];
}

export class SimpleSurgeryGuideMaterialLikeDTO extends SimpleMaterialLikeDTO {
  @IsUUID()
  surgeryGuideMaterialId!: string;
}

export class CreateSurgeryGuideMaterialDTO extends MaterialLikeIdDTO {
  @IsUUID()
  surgeryGuideId!: string;

  @IsUUID()
  @IsOptional()
  storageLocationId?: string;

  @IsUUID()
  guideMaterialCategoryId!: string;

  @IsInt()
  amount!: number;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => NotesDTO)
  notes!: NotesDTO;
}

export class GroupedSurgeryGuideMaterialsDTO {
  @IsString()
  category!: string;

  @IsUUID()
  categoryId!: string;

  @ValidateNested({ each: true })
  @Type(() => SurgeryGuideMaterialLikeDTO)
  materials!: SurgeryGuideMaterialLikeDTO[];
}

export class UpdateSortSurgeryGuideMaterialsDTO {
  @IsUUID()
  categoryId!: string;

  @IsUUID(undefined, { each: true })
  surgeryGuideMaterialIds!: string[];
}

export class UpdateSurgeryGuideMaterialDTO {
  @IsUUID()
  surgeryGuideMaterialId!: string;

  @IsOptional()
  @IsUUID()
  guideMaterialCategoryId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => MaterialLikeIdDTO)
  material?: MaterialLikeIdDTO;

  @IsOptional()
  @IsInt()
  amount?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => UpdateNotesDTO)
  notes?: UpdateNotesDTO;

  @IsOptional()
  @IsUUID()
  storageLocationId?: string | null;
}
