import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotesDTO } from 'dto/guide';

interface Props {
  notes: NotesDTO;
  translationPrefix?: string;
}

const Instructions = ({ notes, translationPrefix }: Props) => {
  const { t } = useTranslation('listItems');
  const prefix = translationPrefix ? `${translationPrefix}.` : '';
  return (
    <div className="note_wrapper">
      {notes.preparer && (
        <div className="material_note">
          <div>
            <strong>{t(`instructions.${prefix}notePreparer`)}</strong>: {notes.preparer}
          </div>
        </div>
      )}
      {notes.sterile && (
        <div className="material_note">
          <div>
            <strong>{t(`instructions.${prefix}noteSterile`)}</strong>: {notes.sterile}
          </div>
        </div>
      )}
      {notes.circular && (
        <div className="material_note">
          <div>
            <strong>{t(`instructions.${prefix}noteCircular`)}</strong>: {notes.circular}
          </div>
        </div>
      )}
      {notes.unpackOnInstruction && (
        <div className="material_note">
          <div>
            <strong>{t(`instructions.${prefix}noteUnpackOnInstruction`)}</strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default Instructions;
