import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import CloneMaterialKnowledgeItem from './CloneMaterialKnowledgeItem';

interface Props {
  handleAddLinkMaterialKnowledgeId: (knowledgeId?: string) => void;
  isKnowledgeSelected: (knowledgeId: string) => boolean;
}

export default observer((props: Props) => {
  const { guideStore } = useStores();
  const [openIndex, setOpenIndex] = useState(-1);

  const getOpenMaterialHandler = (index: number) => () => {
    setOpenIndex(index);
  };

  return (
    <>
      {guideStore.guideMaterials.reduce((previous: JSX.Element[], groups) => {
        groups.materials.forEach((guideMaterial, i) => {
          previous.push(
            <CloneMaterialKnowledgeItem
              key={guideMaterial.guideMaterialId}
              guideMaterial={guideMaterial}
              handleAddLinkMaterialKnowledgeId={props.handleAddLinkMaterialKnowledgeId}
              isOpen={openIndex === i}
              openHandler={getOpenMaterialHandler(i)}
              isKnowledgeSelected={props.isKnowledgeSelected}
            />
          );
        });
        return previous;
      }, [])}
    </>
  );
});
