import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PostDTO } from 'dto/post';
import { animateScroll } from 'react-scroll';
import DialogForm from './DialogForm';
import DialogPost from './DialogPost';

interface Props {
  posts?: PostDTO[];
}

const Dialog = ({ posts = [] }: Props) => {
  const { t } = useTranslation('board');
  useEffect(() => {
    animateScroll.scrollToBottom({ containerId: 'dialog-posts-container', duration: 1 });
  }, [posts]);
  return (
    <div className="chat-wrapper chat-wrapper-custom">
      <div className="board_header">
        <img src="images/icon_feedback_chat_24.svg" alt="" className="board_icon_chatbox" />
        <div>{t('label.dialog')}</div>
      </div>
      <div id="dialog-posts-container" className="chat_inner_wrapper">
        {posts.map(post => (
          <DialogPost post={post} key={post.postId} />
        ))}
      </div>
      <DialogForm />
    </div>
  );
};

export default Dialog;
