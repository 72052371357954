import { UsedMaterialStatus } from 'dto/usedMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLProps<HTMLDivElement> {
  status: UsedMaterialStatus;
}

const SurgeryMaterialCheckStatus = ({ status, ...rest }: Props) => {
  const { t } = useTranslation('briefing');
  if (status === UsedMaterialStatus.Used) {
    return (
      <div {...rest} className="btn_checkboxmenu btn-checkboxmenu in-sub-list-check green">
        {t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.used')}
      </div>
    );
  }
  if (status === UsedMaterialStatus.Implanted) {
    return (
      <div {...rest} className="btn_checkboxmenu btn-checkboxmenu in-sub-list-check green">
        {t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.implanted')}
      </div>
    );
  }
  if (status === UsedMaterialStatus.Planned) {
    return (
      <div {...rest} className="btn_checkboxmenu btn-checkboxmenu in-sub-list-check red">
        {t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.planned')}
      </div>
    );
  }
  if (status === UsedMaterialStatus.UsedNotImplanted) {
    return (
      <div {...rest} className="btn_checkboxmenu btn-checkboxmenu in-sub-list-check red">
        {t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.usedNotImplanted')}
      </div>
    );
  }
  if (status === UsedMaterialStatus.NotNeeded) {
    return (
      <div {...rest} className="btn_checkboxmenu btn-checkboxmenu in-sub-list-check red">
        {t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.notRequired')}
      </div>
    );
  }
  return (
    <div {...rest} className="btn_checkboxmenu btn-checkboxmenu in-sub-list-check green">
      {t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.used')}
    </div>
  );
};

export default SurgeryMaterialCheckStatus;
