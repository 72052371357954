import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import NavSectionButton from 'components/NavSection/NavSectionButton';

interface Props {
  currentPath: string;
}

const SettingsNavigation = observer(({ currentPath }: Props) => {
  const { t } = useTranslation('settings');
  return (
    <>
      <NavSectionButton
        icon="icon_globe_16"
        label={t('subMenu.globalAdministration')}
        routePath="/settings/administration"
        currentPath={currentPath}
      />
      <NavSectionButton
        icon="icon_property_16"
        label={t('subMenu.surgeryRooms')}
        routePath="/settings/surgery-rooms"
        currentPath={currentPath}
      />
      <NavSectionButton icon="f-comment" label={t('subMenu.board')} routePath="/settings/board" currentPath={currentPath} />
      <NavSectionButton
        icon="icon_bulb-62_16"
        label={t('subMenu.knowledgeTopics')}
        routePath="/settings/knowledge-topics"
        currentPath={currentPath}
      />
      <NavSectionButton icon="undo-29" label={t('subMenu.backup')} routePath="/settings/backup" currentPath={currentPath} />
    </>
  );
});

export default SettingsNavigation;
