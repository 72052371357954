import React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { FormikValues, FormikProps } from 'formik';
import { UTCTimeDTO } from 'dto/surgery';
import { parseToTime, parseFromTime } from 'util/date';

const styles = () => ({
  input: {
    color: '#7a7a7a',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    paddingLeft: '10px',
    fontWeight: 400,
    marginTop: '20px',
    background: 'white'
  }
});

interface Props extends WithStyles<typeof styles> {
  meta: FormikProps<FormikValues>;
  name: string;
}

const TimePickerComponent = ({ meta, name, classes, ...rest }: Props) => {
  const onSelectDate = (date: MaterialUiPickersDate) => {
    if (!date) return;
    const parsedDate: UTCTimeDTO = parseToTime(date);
    meta.setFieldValue(name, parsedDate);
  };
  return (
    <KeyboardTimePicker
      {...rest}
      autoOk
      value={parseFromTime(meta.values[name])}
      onChange={onSelectDate}
      variant="inline"
      inputVariant="outlined"
      PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'left' } }}
      InputProps={{ className: classes.input, notched: true }}
    />
  );
};

export default withStyles(styles)(TimePickerComponent);
