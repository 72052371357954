import React from 'react';
import { useTranslation } from 'react-i18next';
import { StorageLocationPathDTO } from 'dto/storageLocation';
import Button from 'components/Form/Button';
import { useStores } from 'util/mobx/stores';
import LocationBox from '../LocationBox';
import './StorageLocationPath.css';

interface Props {
  path: StorageLocationPathDTO;
}

const StorageLocationPath = ({ path }: Props) => {
  const { storageLocationStore, guideDetailDrawerStore } = useStores();
  const { t } = useTranslation('storageLocationItems');

  const onFlyoutOpen = async () => {
    if (!guideDetailDrawerStore.selectedGuideItem?.material) return;
    storageLocationStore.setMaterialLikeId({ materialId: guideDetailDrawerStore.selectedGuideItem.material.materialId });
    await storageLocationStore.loadMaterialLikeLocations();
    storageLocationStore.setIsSelectMaterialLocationFlyoutOpen(true);
  };

  return (
    <div className="post_item no_margin">
      <div className="post_box material_detail">
        <div className="flex_horizontal">
          <img src="images/icon_pennant_16_grey.svg" alt="" className="icon_big_flag" />
          <div>{t('pathTitle')}</div>
        </div>
        <div className="field_location top_margin location-boxes-container">
          {path.storageLocations.map((storageLocation, index) => (
            <LocationBox
              storageLocation={storageLocation}
              key={storageLocation.storageLocationId}
              last={index === path.storageLocations.length - 1}
            />
          ))}
        </div>
        <Button className="btn_small margin_bottom" type="button" onClick={onFlyoutOpen}>
          <div className="txt_dropout_1">{t('buttons.showAll')}</div>
        </Button>
      </div>
    </div>
  );
};

export default StorageLocationPath;
