import React, { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { getItemStyle } from 'util/dragAndDrop';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import SurgeryMaterialListItem from '../SurgeryMaterialLikeListItem';

interface Props {
  materials: SurgeryGuideMaterialLikeDTO[];
  hover?: boolean;
  categoryId: string;
}

const SurgeryMaterialsList = ({ materials }: Props) => {
  return (
    <>
      {materials.map((materialLike, index) => (
        <Fragment key={materialLike.surgeryGuideMaterialId}>
          <Draggable key={materialLike.surgeryGuideMaterialId} draggableId={materialLike.surgeryGuideMaterialId} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                <SurgeryMaterialListItem materialLike={materialLike} />
              </div>
            )}
          </Draggable>
        </Fragment>
      ))}
    </>
  );
};

export default SurgeryMaterialsList;
