/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialSetDetailRightMenu from './MaterialSetDetailRightMenu';
import MaterialSetDetailForm from './MaterialSetDetailForm';

interface Props {
  groupType: string;
}

const InstrumentDetail = observer(({ groupType }: Props) => {
  const { materialSetsStore, appNavigationStore } = useStores();

  appNavigationStore.useSubPageIdSetter('detail');

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <MaterialSetDetailRightMenu />;
    });
  }, [appNavigationStore, groupType]);
  if (materialSetsStore.materialSetDetail) {
    return <MaterialSetDetailForm materialSetDetail={materialSetsStore.materialSetDetail} groupType={groupType} />;
  }
  return null;
});

export default InstrumentDetail;
