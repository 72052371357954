import Picture from 'components/Picture';
import React from 'react';
import { useStores } from 'util/mobx/stores';
import './ListItemAdd.css';

interface Props {
  className: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children?: JSX.Element | JSX.Element[];
  visibleCondition?: boolean;
  addUserPicture?: boolean;
}

export default ({ className, onClick, children, visibleCondition = true, addUserPicture = false }: Props) => {
  const { domainStore, userStore } = useStores();

  return (
    <div className={`${className} list_item_add_state ${domainStore.isMobile ? 'mobile' : ''}`} onClick={onClick}>
      {visibleCondition && (
        <div className="image_wrapper_50">
          <div className="image_border" />
          {addUserPicture ? (
            <Picture
              src={userStore.selectedUser?.pictureThumbnail || '/images/icon_add_patient2x.jpg'}
              width="50"
              alt=""
              className="image_circle_50"
            />
          ) : (
            <img src="images/icon_add.jpg" width="50" alt="" className="image_circle_50" />
          )}
        </div>
      )}
      {children}
    </div>
  );
};
