import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import NavSectionButton from 'components/NavSection/NavSectionButton';
import { useLocation } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';

const GuideNavSection = observer(() => {
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  const { t } = useTranslation('guideMaterials');
  const { guideStore, procedureStore } = useStores();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [isProcedureChapterActive, setIsProcedureChapterActive] = useState(false);
  const itemRef: React.RefObject<HTMLDivElement> = useRef(null);

  useLayoutEffect(() => {
    function updateSize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    /**
     * closes the item menu
     * @param event
     */
    const onClose = (event: MouseEvent) => {
      if (size.width <= 991 && isProcedureChapterActive && event.target instanceof Node && !itemRef.current?.contains(event.target)) {
        setIsProcedureChapterActive(false);
      }
    };

    const onScrollClose = (ev: Event) => {
      if (size.width <= 991 && isProcedureChapterActive && ev.target instanceof Node && !itemRef.current?.contains(ev.target)) {
        setIsProcedureChapterActive(false);
      }
    };

    if (itemRef.current) {
      if (isProcedureChapterActive) {
        document.body.addEventListener('click', onClose);
        document.body.addEventListener('scroll', onScrollClose, true);
      } else {
        document.body.removeEventListener('click', onClose);
        document.body.removeEventListener('scroll', onScrollClose, false);
      }
    }

    return () => {
      document.body.removeEventListener('click', onClose);
      document.body.removeEventListener('scroll', onScrollClose, false);
    };
  }, [itemRef, isProcedureChapterActive, size.width]);

  return (
    <>
      <NavSectionButton
        icon="icon_list-bullet_16"
        label={t('subMenu.material')}
        routePath={`/guide/materials?guideId=${guideStore.selectedGuide?.guideId}`}
        currentPath={currentPath}
      />
      <div className="nav_chapter">
        <div onClick={() => setIsProcedureChapterActive(true)}>
          <NavSectionButton
            icon="icon_filter-check_16"
            label={t('subMenu.procedure')}
            routePath={`/guide/procedure?guideId=${guideStore.selectedGuide?.guideId}`}
            currentPath={currentPath}
          />
        </div>
        {location.pathname === '/guide/procedure' && (size.width > 991 || (size.width <= 991 && isProcedureChapterActive)) && (
          <div className="chapter" style={{ display: 'flex' }} ref={itemRef}>
            {procedureStore.procedures.map(procedureItem => (
              <HashLink
                to={`/guide/procedure?guideId=${guideStore.selectedGuide?.guideId}#${procedureItem.chapter}`}
                smooth
                className="chapter_link w-inline-block"
                key={procedureItem.chapter}
                replace
                onClick={() => setIsProcedureChapterActive(false)}
              >
                <div className="text-block-8">{procedureItem.chapter}</div>
              </HashLink>
            ))}
          </div>
        )}
      </div>
    </>
  );
});

export default GuideNavSection;
