import React, { useState } from 'react';
import { getAnimationState } from 'util/animation';

interface ChildrenProps {
  setIsListExpanded: (state: boolean | null) => void;
  isListExpanded: boolean | null;
}

interface Props {
  children: (props: ChildrenProps) => JSX.Element | null;
  list?: (isListExpanded: boolean | null) => JSX.Element;
  classPrefix: string;
  inGuide?: boolean;
  className?: string;
  isExpandedByDefault?: boolean;
}

const ListItemDropdownAsFunction = ({
  children,
  list,
  classPrefix,
  inGuide = false,
  className = '',
  isExpandedByDefault = false
}: Props) => {
  const [isListExpanded, setIsListExpanded] = useState<boolean | null>(isExpandedByDefault ? true : null);
  return (
    <div
      className={`list_item_dropdown list-item-dropdown ${classPrefix}-list-item-dropdown ${getAnimationState(
        isListExpanded,
        'expanded',
        'collapsed'
      )} ${className}`}
    >
      <div className="list_item_flex">
        <div
          className={`item_dropdown item_open ${inGuide ? '' : 'left'} item-dropdown`}
          onClick={() => setIsListExpanded(!isListExpanded)}
        >
          <img src="images/icon_small-left_16.svg" alt="" className={`image_arrow_dropdown_2 image-arrow ${classPrefix}-image-arrow`} />
        </div>
        <div
          className={`list_item_material with_dropdown ${
            inGuide ? 'in_materiallist' : ''
          } image-border-container ${classPrefix}-list-item-info`}
        >
          {children({ setIsListExpanded, isListExpanded })}
        </div>
        {list && list(isListExpanded)}
      </div>
    </div>
  );
};

export default ListItemDropdownAsFunction;
