import React from 'react';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const SurgeryMaterialsRightMenu = withRouter(({ history }: RouteComponentProps) => {
  const { t } = useTranslation('briefing');
  const { t: searchTranslation } = useTranslation('materialSearch');
  const { surgeryStore, searchStore, surgeryGuideStore, domainStore } = useStores();
  const handleAddMaterial = () => {
    searchStore.openSearchPanel(m => surgeryGuideStore.addMaterialToGuide(m), searchTranslation('searchItem.addItem'));
  };
  const handleOpenPlaner = () => surgeryStore.setIsPlannerDrawerOpen(true);
  const handleGoToBriefing = () => {
    if (surgeryGuideStore.briefing) {
      history.push(`/surgery/briefing?surgeryId=${surgeryGuideStore.briefing.surgery.surgeryId}`);
    }
  };

  const goToOriginalGuide = () => {
    history.push(`/guide/materials?guideId=${surgeryGuideStore.surgeryGuide?.guide.guideId}`);
  };

  const { shortName: functionalAreaShortName } = domainStore.currentFunctionalArea;
  return (
    <>
      <SideMenuIconButton
        handleClick={handleAddMaterial}
        label={t('briefingMaterials.rightMenu.createMaterial')}
        iconPath="icon_f-add_16.svg"
      />
      <SideMenuIconButton
        handleClick={handleGoToBriefing}
        label={t('briefingMaterials.rightMenu.showBriefing')}
        iconPath="icon_person_16_blue.svg"
      />
      <SideMenuIconButton
        handleClick={goToOriginalGuide}
        label={t('briefingMaterials.rightMenu.goToGuide')}
        iconPath="icon_prototype_16.svg"
      />
      <SideMenuIconButton
        handleClick={handleOpenPlaner}
        label={t('briefingMaterials.rightMenu.openPlaner', { functionalAreaShortName })}
        iconPath="icon_calendar-date-2_16_blue.svg"
      />
    </>
  );
});

export default SurgeryMaterialsRightMenu;
