import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStores } from 'util/mobx/stores';
import { downloadFile } from 'util/download';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';

const GuideMaterialRightMenu = () => {
  const { guideStore, domainStore, postStore, flyoutStore, searchStore } = useStores();
  const { t } = useTranslation('guideMaterials');
  const { t: searchTranslation } = useTranslation('materialSearch');

  const handleSwitchSearchClick = () => {
    searchStore.openSearchPanel(m => guideStore.addMaterialToGuide(m), searchTranslation('searchItem.addItem'));
  };

  const handleGetMaterialClick = () => {
    const currentDepartmentId = domainStore.currentDepartment.id;
    if (guideStore.selectedGuide && currentDepartmentId && domainStore.departmentsByCurrentFunctionArea) {
      const departmentsIds = domainStore.departmentsByCurrentFunctionArea.departments.map(department => department.departmentId);
      guideStore.setGuideMaterialsToClone(guideStore.selectedGuide.guideId);
      guideStore.getGuidesByDepartmentsClone(departmentsIds);
      guideStore.setShowCloneGuideMaterialFlyout(true);
    }
  };

  const menuButtonClickHandlerWrapper = (event: React.MouseEvent, handler: (event: React.MouseEvent) => void) => {
    handler(event);
    event.preventDefault();
  };

  async function onShowHistoryFlyout() {
    if (guideStore.selectedGuide) {
      await guideStore.getHistory(guideStore.selectedGuide.guideId);
    }
    flyoutStore.setIsHistoryFlyoutOpen(true);
  }

  const onCommentsFlyoutOpen = async () => {
    if (guideStore.selectedGuide?.guideId) {
      await postStore.loadGuidePosts(guideStore.selectedGuide?.guideId);
      postStore.setIsChatFlyoutOpen(true);
    }
  };

  return (
    <>
      <Can I={actions.add} this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}>
        {!domainStore.isMobile && (
          <SideMenuIconButton handleClick={handleSwitchSearchClick} label={t('button.addMaterial')} iconPath="icon_f-add_16.svg" />
        )}
        <SideMenuIconButton
          handleClick={handleGetMaterialClick}
          label={t('button.getMaterial')}
          iconPath="icon_archive-e-download_16.svg"
        />
      </Can>
      <SideMenuIconButton handleClick={onCommentsFlyoutOpen} label={t('button.allComments')} iconPath="icon_icon_f-comment_16_blue.svg" />
      <Can I={actions.update} this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}>
        <SideMenuIconButton
          handleClick={event =>
            menuButtonClickHandlerWrapper(event, () => {
              if (guideStore.selectedGuide) {
                // guideStore.setSelectedGuide({ guideId: guideStore.selectedGuide.guideId, name: guideStore.selectedGuide.name });
                guideStore.setShowGuideFlyout(true);
              }
            })
          }
          label={t('button.renameManual')}
          iconPath="icon_background_16.svg"
        />
      </Can>
      <SideMenuIconButton handleClick={onShowHistoryFlyout} label={t('button.showMaterialHistory')} iconPath="icon_time-clock_16.svg" />
      {!domainStore.isMobile && (
        <SideMenuIconButton
          handleClick={() => guideStore.exportGuide().then(downloadFile)}
          label={t('button.export')}
          iconPath="icon_share_16.svg"
        />
      )}
      <Can I={actions.delete} this={subject(subjectArea.guide, { departmentId: guideStore.selectedGuide?.departmentId })}>
        <SideMenuIconButton
          handleClick={() => {
            guideStore.showDeleteGuideFlyout(guideStore.selectedGuide?.guideId);
          }}
          red
          label={t('button.deleteGuide')}
          iconPath="icon_bin_16.svg"
        />
      </Can>
    </>
  );
};

export default GuideMaterialRightMenu;
