import React from 'react';
import { PackageDTO } from 'dto/package';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import Popover from 'components/Popover';
import GetCameraImageButton from 'components/GetCameraImageButton';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import MaterialValueTags from 'components/MaterialValueTags';
import HoverWrapper from 'components/HoverWrapper';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import Picture from 'components/Picture';
import { useStores } from 'util/mobx/stores';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import SurgeryInstrumentListItem from '../SurgeryInstrumentListItem';
import SurgeryPackageListItemMenu from './SurgeryPackageListItemMenu';
import SurgeryMaterialCheckItem from '../SurgeryMaterialCheckItem';
import './SurgeryPackageListItem.css';

interface Props {
  packageItem: PackageDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
}

const SurgeryPackageListItem = ({ packageItem, surgeryGuide }: Props) => {
  const { surgeryGuideStore, guideDetailDrawerStore } = useStores();

  const onOpenDetails = () => {
    guideDetailDrawerStore.setSelectedPackage(packageItem);
    guideDetailDrawerStore.setSelectedSurgeryGuideItem(surgeryGuide);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const renderList = () => (
    <div className="content_holder no_padding padding_left in_matlist instruments-list">
      {packageItem.instruments.map(instrument => (
        <SurgeryInstrumentListItem instrument={instrument} key={instrument.instrumentId} />
      ))}
    </div>
  );

  const usedMaterial = surgeryGuide.usedMaterials.find(m => m.packageId === packageItem.packageId);

  const onStatusChange = (status: UsedMaterialStatus) => {
    surgeryGuideStore.setUsedMaterial({
      surgeryGuideMaterialId: surgeryGuide.surgeryGuideMaterialId,
      packageId: packageItem.packageId,
      status
    });
  };

  return (
    <HoverWrapper>
      {({ hover }) => (
        <ListItemDropdownContainer classPrefix="package" className="surgery-package" list={renderList()} inGuide>
          <>
            <div className="item_count _1_lvl hidden" onClick={onOpenDetails} />
            <div className="item_dropdown_holder" onClick={onOpenDetails} />
            <div className="image_wrapper_50">
              <SurgeryMaterialCheckItem
                inSublist
                onStatusChanged={onStatusChange}
                status={usedMaterial?.status}
                surgeryGuide={surgeryGuide}
                usedMaterial={usedMaterial}
              />
              {packageItem.pictures.length > 0 ? (
                <Picture src={packageItem.pictures[0].picture} width={50} alt="" className="image_circle_50" />
              ) : (
                <GetCameraImageButton buttonStyle="icon" description="" picturesOnly isAddable />
              )}
              <div className="material_lvl1_line">
                <div className="dot in_mat_list" />
              </div>
            </div>
            <div className="material_info w-inline-block package-info">
              <div className="material_text material-text" onClick={onOpenDetails}>
                <div>{packageItem.name}</div>
              </div>
              <div className="item_number" onClick={onOpenDetails} />
              <MaterialValueTags pack={packageItem} />
              <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                {({ handleClose, isOpen }) => (
                  <SurgeryPackageListItemMenu
                    isOpen={!!isOpen}
                    handleClose={handleClose}
                    surgeryGuide={surgeryGuide}
                    usedMaterial={usedMaterial}
                    onOpenDetails={onOpenDetails}
                  />
                )}
              </Popover>
            </div>
          </>
        </ListItemDropdownContainer>
      )}
    </HoverWrapper>
  );
};

export default SurgeryPackageListItem;
