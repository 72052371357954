import React from 'react';
import { SurgeryStatisticDTO } from 'dto/statistic';
import StatusBar from 'components/StatusBar';

interface Props {
  stats: SurgeryStatisticDTO;
}

const SurgeryListItemStats = ({ stats }: Props) => {
  return (
    <div className="div-block-122">
      <StatusBar isDetailed={false} all={stats.material.all} checked={stats.material.checked} />
      <StatusBar isDetailed={false} all={stats.procedure.all} checked={stats.procedure.checked} />
    </div>
  );
};

export default SurgeryListItemStats;
