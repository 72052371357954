import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import UserDetailsForm from 'components/UserDetailsForm';
import DepartmentList from 'components/DepartmentList';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import { SecureOptionalAccountDTO, Gender } from 'dto/user';
import * as Yup from 'yup';

export interface UserSettingsInitialValues extends SecureOptionalAccountDTO {
  passwordConfirm?: string;
}

const UserSettingsData = observer(() => {
  const { t: translationAccountManagement } = useTranslation('accountManagement');
  const { t: translationSignup } = useTranslation('signup');

  const { userStore, loadingStore } = useStores();

  useEffect(() => {
    userStore.setLoggedInUserAsSelected();
  }, [loadingStore, userStore]);

  const initialValues: UserSettingsInitialValues = {
    salutationKey: userStore.selectedUser ? userStore.selectedUser.salutationKey : Gender.Female,
    titleId: userStore.selectedUser ? userStore.selectedUser.titleId : '',
    firstName: userStore.selectedUser ? userStore.selectedUser.firstName : '',
    lastName: userStore.selectedUser ? userStore.selectedUser.lastName : '',
    emailAddress: userStore.selectedUser ? userStore.selectedUser.emailAddress : '',
    phoneNumber: userStore.selectedUser ? userStore.selectedUser.phoneNumber : '',
    username: userStore.selectedUser ? userStore.selectedUser.username : '',
    userId: userStore.selectedUser ? userStore.selectedUser.userId : '',
    currentPassword: '',
    newPassword: '',
    passwordConfirm: ''
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(translationSignup('validation.fieldRequired')),
    lastName: Yup.string().required(translationSignup('validation.fieldRequired')),
    titleId: Yup.string().required(translationSignup('validation.fieldRequired')),
    username: Yup.string()
      .required(translationSignup('validation.fieldRequired'))
      .min(4, translationSignup('validation.usernameMinLength'))
      .max(20, translationSignup('validation.usernameMaxLength')),
    newPassword: Yup.string().min(8, translationSignup('validation.passwordLength')),
    passwordConfirm: Yup.string().when('newPassword', {
      is: value => !!value,
      then: Yup.string()
        .required(translationSignup('validation.fieldRequired'))
        .oneOf([Yup.ref('newPassword')], translationSignup('validation.passwordsMatch'))
    }),
    currentPassword: Yup.string().required(translationSignup('validation.fieldRequired')),
    emailAddress: Yup.string().email(translationSignup('validation.invalidEmailAddress'))
  });
  return userStore.selectedUser ? (
    <>
      <div className="div-block-127">
        <div className="div_block_131">
          <div className="flyout_box_head">
            <div className="item_name-copy">
              <div>
                <div className="t2 bottom-margin">{userStore.selectedUser.title}</div>
                <div>{`${userStore.selectedUser.firstName}, ${userStore.selectedUser.lastName}`}</div>
              </div>
            </div>
          </div>
          <div className="line-copy" />
          <UserDetailsForm
            viewOnly={false}
            inUserSettings
            initialValues={initialValues}
            className="in-user-settings"
            validationSchema={validationSchema}
          />
          <div className="h1 top-margin">Weitere Einstellungen und Informationen</div>

          <div className="flyout_box border_around">
            <div>{translationAccountManagement('userDetail.latestLogin')}</div>
            <div className="text-block-29">
              {moment(userStore.selectedUser?.lastOnline).format(translationAccountManagement('userDetail.latestLoginFormat'))}
            </div>
          </div>

          <div className="flyout_box border_around">
            <DepartmentList
              departmentMaintainerOf={userStore.selectedUserLeaderOf}
              headline={translationAccountManagement('userDetail.departmentOrganisation.maintainerIsLead')}
              onChangeButtonText={translationAccountManagement('userDetail.departmentOrganisation.onChangeButton')}
            />
          </div>
          <div className="flyout_box border_around">
            <DepartmentList
              departmentMaintainerOf={userStore.selectedUserMaintainerOf}
              headline={translationAccountManagement('userDetail.departmentOrganisation.maintainerIsNotLead')}
              onChangeButtonText={translationAccountManagement('userDetail.departmentOrganisation.onChangeButton')}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
});

export default UserSettingsData;
