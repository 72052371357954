import React from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import Chat from 'components/Chat';
import NavSection from 'components/NavSection/NavSection';
import Popover from 'components/Popover';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import Button from 'components/Form/Button';
import './Page.css';

interface Props {
  children: JSX.Element | JSX.Element[];
  navigation?: JSX.Element | JSX.Element[];
  headline?: string;
  idPrefix: string;
  gridClass?: string;
  functionsClass?: string;
  wrapperContentClass?: string;
  navigationClassName?: string;
  disableBackButton?: boolean;
  withChatBar?: boolean;
  withoutRightMenu?: boolean;
  withoutRightMenuClass?: boolean;
  chatConfigurationType?: ChatConfigurationTypes;
  withoutMoreBtn?: boolean;
}

// IMPORTANT: This page does not use the observer(...) but the useObserver(...) hook.
// This prevents a loading-loop because the re-rendering of the children also triggers a
// re-render of the rightMenuComponent.
const Page = ({
  children,
  navigation,
  headline,
  idPrefix,
  disableBackButton = false,
  withChatBar = true,
  withoutRightMenu = false,
  withoutRightMenuClass = true,
  chatConfigurationType,
  wrapperContentClass = '',
  functionsClass = '',
  gridClass = '',
  navigationClassName = '',
  withoutMoreBtn = false
}: Props) => {
  const { appNavigationStore, loadingStore } = useStores();
  const { t } = useTranslation('navigation');

  const rightMenuComponent = useObserver(() => appNavigationStore.rightMenuComponent);

  return (
    <>
      {useObserver(() => loadingStore.isLoading && loadingStore.withOverlay && <div className="div-block-154" />)}
      <div className={`w-layout-grid ${!withChatBar ? 'no-chat-bar' : ''} ${gridClass || 'grid_main grid-main'}`}>
        <div
          className={`wrapper_content wrapper-content ${
            withoutRightMenu && withoutRightMenuClass ? 'without-right-menu' : ''
          } ${wrapperContentClass}`}
          id={`grid-${idPrefix}${appNavigationStore.subPageId ? `-${appNavigationStore.subPageId}` : ''}-wrapper_content`}
        >
          {children}
        </div>
        {useObserver(() => (
          <>
            {navigation && (
              <NavSection className={navigationClassName} headline={headline} disableBackButton={disableBackButton}>
                <>
                  {navigation}
                  {!withoutMoreBtn ? (
                    <div className="nav_more">
                      <Popover
                        className="more_buttons"
                        trigger={
                          <Button className="btn_section btn_more">
                            <img src="images/icon_menu-5_16.svg" className="image_section" alt="" />
                            <div>{t('more')}</div>
                          </Button>
                        }
                      >
                        {({ handleClose }) => (
                          <>
                            <div className="line_more_menu" />
                            <div onClick={handleClose} className="more-buttons">
                              {rightMenuComponent}
                            </div>
                          </>
                        )}
                      </Popover>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </NavSection>
            )}
            <div className={`wrapper_functions ${functionsClass}`}>{!withoutRightMenu && appNavigationStore.rightMenuComponent}</div>
            {withChatBar && chatConfigurationType && <Chat chatConfigurationType={chatConfigurationType} />}
          </>
        ))}
      </div>
    </>
  );
};

export default Page;
