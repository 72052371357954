/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, IsString, IsOptional, IsEnum } from 'class-validator';

export enum UsedMaterialStatus {
  /**
   * None means the same as if the usedMaterial doesn't exist.
   * in most cases "unprepared"
   */
  None,

  /**
   * Prepared is the default for simple checks.
   */
  Prepared,

  Planned,
  NotNeeded,
  Used,
  Implanted,
  UsedNotImplanted
}

/**
 * UsedMaterialLikeIdDTO contains any id of material-like entities which can be used (e.g. groupMaterialId, package)
 * But it should only contain one at a time.
 * Also it is meant as "addition" to the material given by surgeryGuideMaterialId in used
 * material to identify specific items of templates or sets.
 */
export class UsedMaterialLikeIdDTO {
  @IsUUID()
  @IsOptional()
  groupMaterialId?: string;

  @IsUUID()
  @IsOptional()
  packageId?: string;
}

export class UsedMaterialDTO extends UsedMaterialLikeIdDTO {
  @IsUUID()
  usedMaterialId!: string;

  @IsUUID()
  surgeryGuideMaterialId!: string;

  @IsString()
  @IsOptional()
  lotNumber?: string;

  @IsEnum(UsedMaterialStatus)
  status!: UsedMaterialStatus;
}

export class CreateUsedMaterialDTO extends UsedMaterialLikeIdDTO {
  @IsUUID()
  surgeryGuideMaterialId!: string;

  /**
   * For now this Lot-number is just another text to save.
   */
  @IsString()
  @IsOptional()
  lotNumber?: string;
}

export class SetUsedMaterialDTO extends UsedMaterialLikeIdDTO {
  @IsUUID()
  surgeryGuideMaterialId!: string;

  @IsOptional()
  @IsEnum(UsedMaterialStatus)
  status?: UsedMaterialStatus;

  @IsOptional()
  @IsString()
  lotNumber?: string;
}
