import { CreateSetGroupDTO, SetGroupDTO, UpdateSetGroupDTO, UpdateSortSetGroupsDTO } from 'dto/setGroup';
import { post, patch, del, put } from './common';

import { validate, validateBool } from './validation';

export const createSetGroup = async (setGroup: CreateSetGroupDTO): Promise<SetGroupDTO> => {
  const newSetGroup = await post('/material-set/set-group', setGroup).then(response => response.data);
  return validate(SetGroupDTO, newSetGroup);
};

export const updateSetGroup = async (materialSetUpdateFields: UpdateSetGroupDTO): Promise<SetGroupDTO> => {
  const updatedSetGroup = await patch(`/material-set/set-group`, materialSetUpdateFields).then(response => response.data);
  return validate(SetGroupDTO, updatedSetGroup);
};

export const deleteSetGroup = async (setGroupId: string) => {
  return del('/material-set/set-group', { setGroupId }).then(response => validateBool(response.data));
};

export const reorderSetGroups = async (reordered: UpdateSortSetGroupsDTO) => {
  return put('/material-set/set-groups/positions', reordered).then(response => validateBool(response.data));
};
