import React, { useEffect } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import { observer } from 'mobx-react';
import AppLayout from 'components/AppLayout';
import Picture from 'components/Picture';
import Page from 'components/Page';
import Safe from 'components/Safe';
import SurgeryPlannerDrawer from 'pages/flyouts/SurgeryPlannerDrawer';
import { useStores } from 'util/mobx/stores';
import { ChatConfigurationTypes } from 'components/Chat/Chat';
import SurgeryProcedures from 'pages/surgeryProcedures';
import SurgeryMaterials from 'pages/surgeryMaterials';
import ProcedureFormFlyout from 'pages/guideProcedure/ProcedureFormFlyout';
import SurgeryProceduresDrawer from 'pages/surgeryProcedures/SurgeryProceduresDrawer';
import BoardFlyout from 'components/Chat/BoardFlyout/BoardFlyout';
import EditLotNumberFlyout from 'pages/surgeryMaterials/EditLotNumberFlyout';
import SelectLocationFlyout from 'pages/flyouts/SelectLocationFlyout';
import SelectMaterialLocationFlyout from 'pages/flyouts/SelectMaterialLocationFlyout';
import GetCameraImageButton from 'components/GetCameraImageButton';
import BriefingNavigation from './SurgeryGuideNavigation';
import SurgeryGuideBarHeader from './SurgeryGuideBarHeader';
import './SurgeryGuide.css';

type Props = RouteComponentProps & {
  location: {
    pathname: string;
    search: string;
  };
};

const SurgeryGuide = withRouter(
  observer(({ location }: Props) => {
    const { surgeryGuideStore } = useStores();

    const surgeryGuideId = qs.parse(location.search).surgeryGuideId as string;

    useEffect(() => {
      surgeryGuideStore.loadGuide(surgeryGuideId);
    }, [surgeryGuideStore, surgeryGuideId]);

    useEffect(() => {
      if (surgeryGuideStore.surgeryGuide) {
        surgeryGuideStore.loadBriefing(surgeryGuideStore.surgeryGuide.briefingId);
      }
    }, [surgeryGuideStore, surgeryGuideStore.surgeryGuide]);

    return (
      <AppLayout
        appBarIcon={
          <div className="title_icon title-icon">
            {surgeryGuideStore.briefing?.surgery.patientPicture?.pictureThumbnail ? (
              <Picture
                src={surgeryGuideStore.briefing?.surgery.patientPicture?.pictureThumbnail}
                width={40}
                sizes="40px"
                alt=""
                className="image_circle_40"
              />
            ) : (
              <GetCameraImageButton buttonStyle="userIcon" description="" picturesOnly />
            )}
          </div>
        }
        flyoutInDrawer={
          <>
            <SelectLocationFlyout />
            <SelectMaterialLocationFlyout />
          </>
        }
        fullPageDrawer={<SurgeryProceduresDrawer />}
        drawers={<SurgeryPlannerDrawer />}
        boardFlyout={<BoardFlyout />}
        appBarChildren={<SurgeryGuideBarHeader />}
        flyout={
          <>
            <ProcedureFormFlyout isSurgery />
            <EditLotNumberFlyout />
          </>
        }
      >
        <Page
          navigation={<BriefingNavigation />}
          gridClass="grid_main_checkable"
          idPrefix="surgery-guides"
          wrapperContentClass="flex_content surgery-guide-content margin_right"
          chatConfigurationType={ChatConfigurationTypes.briefing}
        >
          <Route path="/surgery-guide/procedures" component={() => <SurgeryProcedures surgeryGuideId={surgeryGuideId} />} />
          <Route path="/surgery-guide/materials" component={() => <SurgeryMaterials surgeryGuideId={surgeryGuideId} />} />
        </Page>
      </AppLayout>
    );
  })
);

export default () => (
  <Safe>
    <SurgeryGuide />
  </Safe>
);
