import React, { useEffect } from 'react';
import { Validator } from 'class-validator';
import { useStores } from 'util/mobx/stores';
import CollapsibleBox from 'components/CollapsibleBox';
import { GuideListItem } from 'components/ListItems';
import { FullGuideDTO } from 'dto/guide';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MaterialSetGlobalFunctionsRightMenu from './MaterialSetGlobalFunctionsRightMenu';
import MaterialSetActions from './MaterialSetActions';

interface Props {
  materialSetId: string;
  groupType: string;
}

const MaterialSetGlobalFunctions = observer(({ materialSetId, groupType }: Props) => {
  const { materialSetsStore, appNavigationStore } = useStores();
  const validator = new Validator();

  const { t } = useTranslation(groupType);
  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <MaterialSetGlobalFunctionsRightMenu groupType={groupType} />;
    });
  }, [appNavigationStore, groupType]);

  useEffect(() => {
    materialSetsStore.loadGuides(materialSetId);
  }, [materialSetsStore, materialSetId]);

  appNavigationStore.useSubPageIdSetter('functions');

  if (validator.isUUID(materialSetId) && materialSetsStore.guides) {
    return (
      <div className="single_colum_content with_list _5px-padding">
        <div className="content_filter">
          <div className="btn_filter_content active">
            <div className="txt_dropout_1">{t('globalFunctions.button.containingGuidesFilter')}</div>
          </div>
        </div>
        {materialSetsStore.guides.map(groupedGuide => (
          <div className="post_item_head_topic post-item-head" key={groupedGuide.department.departmentId}>
            <CollapsibleBox
              head={
                <div>
                  {groupedGuide.department.locationShortName} | {groupedGuide.department.functionalAreaShortName} |{' '}
                  {groupedGuide.department.name}
                </div>
              }
            >
              {groupedGuide.guides.map((guide: FullGuideDTO) => (
                <GuideListItem key={guide.guideId} guide={guide} actions={<MaterialSetActions guide={guide} groupType={groupType} />} />
              ))}
            </CollapsibleBox>
          </div>
        ))}
      </div>
    );
  }
  return null;
});

export default MaterialSetGlobalFunctions;
