import React from 'react';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { ContentType } from 'dto/file';
import Video from 'components/Video/Video';
import Document from 'components/Document';
import './CommentFileBox.css';
import { useStores } from 'util/mobx/stores';
import DownloadButton from 'components/DownloadButton';
import { useSecureURL } from 'util/hooks';
import Picture from 'components/Picture';

interface Props {
  file: ContentElementMediaDTO;
  onDeleteImage?: (imageId: string) => void;
  inBoard?: boolean;
}

const CommentFileBox = ({ file, onDeleteImage, inBoard = false }: Props) => {
  const { flyoutStore } = useStores();
  const onDelete = () => {
    if (onDeleteImage) {
      onDeleteImage(file.fileId);
    }
  };
  const imageURL = useSecureURL(file.fullUrl);

  return (
    <div className="div-block-85 comment-file">
      {onDeleteImage && (
        <div className="remove_hitbox" onClick={onDelete}>
          <div className="remove">
            <img src="images/icon_c-remove_12.svg" alt="" />
          </div>
        </div>
      )}
      {file.contentType !== ContentType.Picture && file.contentType !== ContentType.Video && inBoard && (
        <div className="in-board">
          <Document file={file} type={file.contentType} title={file.title} isSmall showDownloadButton={inBoard} />
        </div>
      )}
      {file.contentType !== ContentType.Picture && file.contentType !== ContentType.Video && !inBoard && (
        <Document file={file} type={file.contentType} title={file.title} isSmall showDownloadButton={inBoard} />
      )}

      {file.contentType === ContentType.Picture && (
        <>
          {inBoard && <DownloadButton file={file} />}
          <div
            className={`image_post_wide ${inBoard ? 'in_board in-board' : 'in_comment'}`}
            onClick={() => flyoutStore.setImagesToView(file)}
          >
            <Picture src={imageURL} width="344" alt="" />
          </div>
        </>
      )}
      {file.contentType === ContentType.Video && (
        <div className={`image_post_wide ${inBoard ? 'in_board in-board' : 'in_comment'}`}>
          <Video file={file} small />
        </div>
      )}
    </div>
  );
};

export default CommentFileBox;
