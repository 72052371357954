/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClassType, TransformValidationOptions, transformAndValidate } from 'class-transformer-validator';
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  Validator,
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidatorOptions
} from 'class-validator';
import { ErrorCode } from 'dto/errorCodes';
import { ApiError } from 'util/error';

const validator = new Validator();

export async function validateArray<T extends object>(
  type: ClassType<T>,
  data: any[],
  validatorOptions: ValidatorOptions = {}
): Promise<T[]> {
  const options: TransformValidationOptions = {
    validator: {
      forbidUnknownValues: true,
      forbidNonWhitelisted: true,
      whitelist: true,
      ...validatorOptions
    }
  };
  try {
    // transform and validate
    const userObject = (await transformAndValidate(type, data, options)) as T[];
    return userObject;
  } catch (allErrors) {
    throw new ApiError(`validationErrors: ${JSON.stringify(allErrors, null, '\t')}`, ErrorCode.FrontendValidationError);
  }
}

export async function validate<T extends object>(type: ClassType<T>, data: any, validatorOptions: ValidatorOptions = {}): Promise<T> {
  return ((await validateArray(type, [data], validatorOptions)) as T[])[0];
}

@ValidatorConstraint({ name: 'Match' })
export class MatchConstraint implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments) {
    const [relatedPropertyName] = args.constraints;
    const relatedValue = (args.object as any)[relatedPropertyName];
    return value === relatedValue;
  }
}

/**
 * accepts a Field and checks if the field has the same value
 */
export function Match(property: string, validationOptions?: ValidationOptions) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [property],
      validator: MatchConstraint
    });
  };
}

export function validateBool(data: unknown) {
  if (validator.isBoolean(data)) {
    return data as boolean;
  }

  throw new ApiError('expected boolean but got something different', ErrorCode.FrontendValidationError);
}
