import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import ScrollPositionManager from 'components/ScrollPositionManager';
import DepartmentLead from 'components/DepartmentLead';
import ListItemText from 'components/ListItemText';
import TemplatesList from './TemplatesList';
import InstrumentsRightMenu from './InstrumentsRightMenu';

const Instruments = observer(() => {
  const { appNavigationStore, domainStore, instrumentStore, loadingStore } = useStores();
  const [filterListBy, setfilterListBy] = useState(domainStore.currentDepartment.id);
  const { t } = useTranslation('instruments');
  useEffect(() => {
    if (filterListBy === domainStore.currentDepartment.id) {
      instrumentStore.loadTemplates(domainStore.currentDepartment.id);
    }
    if (filterListBy === 'all') {
      instrumentStore.loadTemplates();
    }
  }, [instrumentStore, domainStore.currentDepartment.id, filterListBy]);
  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <InstrumentsRightMenu />;
    });
  }, [appNavigationStore]);

  const onSelectDepartment = (id: string) => setfilterListBy(id);

  return (
    <div className="w-layout-grid grid_colum_content">
      <div id="grid-guides-colum_left" className="colum_left">
        <DepartmentLead />
        <div className="line" />
        <ListItemText
          text={t('categoryList.usedInDepartments')}
          isSelected={domainStore.currentDepartment.id === filterListBy}
          onClick={() => onSelectDepartment(domainStore.currentDepartment.id)}
        />
        <ListItemText
          text={t('categoryList.allInstruments')}
          isSelected={filterListBy === 'all'}
          onClick={() => onSelectDepartment('all')}
        />
      </div>
      {!loadingStore.isLoading && (
        <ScrollPositionManager
          scrollKey="grid-guides-colum-right"
          id="grid-guides-colum_right"
          className="colum_right materials colum-scroll"
          style={{ gridColumnStart: '2' }}
        >
          <TemplatesList templates={instrumentStore.templates} filterListBy={filterListBy} />
        </ScrollPositionManager>
      )}
    </div>
  );
});

export default Instruments;
