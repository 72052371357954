import React from 'react';
import { observer } from 'mobx-react';
import { TemplateDTO } from 'dto/template';
import { GuideMaterialLikeDTO } from 'dto/guide';
import PackageListItem from 'components/ListItems/PackageListItem';
import { PackagingType } from 'dto/package';
import SingleInstrumentListItem from '../SingleInstrumentListItem';
import Instructions from '../components/Instructions';
import './GuideTemplateListItem.css';
import ListItemDropdownContainer from '../ListItemDropdownContainer';

interface Props {
  template: TemplateDTO;
  guideData: GuideMaterialLikeDTO;
  className?: string;
  isClone?: boolean;
}

const GuideTemplateListItem = observer(({ template, guideData, className = '', isClone = false }: Props) => {
  const renderList = () => (
    <div className="content_holder no_padding padding_left in_matlist lvl_1 guide-template-instruments-list">
      {template.packages.map(packageItem => {
        if (packageItem.packagingType === PackagingType.Single) {
          return (
            <SingleInstrumentListItem key={packageItem.packageId} singleInstrument={packageItem} guideData={guideData} isClone={isClone} />
          );
        }
        return <PackageListItem key={packageItem.packageId} packageItem={packageItem} guideData={guideData} isClone={isClone} />;
      })}
      <div className="div-block-102_fixed-copy">
        <div className="div-block-108-copy" />
      </div>
    </div>
  );
  return (
    <ListItemDropdownContainer list={renderList()} classPrefix="template" inGuide className={className}>
      <div className="item_count _1_lvl">
        <div>{guideData.amount}</div>
      </div>
      <div className="item_dropdown_holder" />
      <div className="image_wrapper_50">
        <div className="image_border white image-border" />
        <img src="images/icon_layer.jpg" alt="" className="image_circle_50" />
      </div>
      <div className="material_info">
        <div className="material_text">
          <div>{template.name}</div>
          <Instructions notes={guideData.notes} />
        </div>
      </div>
    </ListItemDropdownContainer>
  );
});

export default GuideTemplateListItem;
