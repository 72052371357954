import React, { useState, useEffect } from 'react';
import { SurgeryTabs } from 'stores/surgeryStore';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import Tabs from './Tabs';
import SurgeryPostItem from './SurgeryPostItem';
import SurgeryHistory from './SurgeryHistory';
import { SurgeryHistoryFilterTypes } from './SurgeryHistoryFilters';

const SurgeryChatAndHistory = observer(() => {
  const { postStore, surgeryStore } = useStores();
  const [selectedTab, setSelectedTab] = useState(SurgeryTabs.chat);
  const [selectedFilter, setSelectedFilter] = useState<SurgeryHistoryFilterTypes | undefined>();
  const onTabSelect = (tab: SurgeryTabs) => setSelectedTab(tab);
  useEffect(() => {
    if (selectedTab === SurgeryTabs.history && surgeryStore.selectedSurgeryBriefing) {
      surgeryStore.loadBriefingHistory(surgeryStore.selectedSurgeryBriefing.briefingId);
    }
  }, [surgeryStore, surgeryStore.selectedSurgeryBriefing, selectedTab]);

  useEffect(() => {
    if (surgeryStore.selectedSurgeryBriefing) {
      postStore.loadBriefingPosts(surgeryStore.selectedSurgeryBriefing.briefingId);
    }
  }, [surgeryStore.selectedSurgeryBriefing, postStore]);

  return (
    <div className="chat-wrapper-copy chat-wrapper-styled">
      <Tabs selectedTab={selectedTab} onTabSelect={onTabSelect} selectedFilter={selectedFilter} onFilterSelect={setSelectedFilter} />
      {selectedTab === SurgeryTabs.chat ? (
        <div className="chat_inner_wrapper chat-inner-wrapper-styled in_operationen_2">
          {postStore.briefingPosts?.map(post => (
            <SurgeryPostItem post={post} key={post.postId} />
          ))}
        </div>
      ) : (
        <div className="chat_inner_wrapper in_operationen_3">
          <SurgeryHistory selectedFilter={selectedFilter} />
        </div>
      )}
    </div>
  );
});

export default SurgeryChatAndHistory;
