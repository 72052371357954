import React from 'react';
import { useTranslation } from 'react-i18next';
import { MaterialStorageLocationsDTO } from 'dto/storageLocation';

interface Props {
  materialStorageLocations: MaterialStorageLocationsDTO[];
}

const StorageLocationPathInMaterial = ({ materialStorageLocations }: Props) => {
  const { t } = useTranslation('storageLocationItems');

  return (
    <div className="post_item no_margin">
      <div className="post_box material_detail">
        <div className="flex_horizontal">
          <div>{t('erpTitle')}</div>
        </div>
        <div className="field_location top_margin flex_left location-boxes-container">
          {materialStorageLocations.map(storageLocation => (
            <div className="location_box margin_right" key={storageLocation.originStorageLocation.storageLocationId}>
              <div className="info_box location">
                <div className="div-block-7">
                  <div>{storageLocation.originStorageLocation.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StorageLocationPathInMaterial;
