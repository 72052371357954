import React, { useState } from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import animationCheckData from 'components/ListItems/EditableGroupMaterialListItem/CheckListItemLottie/animationData.json';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import { UsedMaterialDTO, UsedMaterialStatus } from 'dto/usedMaterial';
import animationReverseCheckData from './checkbox_reverse.json';
import SurgeryMaterialCheckDropdown from '../SurgeryMaterialCheckDropdown';

interface Props extends React.HTMLProps<HTMLDivElement> {
  status?: UsedMaterialStatus;
  checked?: boolean;
  onStatusChanged: (status: UsedMaterialStatus) => void;
  onCheckChanged?: (status: boolean) => void;
  notEditable?: boolean;
  noCheck?: boolean;
  inSublist?: boolean;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  usedMaterial?: UsedMaterialDTO;
}

const SurgeryMaterialCheckItem = ({
  status,
  onStatusChanged,
  notEditable = false,
  noCheck = false,
  inSublist = false,
  surgeryGuide,
  usedMaterial,
  ...rest
}: Props) => {
  const [isAnimationPaused, setIsAnimationPaused] = useState(true);
  const defaultOptions: LottieProps['options'] = {
    autoplay: false,
    loop: false,
    animationData: status === UsedMaterialStatus.Prepared ? animationReverseCheckData : animationCheckData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    if (notEditable) {
      return;
    }
    setIsAnimationPaused(false);
    setTimeout(() => {
      if (status === UsedMaterialStatus.Prepared) {
        onStatusChanged(UsedMaterialStatus.None);
      } else {
        onStatusChanged(UsedMaterialStatus.Prepared);
      }
    }, 1000);
  };

  const onDropdownStatusChange = (s: UsedMaterialStatus) => {
    if (
      (s === UsedMaterialStatus.None || s === UsedMaterialStatus.Prepared) &&
      (status === UsedMaterialStatus.None || status === UsedMaterialStatus.Prepared)
    ) {
      setIsAnimationPaused(false);
      setTimeout(() => {
        onStatusChanged(s);
      }, 1000);
    } else {
      onStatusChanged(s);
    }
  };

  return (
    <>
      {!(status && status !== UsedMaterialStatus.Prepared) &&
        (noCheck ? (
          <div className="status_only sub_list sub-list-check" />
        ) : (
          <div
            onClick={onClick}
            className={`btn_checkbox_in_matlist btn-checkbox-in-matlist ${notEditable ? 'not-editable' : ''}`}
            {...rest}
          >
            <Lottie
              ariaRole="button"
              options={defaultOptions}
              direction={1}
              speed={1.5}
              isPaused={isAnimationPaused}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => setIsAnimationPaused(true)
                }
              ]}
              isClickToPauseDisabled={notEditable}
            >
              <img src="images/check-white.svg" alt="" />
            </Lottie>
          </div>
        ))}

      <SurgeryMaterialCheckDropdown
        surgeryGuide={surgeryGuide}
        inSublist={inSublist}
        onStatusChanged={onDropdownStatusChange}
        status={status}
        usedMaterial={usedMaterial}
      />
    </>
  );
};

export default SurgeryMaterialCheckItem;
