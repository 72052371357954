import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import OverviewBox from 'components/OverviewBox';
import InstrumentOverviewRightMenu from './InstrumentOverviewRightMenu';

interface Props {
  instrumentId: string;
}

const InstrumentOverview = observer(({ instrumentId }: Props) => {
  const { t } = useTranslation('instruments');
  const { appNavigationStore } = useStores();
  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <InstrumentOverviewRightMenu />;
    });
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('overview');

  return (
    <div className="single_colum_content no_padding_right">
      <div className="div-block-48">
        <OverviewBox
          link={`/instrument/detail?instrumentId=${instrumentId}`}
          name={t('overview.baseData.name')}
          text={t('overview.baseData.textHtml')}
          btnText={t('overview.baseData.btnText')}
          image="data-table_16"
        />
        <OverviewBox
          link={`/instrument/content?instrumentId=${instrumentId}`}
          name={t('overview.knowledge.name')}
          text={t('overview.knowledge.textHtml')}
          btnText={t('overview.knowledge.btnText')}
          image="bulb-62_16"
        />
      </div>
    </div>
  );
});

export default InstrumentOverview;
