import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { GuideMaterialLikeDTO } from 'dto/guide';
import { GroupedMaterialKnowledgeDTO, MaterialKnowledgeDTO } from 'dto/materialKnowledge';
import PostItem from 'components/PostItem';
import Picture from 'components/Picture';
import CollapsibleBox from 'components/CollapsibleBox';
import CustomRadioButton from 'components/CustomRadioButton';
import { getKnowledge } from 'api/knowledge';
import MaterialValueTags from 'components/MaterialValueTags';
import { OptionalPictureDTO } from 'dto/file';
import { IconType } from 'dto/procedure';

interface Props {
  guideMaterial: GuideMaterialLikeDTO;
  handleAddLinkMaterialKnowledgeId: (knowledgeId?: string) => void;
  isKnowledgeSelected: (knowledgeId: string) => boolean;
  isOpen: boolean;
  openHandler: () => void;
}

export default observer(({ guideMaterial, handleAddLinkMaterialKnowledgeId, isKnowledgeSelected, isOpen, openHandler }: Props) => {
  const { t } = useTranslation('guide');
  const [knowledge, setKnowledge] = useState<GroupedMaterialKnowledgeDTO[]>();

  const handleMaterialClick = () => {
    // lazy load the knowledge on open
    if (knowledge === undefined) {
      getKnowledge({
        materialId: guideMaterial.material?.materialId,
        packageId: guideMaterial.template?.packages[0].packageId,
        materialSetId: guideMaterial.materialSet?.materialSetId
      }).then(setKnowledge);
    }
    openHandler();
  };

  let image: OptionalPictureDTO;

  if (guideMaterial.material) {
    const { pictureFileId, picture, pictureThumbnail } = guideMaterial.material;
    image = { pictureFileId, picture, pictureThumbnail };
  }
  if (guideMaterial.materialSet) {
    const { pictureFileId, picture, pictureThumbnail } = guideMaterial.materialSet;
    image = { pictureFileId, picture, pictureThumbnail };
  }

  const getMaterialBox = (knowledgeElements: JSX.Element) => {
    let picturePath: string | undefined;
    let name: string;
    let tags: JSX.Element;

    if (guideMaterial.material) {
      picturePath = image.pictureThumbnail;
      name = guideMaterial.material.name;
      tags = <MaterialValueTags material={guideMaterial.material} />;
    } else if (guideMaterial.template) {
      picturePath = 'images/icon_layer.jpg';
      name = guideMaterial.template.name;
      tags = <MaterialValueTags template={guideMaterial.template} />;
    } else if (guideMaterial.materialSet) {
      picturePath = image.pictureThumbnail;
      name = guideMaterial.materialSet.name;
      tags = <MaterialValueTags materialSet={guideMaterial.materialSet} />;
    } else {
      return <></>;
    }
    return (
      <CollapsibleBox
        isClone
        head={
          <>
            <div className="image_wrapper_50">
              <div className="image_border" />
              <Picture src={picturePath} alt={name} className="image_circle_50" />
            </div>
            <div>{name}</div>
          </>
        }
        valueTag={tags}
        onClick={handleMaterialClick}
        close={!isOpen}
      >
        {knowledgeElements}
      </CollapsibleBox>
    );
  };

  return getMaterialBox(
    knowledge ? (
      <div className="content_holder">
        {knowledge.map((materialKnowledge: GroupedMaterialKnowledgeDTO) => (
          <React.Fragment key={materialKnowledge.materialChapterId}>
            <div className="txt_chapter_flyout">{materialKnowledge.materialChapter}</div>
            {materialKnowledge.knowledgeElements.map(
              (knowledgeElement: MaterialKnowledgeDTO) =>
                knowledgeElement.materialKnowledgeElementId && (
                  <PostItem
                    key={knowledgeElement.materialKnowledgeElementId}
                    title={knowledgeElement.content.title}
                    user={knowledgeElement.content.user}
                    files={knowledgeElement.content.contentElementMedias}
                    iconType={IconType.UserPicture}
                    radioButton={
                      <CustomRadioButton
                        title={t('cloneGuideProcedure.button.linkProcedure')}
                        checked={
                          knowledgeElement.materialKnowledgeElementId
                            ? isKnowledgeSelected(knowledgeElement.materialKnowledgeElementId)
                            : false
                        }
                        onClick={() => handleAddLinkMaterialKnowledgeId(knowledgeElement.materialKnowledgeElementId)}
                      />
                    }
                  >
                    <div>{knowledgeElement.content.description}</div>
                  </PostItem>
                )
            )}
          </React.Fragment>
        ))}
      </div>
    ) : (
      <div />
    )
  );
});
