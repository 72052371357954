import React from 'react';
import { observer } from 'mobx-react';

import { ContentType } from 'dto/file';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { ImageBox, VideoBox, FileBox } from 'components/Posts';

interface Props {
  files?: ContentElementMediaDTO[];
  inDrawer?: boolean;
  viewOnly?: boolean;
}

const PostMediaList = observer(({ files = [], inDrawer = false, viewOnly = false }: Props) => {
  const mediaFiles = files.filter(f => f.contentType === ContentType.Picture || f.contentType === ContentType.Video);
  const documents = files.filter(f => f.contentType !== ContentType.Picture && f.contentType !== ContentType.Video);
  return (
    <>
      {files.length > 0 && (
        <div className="post_media">
          {mediaFiles.length > 0 && (
            <div className={`media_images_wrapper ${inDrawer ? 'in-op-pilot-preview' : ''}`}>
              <div className={`${inDrawer ? 'line_post-op-pilot-preview' : 'line_post margin_right'}`} />
              {mediaFiles.map(file =>
                file.contentType === ContentType.Video ? (
                  <VideoBox key={file.fileId} file={file} inDrawer={inDrawer} viewOnly={viewOnly} />
                ) : (
                  <ImageBox key={file.fileId} file={file} inDrawer={inDrawer} />
                )
              )}
            </div>
          )}
        </div>
      )}
      {documents.length > 0 && (
        <div className={`media_files_wrapper ${inDrawer ? 'in-op-pilot-preview' : ''}`}>
          <div className="line_post offset" />
          {documents.map(document => (
            <FileBox file={document} key={document.fileId} inDrawer={inDrawer} />
          ))}
        </div>
      )}
    </>
  );
});

export default PostMediaList;
