import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ItemMenu from 'components/ItemMenu';
import ItemMenuButton from 'components/ItemMenuButton';

import { Can, AbilityContext, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import { useStores } from 'util/mobx/stores';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleOpenDetailsGuideMaterial: () => void;
  handleDeleteGuideMaterialClick: () => void;
}

const GuideMaterialListItemMenu = ({ isOpen, handleClose, handleOpenDetailsGuideMaterial, handleDeleteGuideMaterialClick }: Props) => {
  const ability = useContext(AbilityContext);
  const { domainStore, guideStore } = useStores();
  const { t } = useTranslation('guideMaterials');
  return (
    <>
      <ItemMenu isOpen={!!isOpen} handleClose={handleClose}>
        {ability.can('see', subject(subjectArea.materialSettingsButton, { departmentId: domainStore.currentDepartment.id })) ? (
          <ItemMenuButton
            label={t('detailMenu.knowledgeAndAttitudes')}
            icon="icon_search-content_16.svg"
            isInPopover
            handleClick={handleOpenDetailsGuideMaterial}
          />
        ) : (
          <ItemMenuButton
            label={t('detailMenu.knowledge')}
            icon="icon_search-content_16.svg"
            isInPopover
            handleClick={handleOpenDetailsGuideMaterial}
          />
        )}

        <Can I={actions.delete} this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}>
          <ItemMenuButton
            label={t('detailMenu.deleteFromGuide')}
            icon="icon_bin_16.svg"
            warning
            isInPopover
            handleClick={handleDeleteGuideMaterialClick}
          />
        </Can>
      </ItemMenu>
    </>
  );
};

export default GuideMaterialListItemMenu;
