import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import './LocationAdministrationContent.css';
import StorageLocationsList from '../StorageLocationsList';
import StorageLocationDetails from '../StorageLocationDetails';

const LocationAdministrationContent = observer(() => {
  const { storageLocationStore, domainStore } = useStores();
  useEffect(() => {
    storageLocationStore.loadLocations();
    return () => {
      storageLocationStore.refreshStore();
    };
  }, [storageLocationStore, domainStore.currentFunctionalArea.id]);

  return (
    <div className="w-layout-grid grid_colum_content_lagerorte">
      <StorageLocationsList />
      {storageLocationStore.selectedStorageLocation && (
        <StorageLocationDetails selectedStorageLocation={storageLocationStore.selectedStorageLocation} />
      )}
    </div>
  );
});

export default LocationAdministrationContent;
