import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { MaterialStorageLocationItem } from 'components/ListItems';
import { SimpleStorageLocationDTO, MaterialStorageLocationDTO } from 'dto/storageLocation';
import { useParams } from 'react-router-dom';

interface Props {
  items?: MaterialStorageLocationDTO[];
  inFlyout?: boolean;
}

interface Params {
  groupType: string;
}

const EndStorageLocationsList = observer(({ items = [], inFlyout = false }: Props) => {
  const { groupType } = useParams<Params>();
  const { storageLocationStore } = useStores();
  const { t } = useTranslation('storageLocations');

  const handleCreateLocation = async () => {
    await storageLocationStore.loadLocations();
    storageLocationStore.setIsSelectLocationFlyoutOpen(true);
  };
  const checkActive = (location: SimpleStorageLocationDTO) => {
    if (location.storageLocationId === storageLocationStore.selectedMaterialStorageLocation?.storageLocationId) {
      return true;
    }
    return false;
  };

  const selectStorageLocation = (storageLocation: SimpleStorageLocationDTO) => {
    storageLocationStore.setSelectedMaterialStorageLocation(storageLocation, false);
    storageLocationStore.setIsDetailDrawerOpen(true);
  };

  if (!storageLocationStore.selectedMaterialStorageLocation) {
    return null;
  }

  return (
    <div className="div-block-140">
      {inFlyout ? (
        <div className="h3">{t('startingPointLocation')}</div>
      ) : (
        <div className="h3">{groupType ? t('button.createLocationForSets') : t('button.createLocation')}</div>
      )}

      <ListItemAdd className="post_item_add in_list lageort" onClick={handleCreateLocation}>
        <InfoBox label={t('button.addAnother')} />
      </ListItemAdd>
      <div className={`storage-location-items-list ${storageLocationStore.selectedMaterialStorageLocation ? 'with-back-button' : ''}`}>
        {items.map(storageLocation => (
          <MaterialStorageLocationItem
            materialStorageLocation={storageLocation}
            key={storageLocation.storageLocationId}
            isSelected={checkActive(storageLocation)}
            onClick={() => selectStorageLocation(storageLocation)}
            noArrow={inFlyout}
          />
        ))}
      </div>
    </div>
  );
});

export default EndStorageLocationsList;
