import { ConfigType } from './configType';

const config: ConfigType = {
  api: {
    url: 'https://demo.klinik-puls.de',
    port: 443,
  }
};


export default config;
