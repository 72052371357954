import React, { useEffect, useState } from 'react';
import Select, { StylesConfig, OptionTypeBase, OptionsType } from 'react-select';

interface Props {
  defaultValue?: OptionTypeBase;
  onChange: (value: string) => void;
  mappedOptions: OptionsType<OptionTypeBase>;
}

const customStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    background: state.isFocused ? '#4d7cfe' : 'white',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 'lighter',
    color: state.isFocused ? 'white' : '#1b2733',

    padding: '12px 20px '
  }),
  control: provided => ({
    ...provided,
    cursor: 'pointer',
    height: '51px',
    marginTop: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1',
    border: '1px solid #e6e8eb',
    borderRadius: '5px',
    backgroundColor: '#fff',
    color: '#c1c7cd',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'lighter'
  }),
  singleValue: provided => ({
    ...provided
  }),
  container: provided => ({
    ...provided
  }),
  menu: provided => ({
    ...provided,
    zIndex: 999
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: provided => ({
    ...provided,
    paddingRight: '12px' // 8px are given
  })
};

function buildDisplayName(value: OptionTypeBase) {
  return { ...value, label: value.group ? `${value.group} |  ${value.label}` : value.label };
}

const CustomDropdown = (props: Props) => {
  const [selectValue, setSelectValue] = useState<OptionTypeBase>();

  useEffect(() => {
    setSelectValue(props.defaultValue && buildDisplayName(props.defaultValue));
  }, [props.defaultValue]);

  const onChange = (value: OptionTypeBase) => {
    setSelectValue(buildDisplayName(value));
    props.onChange(value.value);
  };
  return (
    <Select
      value={selectValue}
      options={props.mappedOptions}
      defaultValue={props.defaultValue && buildDisplayName(props.defaultValue)}
      styles={customStyles}
      onChange={onChange}
    />
  );
};

export default CustomDropdown;
