import {
  MaterialSetDTO,
  UpdateMaterialSetDTO,
  DepartmentMaterialSetsDTO,
  FullSetCheckDTO,
  CreateCheckMaterialSetDTO,
  GetMaterialSetsDTO,
  CreateMaterialSetDTO,
  SetCheckDTO,
  SimpleMaterialSetCheckItemDTO
} from 'dto/materialSet';
import { GroupedMaterialGuidesDTO } from 'dto/material';
import { ClassTransformer } from 'class-transformer';
import { get, patch, del, post } from './common';

import { validate, validateBool, validateArray } from './validation';

export const getMaterialSets = async (params: GetMaterialSetsDTO): Promise<DepartmentMaterialSetsDTO[]> => {
  const sets = await get('/material-sets', params).then(response => response.data);
  return validateArray(DepartmentMaterialSetsDTO, sets);
};

export const getMaterialSet = async (materialSetId: string): Promise<MaterialSetDTO> => {
  const set = await get('/material-set', { materialSetId }).then(response => response.data);
  return validate(MaterialSetDTO, set);
};

export const getMaterialSetGuides = async (materialSetId: string): Promise<GroupedMaterialGuidesDTO[]> => {
  const guides = await get('/material-set/guides', { materialSetId }).then(response => response.data);
  return validateArray(GroupedMaterialGuidesDTO, guides);
};

export const createMaterialSet = async (materialSetToCreate: CreateMaterialSetDTO) => {
  const materialSet = await post('/material-set', materialSetToCreate).then(response => response.data);
  return validate(MaterialSetDTO, materialSet);
};

export const updateMaterialSet = async (materialSetUpdateFields: UpdateMaterialSetDTO): Promise<MaterialSetDTO> => {
  const updatedMaterialSet = await patch(`/material-set`, materialSetUpdateFields).then(response => response.data);
  return validate(MaterialSetDTO, updatedMaterialSet);
};

export const deleteMaterialSet = async (materialSetId: string) => {
  return del('/material-set', { materialSetId }).then(response => validateBool(response.data));
};

export const getMaterialSetChecks = async (materialSetId: string): Promise<SetCheckDTO[]> => {
  const materialSetChecks = await get('/material-set/checks', { materialSetId }).then(response => response.data);
  return validateArray(SetCheckDTO, materialSetChecks);
};

/**
 * custom transformer which handles the Map
 * @param res
 */
const setCheckTransformer = (res: string) => {
  const transformer = new ClassTransformer();
  const transformed = transformer.deserialize(FullSetCheckDTO, res) as any;

  transformed.setGroupChecks = Object.keys(transformed.setGroupChecks).reduce((prev, current) => {
    if (transformed.setGroupChecks[current]) {
      prev.set(current, transformed.setGroupChecks[current]);
    }
    return prev;
  }, new Map<string, SimpleMaterialSetCheckItemDTO>());

  transformed.groupMaterialChecks = Object.keys(transformed.groupMaterialChecks).reduce((prev, current) => {
    if (transformed.groupMaterialChecks[current]) {
      prev.set(current, transformed.groupMaterialChecks[current]);
    }
    return prev;
  }, new Map<string, SimpleMaterialSetCheckItemDTO>());

  return transformed;
};

export const getMaterialSetCheckItems = async (setCheckId: string): Promise<FullSetCheckDTO> => {
  const fullMaterialSetCheck = await get(
    '/material-set/check-items',
    { setCheckId },
    {
      transformResponse: setCheckTransformer
    }
  ).then(response => response.data);
  return validate(FullSetCheckDTO, fullMaterialSetCheck);
};

export const postMaterialSetCheckItems = async (materialsToCheck: CreateCheckMaterialSetDTO): Promise<FullSetCheckDTO> => {
  const checkedMaterials = await post('/material-set/checks', materialsToCheck, {
    transformResponse: setCheckTransformer
  }).then(response => response.data);
  return validate(FullSetCheckDTO, checkedMaterials);
};
