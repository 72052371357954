import React from 'react';
import { ExtendedUserDTO, UserRole } from 'dto/user';
import ValueTag from 'components/ValueTag';
import { useTranslation } from 'react-i18next';

interface Props {
  user: ExtendedUserDTO;
}

const UserRoleTag = ({ user }: Props) => {
  const { t } = useTranslation('roles');

  return (
    <div className="div-block-182">
      {user.departmentMaintainerOf.length > 0 && <ValueTag title={t('departmentMaintainer')} value=" " />}
      {user.userRole === UserRole.Admin && <ValueTag title={t('admin')} value=" " />}
      {user.userRole === UserRole.Importer && <ValueTag title={t('import')} value=" " />}
      {user.userRole === UserRole.User && <ValueTag title={t('user')} value=" " />}
    </div>
  );
};

export default UserRoleTag;
