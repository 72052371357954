import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { reorderMaterialChapters } from 'api/materialChapter';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import SettingsItem from 'components/SettingsItem';
import { SettingsItemTypes } from 'stores/settingsStore';
import { MaterialChapterDTO } from 'dto/materialChapter';

const SettingsKnowledgeTopics = observer(() => {
  const { settingsStore, appNavigationStore } = useStores();
  const [selectedMaterialChapters, setSelectedMaterialChapters] = useState<MaterialChapterDTO[]>([]);
  const { t } = useTranslation('settings');
  useEffect(() => {
    settingsStore.setItemFormFlyoutTitles(t('formFlyout.materialChapterUpdateTitle'), t('formFlyout.materialChapterCreateTitle'));
    const loadData = async () => {
      await settingsStore.loadMaterialChapters();
    };
    loadData();
  }, [settingsStore, t]);
  useEffect(() => {
    setSelectedMaterialChapters(settingsStore.materialChapters);
  }, [settingsStore.materialChapters]);

  appNavigationStore.useSubPageIdSetter('knowledge');

  const onCreateFlyoutOpen = () => {
    settingsStore.setItemFormFlyoutType(SettingsItemTypes.materialChapter);
    settingsStore.setItemFormFlyoutOpen(true);
  };

  const onActivate = (itemId: string) => {
    settingsStore.updateMaterialChapter({
      materialChapterId: itemId,
      disabled: false
    });
  };

  const onDeactivate = (itemId: string) => {
    settingsStore.updateMaterialChapter({
      materialChapterId: itemId,
      disabled: true
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const fileToBeMoved = selectedMaterialChapters.find(mc => mc.materialChapterId === result.draggableId);
    const oldMaterialChapters = selectedMaterialChapters.filter(mc => mc.materialChapterId !== result.draggableId);

    if (fileToBeMoved && oldMaterialChapters) {
      oldMaterialChapters.splice(result.destination.index, 0, fileToBeMoved);
      setSelectedMaterialChapters([...oldMaterialChapters]);
      reorderMaterialChapters({ materialChapterIds: oldMaterialChapters.map(mc => mc.materialChapterId) });
    }
  };
  return (
    <div className="single_colum_content-copy">
      <div className="div-block-79-copy">
        <div className="h1">{t('knowledgeTopics.title')}</div>
        <ListItemAdd className="post_item_add post-item-add" onClick={onCreateFlyoutOpen}>
          <InfoBox label={t('knowledgeTopics.addNew')} />
        </ListItemAdd>
        <div className="div-block-80-copy">
          <div className="div-block-82">
            <DragDropContext onDragEnd={onDragEnd}>
              {selectedMaterialChapters.map((materialChapter, index) => (
                <Droppable droppableId={materialChapter.materialChapterId} key={materialChapter.materialChapterId}>
                  {droppableProvided => (
                    <div ref={droppableProvided.innerRef}>
                      <Draggable draggableId={materialChapter.materialChapterId} index={index}>
                        {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <SettingsItem
                              itemId={materialChapter.materialChapterId}
                              onActivate={onActivate}
                              onDeActivate={onDeactivate}
                              key={materialChapter.materialChapterId}
                              name={materialChapter.name}
                              disabled={materialChapter.disabled}
                              type={SettingsItemTypes.materialChapter}
                              isFixed={materialChapter.isGenerated}
                            />
                          </div>
                        )}
                      </Draggable>
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SettingsKnowledgeTopics;
