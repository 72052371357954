import React from 'react';
import { useTranslation } from 'react-i18next';
import { UsedMaterialDTO, UsedMaterialStatus } from 'dto/usedMaterial';
import { useStores } from 'util/mobx/stores';
import Popover from 'components/Popover';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import SurgeryDropdownButton from '../SurgeryDropdownButton';
import SurgeryMaterialCheckStatus from './SurgeryMaterialCheckStatus';
import './SurgeryMaterialCheckDropdown.css';

interface Props {
  inSublist?: boolean;
  status?: UsedMaterialStatus;
  onStatusChanged: (status: UsedMaterialStatus) => void;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
  usedMaterial?: UsedMaterialDTO;
}

const SurgeryMaterialCheckDropdown = ({ inSublist = false, onStatusChanged, status, surgeryGuide, usedMaterial }: Props) => {
  const { surgeryGuideStore } = useStores();
  const { t } = useTranslation('briefing');

  const onAddLotNumber = (handleClose: () => void) => {
    surgeryGuideStore.setIsEditLotNumberFlyoutOpen(true, surgeryGuide, usedMaterial);
    handleClose();
  };

  const trigger =
    status && status !== UsedMaterialStatus.Prepared ? (
      <SurgeryMaterialCheckStatus status={status} />
    ) : (
      <div className={`btn_checkboxmenu ${inSublist ? 'in_sublist_2 in-sub-list-check' : ''}`}>
        <img src="images/icon_right-arrow_12.svg" alt="" className="image_arrow_checkbox" />
      </div>
    );

  const onStatusSelect = (handleClose: () => void, selectedStatus: UsedMaterialStatus) => {
    handleClose();
    onStatusChanged(selectedStatus);
  };

  return (
    <Popover trigger={trigger}>
      {({ handleClose, isOpen }) => (
        <div className="menu_edit_checkbox menu-edit-checkbox" style={{ display: isOpen ? 'block' : 'none' }}>
          {usedMaterial?.groupMaterialId && status !== UsedMaterialStatus.None && (
            <SurgeryDropdownButton
              className="btn_list_edit border_bottom"
              icon="icon_back-arrow_16_blue.svg"
              onClick={() => onStatusSelect(handleClose, UsedMaterialStatus.None)}
              mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.reset')}
            />
          )}
          {!usedMaterial?.groupMaterialId && (
            <>
              {status !== UsedMaterialStatus.None && (
                <SurgeryDropdownButton
                  className="btn_list_edit"
                  icon="icon_check-e_16_grey.svg"
                  onClick={() => onStatusSelect(handleClose, UsedMaterialStatus.None)}
                  mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.notPrepared.main')}
                  secondaryText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.notPrepared.secondary')}
                />
              )}
              {status !== UsedMaterialStatus.Prepared && (
                <SurgeryDropdownButton
                  className="btn_list_edit green border_bottom"
                  icon="icon_check-e_16_green.svg"
                  onClick={() => onStatusSelect(handleClose, UsedMaterialStatus.Prepared)}
                  mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.prepared.main')}
                  secondaryText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.prepared.secondary')}
                />
              )}
            </>
          )}
          <div className="txt_info_text top_margin">{t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.infoText')}</div>
          <SurgeryDropdownButton
            className="btn_list_edit green"
            icon="icon_single-01_16_green.svg"
            onClick={() => onStatusSelect(handleClose, UsedMaterialStatus.Used)}
            mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.used')}
          />
          <SurgeryDropdownButton
            className="btn_list_edit green"
            icon="icon_sticker_16_green.svg"
            onClick={() => onStatusSelect(handleClose, UsedMaterialStatus.Implanted)}
            mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.implanted')}
          />
          {/* <SurgeryDropdownButton
            className="btn_list_edit yellow"
            icon="icon_calendar-event_16_yellow.svg"
            onClick={() => onStatusSelect(handleClose, UsedMaterialStatus.Planned)}
            mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.planned')}
          /> */}
          <SurgeryDropdownButton
            className="btn_list_edit"
            icon="icon_e-remove_16.svg"
            onClick={() => onStatusSelect(handleClose, UsedMaterialStatus.UsedNotImplanted)}
            mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.usedNotImplanted')}
          />
          <SurgeryDropdownButton
            className="btn_list_edit yellow"
            icon="icon_sticker_16_yellow.svg"
            onClick={() => onStatusSelect(handleClose, UsedMaterialStatus.NotNeeded)}
            mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.notRequired')}
          />

          <SurgeryDropdownButton
            className="btn_list_edit"
            icon="icon_i-edit_16.svg"
            onClick={() => onAddLotNumber(handleClose)}
            mainText={t('briefingMaterials.surgeryMaterialItem.checkBoxMenu.lotNumber')}
          />
        </div>
      )}
    </Popover>
  );
};

export default SurgeryMaterialCheckDropdown;
