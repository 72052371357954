import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import AppBarBranding from './AppBarBranding';
import AppBarMenuButton from './AppBarMenuButton';

import './AppBar.css';

interface Props {
  rightChildren?: JSX.Element[] | JSX.Element;
  children: JSX.Element[] | JSX.Element;
  icon: JSX.Element[] | JSX.Element;
  buttonItemRef: React.RefObject<HTMLDivElement> | null;
}
const AppBar = observer((props: Props) => {
  const { domainStore, loadingStore } = useStores();
  const { shortName: functionAreaShortName } = domainStore.currentFunctionalArea;

  return (
    <div className="title-wrapper mobile title-wrapper-style">
      {loadingStore.isLoading && <div className="loading-bar" />}
      {!domainStore.isMobile && <AppBarBranding label={functionAreaShortName} className="logo_desktop" />}
      <div className="app_bar_menu_btn" ref={props.buttonItemRef}>
        <AppBarMenuButton className="btn_menu_desktop" />
      </div>
      {props.icon}
      {props.children}
      <div className="div-block-43">{props.rightChildren}</div>

      {domainStore.isMobile && <AppBarBranding label={functionAreaShortName} className="logo_mobile" />}
    </div>
  );
});

export default AppBar;
