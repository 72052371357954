import { setErrorStore } from 'api/common';
import { observable, action, computed } from 'mobx';
import { ApiError } from 'util/error';

export default class ErrorStore {
  private MAX_ERRORS = 5;

  @observable
  private errors?: ApiError[] = undefined;

  @computed
  get currentError(): ApiError | undefined | null {
    if (this.errors === undefined) {
      return undefined;
    }

    if (this.errors.length <= 0) {
      return null;
    }

    return this.errors[0];
  }

  constructor() {
    setErrorStore(this);
  }

  @action
  onError(error: ApiError) {
    // explicitly ignore unauthorized error as it should just log out
    if (error.reason?.response?.status === 401) {
      return;
    }

    if (this.errors === undefined) {
      this.errors = [];
    }

    if (this.errors.length < this.MAX_ERRORS) {
      // ignore new errors if we already have too much
      this.errors.push(error);
    }
  }

  @action
  removeCurrentError() {
    if (this.errors === undefined) {
      this.errors = [];
    }

    if (this.errors.length > 0) {
      this.errors.splice(0, 1);
    }
  }
}
