import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialLikeStorageLocations from 'components/MaterialLikeStorageLocations';
import PackageStorageLocationsRightMenu from './PackageStorageLocationsRightMenu';

interface Props {
  packageId: string;
}

const PackageStorageLocations = observer(({ packageId }: Props) => {
  const { appNavigationStore, storageLocationStore } = useStores();

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <PackageStorageLocationsRightMenu />;
    });
  }, [appNavigationStore]);

  useEffect(() => {
    return () => {
      storageLocationStore.refreshStore();
    };
  });

  appNavigationStore.useSubPageIdSetter('locations');

  return <MaterialLikeStorageLocations materialLikeOrPackageId={{ packageId }} />;
});

export default PackageStorageLocations;
