import React from 'react';
import Picture from 'components/Picture';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

interface Props {
  file: ContentElementMediaDTO;
  inDrawer?: boolean;
}

export default ({ file, inDrawer }: Props) => {
  const { flyoutStore } = useStores();
  const { t } = useTranslation('itemFormFlyOut');

  if (inDrawer) {
    return (
      <div className="image_post_wide in_board" onClick={() => flyoutStore.setImagesToView(file)}>
        <Picture src={file.fullUrl} width="344" alt="" className="chat_image" />
      </div>
    );
  }

  return (
    <div className="post_image">
      <div className="image_post_wide" onClick={() => flyoutStore.setImagesToView(file)}>
        {file.pictureLabelling && (
          <div className="info_box_image">
            <div>{t('uploadFiles.imageMarking')}</div>
          </div>
        )}
        <Picture src={file.fullUrl} width="344" alt="" />
      </div>
      <div className="post_image_info">
        <div className="txt_post_image">{file.title}</div>
      </div>
    </div>
  );
};
