import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import SideMenuIconButton from 'components/SideMenuIconButton';

const PackageStorageRightMenu = observer(() => {
  const { t } = useTranslation('packages');
  const { storageLocationStore, domainStore } = useStores();

  const handleCreate = async () => {
    await storageLocationStore.loadLocations();
    storageLocationStore.setIsSelectLocationFlyoutOpen(true);
  };

  const handleSwitch = async () => domainStore.setShowSwitchDomainFlyout(true);

  const handleDelete = async () => {
    storageLocationStore.deleteStorageItemByMaterialLikeId();
  };

  return (
    <>
      <SideMenuIconButton
        handleClick={() => handleCreate()}
        label={t('packageStorageLocations.rightMenu.addLocation')}
        iconPath="icon_f-add_16.svg"
      />
      <SideMenuIconButton
        handleClick={() => handleSwitch()}
        label={t('packageStorageLocations.rightMenu.switchArea')}
        iconPath="icon_pin-edit_16.svg"
      />
      {storageLocationStore.selectedMaterialStorageLocation && (
        <SideMenuIconButton
          handleClick={() => handleDelete()}
          label={t('packageStorageLocations.rightMenu.removeMaterial')}
          iconPath="icon_bin_16.svg"
          red
        />
      )}
    </>
  );
});

export default PackageStorageRightMenu;
