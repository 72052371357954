import { useEffect, useState } from 'react';
import { fetchSecure } from './download';

/**
 * useSecureURL uses fetchSecure to download the given url using the authentication.
 * It then just returns the local object url (or undefined, if it the download is not finished yet).
 *
 * This can be used like every other hook in React components. It uses a state internally.
 * @example <img src={useSecure("mysecureurl.de/file/xyz.png")} />
 */
export function useSecureURL(url?: string): string | undefined {
  const [secureURL, setSecureURL] = useState<string>();

  useEffect(() => {
    if (url) {
      fetchSecure(url, true).then(setSecureURL);
    }
  }, [url]);

  return secureURL;
}
