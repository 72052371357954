import React from 'react';
import { observer } from 'mobx-react';
import Form, { InputField } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { FormikValues } from 'formik';
import { MergedGuideMaterialDTO } from 'stores/guideDetailDrawerStore';
import CustomSwitch from 'components/Form/Switch';
import SettingsStorageLocationBox from '../SettingsStorageLocationBox';

import './GuideSettingsForm.css';

interface Props {
  guideItem: MergedGuideMaterialDTO;
  hideStorageLocation: boolean;
}

const GuideSettingsForm = observer(({ guideItem, hideStorageLocation }: Props) => {
  const { t } = useTranslation('guideSettings');
  const { guideDetailDrawerStore } = useStores();
  const onValuesChange = (values: FormikValues) => {
    guideDetailDrawerStore.setGuideSettingsValues({
      amount: +values.amount,
      notes: {
        preparer: values.preparer,
        circular: values.circular,
        sterile: values.sterile,
        unpackOnInstruction: values.unpackOnInstruction
      }
    });
  };

  return (
    <Form
      initialValues={{
        amount: guideItem.amount,
        preparer: guideItem.notes.preparer,
        circular: guideItem.notes.circular,
        sterile: guideItem.notes.sterile,
        unpackOnInstruction: guideItem.notes.unpackOnInstruction
      }}
      onValuesChange={onValuesChange}
    >
      {form => (
        <>
          <div className="mat_settings_header">
            <img src="images/icon_airport-trolley_16.svg" alt="" className="image_function" />
            <div>{t('preparer')}</div>
          </div>
          <div className="setting_wrapper">
            <div className="txt_setting">{t('amount')}</div>
            <InputField type="number" name="amount" className="input_field_settings input-field-settings" />
          </div>
          {!hideStorageLocation && <SettingsStorageLocationBox guideItem={guideItem} />}
          <div className="setting_wrapper">
            <div className="txt_setting">{t('notes')}</div>
            <InputField name="preparer" className="input_field_settings input-field-settings" />
          </div>
          <div className="mat_settings_header">
            <img src="images/icon_multiple-19_16.svg" alt="" className="image_function" />
            <div>{t('springer')}</div>
          </div>
          <div className="setting_wrapper">
            <div className="txt_setting">{t('notes')}</div>
            <InputField name="circular" className="input_field_settings input-field-settings" />
          </div>
          <div className="div-block-6-copy">
            <div>{t('noteUnpackOnInstruction')}</div>
            <div className="div-block-11">
              <div className="txt_toggle_inactive" style={{ color: form.values.unpackOnInstruction ? '#40e23b' : undefined }}>
                {t(form.values.unpackOnInstruction ? 'unpackOnInstructionTrue' : 'unpackOnInstructionFalse')}
              </div>
              <div className="div-block-9">
                <InputField name="unpackOnInstruction" component={CustomSwitch} className="input_field_settings input-field-settings" />
              </div>
            </div>
          </div>
          <div className="mat_settings_header">
            <img src="images/icon_single-body_16.svg" alt="" className="image_function" />
            <div>{t('sterile')}</div>
          </div>
          <div className="setting_wrapper">
            <div className="txt_setting">{t('notes')}</div>
            <InputField name="sterile" className="input_field_settings input-field-settings" />
          </div>
        </>
      )}
    </Form>
  );
});

export default GuideSettingsForm;
