import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import './FullScreenImageFlyout.css';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import FlyoutContainer from 'components/FlyoutContainer';
import Button from 'components/Form/Button';
import { useSecureURL } from 'util/hooks';

interface ImageProps {
  width: number;
  height: number;
}

interface ZoomProps {
  zoomIn: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  zoomOut: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  resetTransform: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FullScreenImageFlyout = observer(() => {
  const { flyoutStore } = useStores();
  const { t } = useTranslation('imageFlyout');
  const [isLabelsShown, setIsLabelsShown] = useState<boolean>(true);

  const getImageUrl = (file: ContentElementMediaDTO) => {
    if (!isLabelsShown) {
      return file.fullUrl;
    }
    return file.pictureLabellingUrl || file.fullUrl;
  };

  const imageURL = useSecureURL(flyoutStore.imageToView ? getImageUrl(flyoutStore.imageToView) : undefined);

  const closePopUp = () => flyoutStore.setImagesToView(null);

  return (
    <FlyoutContainer
      icon={<img src="images/icon_edit.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={!!flyoutStore.imageToView}
      className="fullscreen-flyout"
      closePopUp={closePopUp}
      cancelLabel={t('cancel')}
      closeAsSubmit
      title={t('imageZoomingTitle')}
      isAllowedToSubmit
    >
      <div className="fullscreen-content">
        <div className="image-flyout-content">
          <TransformWrapper options={{ limitToWrapper: true, minScale: 0 }} defaultPositionX={0} defaultPositionY={0}>
            {({ zoomIn, zoomOut, resetTransform }: ZoomProps) => {
              if (!flyoutStore.imageToView) {
                return null;
              }
              const onLoad = () => {
                resetTransform();
              };
              return (
                <>
                  <TransformComponent>
                    <img src={imageURL} onLoad={onLoad} alt={flyoutStore.imageToView.title || ''} className="image-to-zoom" />
                  </TransformComponent>
                  <div className="tools">
                    <Button icon="images/zoom-in.svg" type="button" className="btn-transparent-round" onClick={zoomIn} />
                    <Button icon="images/zoom-out.svg" type="button" className="btn-transparent-round" onClick={zoomOut} />
                    <Button icon="images/reset.svg" type="button" className="btn-transparent-round reset" onClick={resetTransform} />
                    {flyoutStore.imageToView?.pictureLabellingUrl && (
                      <Button
                        className="btn-transparent-round toggle-labels"
                        icon={isLabelsShown ? 'images/eye-hide.svg' : 'images/eye-show.svg'}
                        type="button"
                        onClick={() => setIsLabelsShown(!isLabelsShown)}
                      />
                    )}
                  </div>
                </>
              );
            }}
          </TransformWrapper>
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default FullScreenImageFlyout;
