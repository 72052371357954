/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, IsOptional } from 'class-validator';

export class TitleDTO {
  @IsUUID()
  titleId!: string;

  @IsString()
  name!: string;
}
export class UpdateTitleDTO {
  @IsUUID()
  titleId!: string;

  @IsString()
  @IsOptional()
  name?: string;
}
export class CreateTitleDTO {
  @IsString()
  name!: string;
}
