import React from 'react';
import { observer } from 'mobx-react';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import SurgeryMaterialItem from './SurgeryMaterialItem';
import SurgeryMaterialSetItem from './SurgeryMaterialSetItem';
import SurgeryTemplateListItem from './SurgeryTemplateListItem';

interface Props {
  materialLike: SurgeryGuideMaterialLikeDTO;
}

const SurgeryMaterialListItem = observer(({ materialLike }: Props) => {
  if (materialLike.material) {
    return <SurgeryMaterialItem material={materialLike.material} surgeryGuide={materialLike} />;
  }
  if (materialLike.materialSet) {
    return <SurgeryMaterialSetItem materialSet={materialLike.materialSet} surgeryGuide={materialLike} />;
  }
  if (materialLike.template) {
    return <SurgeryTemplateListItem template={materialLike.template} surgeryGuide={materialLike} />;
  }
  return null;
});

export default SurgeryMaterialListItem;
