import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostDTO } from 'dto/post';
import { useStores } from 'util/mobx/stores';
import { getDayTimeWording } from 'util/date';
import ImageCircle from 'components/ImageCircle';
import CommentFileBox from 'components/ThumbnailList/CommentFileBox';
import './DialogPost.css';

interface Props {
  post: PostDTO;
}

const DialogPost = ({ post }: Props) => {
  const { authStore } = useStores();
  const isUsersPost = post?.contentElement.user.userId === authStore.user.userId;
  const userClassName = isUsersPost ? 'user_comment' : '';
  return (
    <div className="board_chat_entry">
      {!isUsersPost && (
        <ImageCircle highlight={false} size="40" className="in_chat_bubble" user={post?.contentElement.user} withUserInfoBoxRight />
      )}
      <div className={`entry ${userClassName}`}>
        <div className={`t2 bottom-margin ${userClassName}`}>
          {post?.contentElement.user.firstName} {post?.contentElement.user.lastName}
        </div>
        <div className={`b_chat_content ${userClassName}`}>
          <div className={`txt_message show-newlines ${userClassName}`}>{post?.contentElement.description}</div>
          <div className="dialog-post-files">
            {post?.contentElement.contentElementMedias.map(contentElementMedia => (
              <CommentFileBox key={contentElementMedia.fileId} file={contentElementMedia} inBoard />
            ))}
          </div>
          <div className="t2 bottom-margin">{getDayTimeWording(post.createdAt, useTranslation('date'), true)}</div>
        </div>
      </div>
      {isUsersPost && <ImageCircle highlight={false} size="40" className="user_comment" user={post?.contentElement.user} />}
    </div>
  );
};

export default DialogPost;
