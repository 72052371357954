import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import BoardInput from 'components/Form/BoardInput';
import { CreatePostContentElementDTO } from 'dto/contentElement';
import { useStores } from 'util/mobx/stores';
import { getAnimationState } from 'util/animation';
import './DialogForm.css';
import { FormikValues, FormikProps } from 'formik';

const DialogForm = () => {
  const { t } = useTranslation('board');
  const [isExpanded, setIsExpanded] = useState<boolean | null>(null);
  const { postStore, domainStore } = useStores();

  const onSubmit = async ({ description, contentElementMedias }: CreatePostContentElementDTO) => {
    const post = {
      description,
      contentElementMedias: contentElementMedias ? contentElementMedias.map(e => ({ fileId: e.fileId, title: e.title })) : []
    };
    await postStore.createFunctionalAreaPost(domainStore.currentFunctionalArea.id, post);
    setIsExpanded(false);
  };

  const onClose = (form: FormikProps<FormikValues>) => {
    form.resetForm();
    setIsExpanded(false);
  };

  return (
    <div className={`div-block-88 dialog-form-container ${getAnimationState(isExpanded, 'expanded', 'collapsed')}`}>
      <div className="b_chat_line" />
      <Form
        initialValues={{
          description: '',
          contentElementMedias: []
        }}
        onSubmit={onSubmit}
      >
        {form => (
          <>
            <BoardInput
              isExpanded={isExpanded}
              onExpand={() => setIsExpanded(true)}
              meta={form}
              name="description"
              placeholder={t('dialog.inputPlaceholder')}
            />
            <div className="div-block-74 in_board">
              <Button className="btn_msg_cancel" type="button" onClick={() => onClose(form)}>
                {t('dialog.buttons.abort')}
              </Button>
              <Button disabled={!form.dirty || !form.values.description} className="btn_msg_send">
                {t('dialog.buttons.send')}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default DialogForm;
