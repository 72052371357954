import React, { useEffect } from 'react';
import { observable, action, computed, runInAction } from 'mobx';

export default class AppNavigationStore {
  // rightMenuBuilder handles can be set to a component which should be used as right menu
  // it should be set using setRightMenuBuilder inside of a useEffect in the sub-pages
  // rightMenuComponent can then be used to get a current instance of the menu
  @observable
  rightMenuBuilder?: () => JSX.Element;

  @observable
  subPageId = '';

  @observable
  isMenuOpen = false;

  @observable
  isKeyboardOpen = false;

  @computed
  get rightMenuComponent(): JSX.Element {
    if (this.rightMenuBuilder) {
      return this.rightMenuBuilder();
    }
    return React.createElement(React.Fragment);
  }

  /**
   * useSubPageIdSetter sets the name of the subpage currently shown to set the
   * grid-id in the Page component correctly
   * Just call in the subpage component. It will set / unset the id automatically.
   * @param subPageId
   */
  @action
  useSubPageIdSetter(subPageId: string) {
    return useEffect(() => {
      runInAction(() => {
        this.subPageId = subPageId;
      });

      return () => {
        runInAction(() => {
          this.subPageId = '';
        });
      };
    });
  }

  @action
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @action
  closeMenu() {
    this.isMenuOpen = false;
  }

  @action
  setIsKeyboardOpen(status: boolean) {
    this.isKeyboardOpen = status;
  }

  @action
  setRightMenuBuilder(rightMenuBuilder: () => JSX.Element) {
    this.rightMenuBuilder = rightMenuBuilder;
  }
}
