import { runInAction, extendObservable } from 'mobx';

/**
 * fromPromise either returns the defaultValue or if the promise is resolved the result from it.
 * The returned callback returns a value which can be observed using mobX observe()
 *  -> it can trigger a refresh on promise resolve, just like any other mobx value.
 *
 * It is meant for simple usage of promises in computed mobx values without the need for await / then.
 *
 * @param defaultValue Any value, used as default until the promise is resolved.
 * @param promise Any promise returning the same type as the defaultValue has.
 */
export function fromPromise<T>(defaultValue: T, origPromise: Promise<T>): () => T {
  const promise = origPromise as Promise<T> & {
    value: T;
  };

  extendObservable(promise, {
    value: defaultValue
  });

  promise.then(value => {
    runInAction(() => {
      promise.value = value;
    });
  });

  return () => promise.value;
}
