import React from 'react';
import CollapsibleBox from 'components/CollapsibleBox';
import PostItem from 'components/PostItem';
import { MaterialDTO } from 'dto/material';
import { useTranslation } from 'react-i18next';
import MaterialKnowledge from 'components/MaterialKnowledge';
import { IconType } from 'dto/procedure';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import DrawerStorageLocationContent from '../../DrawerStorageLocationContent';

interface Props {
  material: MaterialDTO;
}

const Content = observer(({ material }: Props) => {
  const { t } = useTranslation('material');

  const { materialStore, guideDetailDrawerStore } = useStores();
  return materialStore.materialDetail ? (
    <div className="material_content">
      <div className="material-name">{material.name}</div>
      <DrawerStorageLocationContent />
      <MaterialKnowledge
        isCollapsible
        materialLikeId={{ materialId: material.materialId }}
        onCreateSynonym={() => guideDetailDrawerStore.openCreateMaterialSynonymFlyout()}
      />
      <div className="post_item_head_topic">
        <CollapsibleBox
          head={
            <>
              <div>{t('drawer.productInformation')}</div>
            </>
          }
        >
          <PostItem title={t('materialDetail.label.productDescription')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{materialStore.materialDetail.description}</div>
          </PostItem>
          <PostItem title={t('materialDetail.label.price')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{materialStore.materialDetail.price}</div>
          </PostItem>
          <PostItem title={t('materialDetail.label.packingUnit')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{materialStore.materialDetail.packageSize}</div>
          </PostItem>
          <PostItem title={t('materialDetail.label.manufacturer')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{materialStore.materialDetail.manufacturerName}</div>
          </PostItem>
          <PostItem title={t('materialDetail.label.supplier')} menuClassName="material_detail" iconType={IconType.None}>
            <div>{materialStore.materialDetail.supplier}</div>
          </PostItem>
        </CollapsibleBox>
      </div>
    </div>
  ) : (
    <></>
  );
});

export default Content;
