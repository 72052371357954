import React, { useEffect, useState } from 'react';
import CollapsibleBox from 'components/CollapsibleBox';
import { useStores } from 'util/mobx/stores';
import Form, { InputField, Select } from 'components/Form';
import { AdminOptionalAccountDTO } from 'dto/user';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Form/Button';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';
import UploadFiles from 'components/UploadFiles';
import { saveFileUser } from 'api/file';
import { ContentType } from 'dto/file';
import { getGenderDropdownOptions } from 'util/userUtils';
import { UserSettingsInitialValues } from 'pages/userSettingsData/UserSettingsData';
import './UserDetailsForm.css';

interface Props {
  initialValues?: AdminOptionalAccountDTO | UserSettingsInitialValues;
  viewOnly: boolean;
  inUserSettings?: boolean;
  className?: string;
  validationSchema?: Yup.ObjectSchema;
}

const UserDetailsForm = observer(({ initialValues, viewOnly, inUserSettings = false, className, validationSchema }: Props) => {
  const { t: translationAccountManagement } = useTranslation('accountManagement');
  const { t: translationUserSalutation } = useTranslation('salutationSelection');
  const { settingsStore, userStore } = useStores();
  const [imageId, setImageId] = useState('');

  // avoid warning, re-render whole component is ok here
  const titles = settingsStore.titlesDropdownOptions;

  useEffect(() => {
    settingsStore.loadAccountManagementTitles();
  }, [settingsStore]);

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const salutationKeyOptions = getGenderDropdownOptions(translationUserSalutation);

  const onSubmit = async (data: UserSettingsInitialValues) => {
    if (userStore.selectedUser && Object.keys(data).length > 0) {
      const userdata = { ...data, userId: userStore.selectedUser.userId };

      if (imageId) {
        userdata.pictureFileId = imageId;
      }

      if (data.passwordConfirm) {
        delete userdata.passwordConfirm;
      }

      await userStore.updateUserSecure(userdata);
    }
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} bindSubmitForm={bindSubmitForm} validationSchema={validationSchema}>
      {props => {
        return (
          <div className="flyout_box no_padding_top_bottom">
            <div className="post_item_head_topic">
              <CollapsibleBox
                headClassName="dropdown_content_head in_op_planer"
                head={<div>{translationAccountManagement('userDetail.aboutThePerson')}</div>}
                contentClass={className}
                isExpandedByDefault={inUserSettings}
              >
                <>
                  {inUserSettings && (
                    <div className="flyout_box no_padding_left">
                      <UploadFiles
                        setImageId={setImageId}
                        cameraImageButtonDescription={translationAccountManagement('userDetail.userDetailsForm.imageButton')}
                        cameraImageButtonDescriptionAlready={translationAccountManagement(
                          'userDetail.userDetailsForm.imageButtonImageAlreadyThere'
                        )}
                        saveFile={saveFileUser}
                        picturesOnly
                        accept={[ContentType.Picture]}
                        userButtonStyle
                      />
                    </div>
                  )}
                  <div className="div-block-132">
                    {inUserSettings && (
                      <div className="flyout_box no_padding_left">
                        <div>{translationAccountManagement('userDetail.userDetailsForm.salutationKey')}</div>
                        <InputField name="salutationKey" mappedOptions={salutationKeyOptions} component={Select} viewOnly={viewOnly} />
                      </div>
                    )}
                    <div className="flyout_box no_padding_left">
                      <div>{translationAccountManagement('userDetail.userDetailsForm.title')}</div>
                      <InputField
                        name="titleId"
                        mappedOptions={titles}
                        component={Select}
                        placeholder={translationAccountManagement('userDetail.userDetailsForm.titlePlaceholder')}
                        viewOnly={viewOnly}
                      />
                      {inUserSettings && <ErrorMessage name="titleId" />}
                    </div>
                    <div className="flyout_box no_padding_left">
                      <div>{translationAccountManagement('userDetail.userDetailsForm.firstName')}</div>
                      <InputField name="firstName" viewOnly={viewOnly} />
                      {inUserSettings && <ErrorMessage name="firstName" />}
                    </div>
                    <div className="flyout_box no_padding_left">
                      <div>{translationAccountManagement('userDetail.userDetailsForm.lastName')}</div>
                      <InputField name="lastName" viewOnly={viewOnly} />
                      {inUserSettings && <ErrorMessage name="lastName" />}
                    </div>
                    <div className="flyout_box no_padding_left">
                      <div>{translationAccountManagement('userDetail.userDetailsForm.emailAddress')}</div>
                      <InputField name="emailAddress" viewOnly={viewOnly} />
                      {inUserSettings && <ErrorMessage name="emailAddress" />}
                    </div>
                    <div className="flyout_box no_padding_left">
                      <div>{translationAccountManagement('userDetail.userDetailsForm.phoneNumber')}</div>
                      <InputField name="phoneNumber" viewOnly={viewOnly} />
                      {inUserSettings && <ErrorMessage name="phoneNumber" />}
                    </div>
                    <div className="flyout_box no_padding_left">
                      <div>{translationAccountManagement('userDetail.userDetailsForm.username')}</div>
                      <InputField name="username" viewOnly={viewOnly} />
                      {inUserSettings && <ErrorMessage name="username" />}
                    </div>
                    {inUserSettings ? (
                      <>
                        <div className="flyout_box no_padding_left" />

                        <div className="flyout_box no_padding_left">
                          <div>{translationAccountManagement('userDetail.userDetailsForm.newPassword')}</div>
                          <InputField
                            name="newPassword"
                            viewOnly={viewOnly}
                            type="password"
                            placeholder="********"
                            autoComplete="new-password"
                          />
                          <ErrorMessage name="newPassword" />
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{translationAccountManagement('userDetail.userDetailsForm.newPasswordConfirm')}</div>
                          <InputField name="passwordConfirm" viewOnly={viewOnly} type="password" />
                          <ErrorMessage name="passwordConfirm" />
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{translationAccountManagement('userDetail.userDetailsForm.currentPassword')}</div>
                          <InputField name="currentPassword" viewOnly={viewOnly} type="password" autoComplete="current-password" />
                          <ErrorMessage name="currentPassword" />
                        </div>
                        <div className="flyout_box no_padding_left" />

                        <Button
                          style={{ display: 'block' }}
                          type="button"
                          className="btn_big_done-copy no-margin-left"
                          onClick={() => handleSubmitMyForm()}
                          disabled={!(props.isValid && props.dirty)}
                        >
                          {translationAccountManagement('userDetail.userDetailsForm.submit')}
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              </CollapsibleBox>
            </div>
          </div>
        );
      }}
    </Form>
  );
});

export default UserDetailsForm;
