import React from 'react';
import { SurgeryGuideDTO } from 'dto/surgeryGuide';
import { SurgeryGuideListItem } from 'components/ListItems';
import { useTranslation } from 'react-i18next';
import StatusBar from 'components/StatusBar';
import { SurgeryGuideStatisticDTO } from 'dto/statistic';

interface Props {
  surgeryGuide: SurgeryGuideDTO;
  stats?: SurgeryGuideStatisticDTO;
}

const SurgeryGuide = ({ surgeryGuide, stats }: Props) => {
  const { t } = useTranslation('surgery');
  return (
    <div className="op_book_item in_op-planer">
      <SurgeryGuideListItem surgeryGuide={surgeryGuide} user={surgeryGuide.guide.user} />

      <div className="line_post offset" />
      {stats && (
        <div className="op_status_box">
          <div className="h2">{t('progress.title')}</div>
          <StatusBar title={t('progress.inMaterial')} all={stats.material.all} checked={stats.material.checked} />
          <StatusBar title={t('progress.inProcedure')} all={stats.procedure.all} checked={stats.procedure.checked} />
          <div className="op_status_wrapper">
            {stats.procedures.length > 0 &&
              stats.procedures.map(procedureStat => (
                <div className="div-block-157">
                  <StatusBar
                    hideIfAllIs0
                    isSmall
                    title={procedureStat.chapterName}
                    all={procedureStat.all}
                    checked={procedureStat.checked}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SurgeryGuide;
