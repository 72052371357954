import React, { ChangeEvent, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { useTranslation } from 'react-i18next';
import ImageEditor from 'components/ImageEditor';
import '../PreviewMediaBox/PreviewMediaBox.css';
import { useStores } from 'util/mobx/stores';
import DownloadButton from 'components/DownloadButton';
import { ProcessingStatus } from 'dto/file';
import { useSecureURL } from 'util/hooks';
import Picture from 'components/Picture';
import ThumbnailMediaProgressTag from '../ThumbnailMediaProgressTag';

// TODO need pictureThumbnail
const videoThumbnail = 'images/video_thumbnail.jpg';

interface Props {
  file: ContentElementMediaDTO;
  onDeleteImage: (imageId: string) => void;
  updateImageLabeling?: (fileId: string, pictureLabelling: string, newFile: Blob) => void;
  index?: number;
  onMediaLabelChange?: (mediaId: string, text: string) => void;
  progress?: number;
}

export default ({ file, onDeleteImage, index = 1, updateImageLabeling, onMediaLabelChange, progress }: Props) => {
  const { domainStore, flyoutStore } = useStores();
  const { t } = useTranslation('itemFormFlyOut');
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onMediaLabelChange) {
      onMediaLabelChange(file.fileId, event.target.value);
    }
  };
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const pictureURL = useSecureURL(file.fullUrl);

  if (file.status !== ProcessingStatus.Finished) {
    return (
      <Draggable draggableId={file.fileId} index={index}>
        {provided => (
          <>
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <div className="post_image post-image">
                <div className="image_post_wide" onClick={() => flyoutStore.setImagesToView(file)}>
                  <ThumbnailMediaProgressTag status={file.status} uploadProgress={progress} contentType={file.contentType} />
                  <Picture src={videoThumbnail} width="344" alt="" />
                </div>
                {file.status !== ProcessingStatus.Uploading && (
                  <>
                    <input
                      type="text"
                      className="txt_post_image in_flyout post-image-input"
                      placeholder={t('uploadFiles.imageDescription')}
                      onChange={onChange}
                      value={file.title}
                    />
                    <div className="post_image_info">
                      <>
                        <div className="btn_remove_media" onClick={() => onDeleteImage(file.fileId)}>
                          <img src="images/icon_bin_16.svg" alt="" className="image_function" />
                          <div>{t('uploadFiles.deleteVideo')}</div>
                        </div>
                      </>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </Draggable>
    );
  }
  return (
    <Draggable draggableId={file.fileId} index={index}>
      {provided => (
        <>
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className="post_image post-image">
              <>
                <DownloadButton file={file} />
                <div className="image_post_wide" onClick={() => flyoutStore.setImagesToView(file)}>
                  {file.pictureLabelling && (
                    <div className="info_box_image">
                      <div>{t('uploadFiles.imageMarking')}</div>
                    </div>
                  )}
                  <Picture src={pictureURL} width="344" alt="" />
                </div>
                <input
                  type="text"
                  className="txt_post_image in_flyout post-image-input"
                  placeholder={t('uploadFiles.imageDescription')}
                  onChange={onChange}
                  value={file.title}
                />
              </>
              <div className="post_image_info">
                <>
                  {!domainStore.isMobile && (
                    <div className="btn_mark_picture" onClick={() => setIsImageEditorOpen(true)}>
                      <img src="images/icon_shopping-tag_16.svg" alt="" className="image_function" />
                      <div>{t('uploadFiles.changeImageMarker')}</div>
                    </div>
                  )}
                  <div className="btn_remove_media" onClick={() => onDeleteImage(file.fileId)}>
                    <img src="images/icon_bin_16.svg" alt="" className="image_function" />
                    <div>{t('uploadFiles.deleteImage')}</div>
                  </div>
                </>
              </div>
            </div>
          </div>
          {updateImageLabeling && (
            <ImageEditor
              image={file}
              isOpen={isImageEditorOpen}
              onClose={() => setIsImageEditorOpen(false)}
              updateImageLabeling={updateImageLabeling}
            />
          )}
        </>
      )}
    </Draggable>
  );
};
