import React from 'react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';

interface Props {
  groupType: string;
  checkListDisabled: boolean;
}

const MaterialSetGroupsRightMenu = observer(({ groupType, checkListDisabled }: Props) => {
  const { t } = useTranslation(groupType);

  const { materialSetsStore, domainStore } = useStores();

  return (
    <>
      {!materialSetsStore.isCheckListVisible && (
        <>
          <Can I={actions.add} a={subjectArea.groupMaterial}>
            {!domainStore.isMobile && (
              <SideMenuIconButton
                handleClick={() => materialSetsStore.setIsSetGroupFormFlyoutOpen(true)}
                label={t('groupsList.rightMenu.createGroups')}
                iconPath="icon_f-add_16.svg"
              />
            )}
          </Can>
          {!checkListDisabled && (
            <>
              {!domainStore.isMobile && (
                <SideMenuIconButton
                  disabled={materialSetsStore.isCheckListVisible}
                  handleClick={() => materialSetsStore.setIsCheckListVisible(true)}
                  label={t('groupsList.rightMenu.checkImplants')}
                  iconPath="icon_checkbox_check_16.svg"
                />
              )}
              <SideMenuIconButton
                disabled={materialSetsStore.isCheckListVisible}
                handleClick={() => materialSetsStore.setIsCheckListFlyoutOpen(true)}
                label={t('groupsList.rightMenu.showControls')}
                iconPath="a-check.svg"
              />
            </>
          )}
        </>
      )}
      <SideMenuIconButton handleClick={() => alert('TODO')} label={t('groupsList.rightMenu.sendCollegues')} iconPath="icon_share_16.svg" />
    </>
  );
});

export default MaterialSetGroupsRightMenu;
