import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { ProcedureDTO } from 'dto/procedure';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import { useStores } from 'util/mobx/stores';
import ProcedureListItemBody from './ProcedureListItemBody';

interface Props {
  procedure: ProcedureDTO;
  chapterId: string;
  onFlyoutOpen: (chapterId: string) => void;
  handleCloneProcedure: (chapterId: string) => void;
  handleCloneMaterialKnowledge: (chapterId: string) => void;
}

export default observer(({ procedure, onFlyoutOpen, chapterId, handleCloneMaterialKnowledge, handleCloneProcedure }: Props) => {
  const { guideStore } = useStores();
  const { t } = useTranslation('guideProcedure');
  return (
    <div>
      <div className="">
        <Can I={actions.add} this={subject(subjectArea.guideProcedure, { departmentId: guideStore.selectedGuide?.departmentId })}>
          <ListItemAdd className="post_item_add">
            <InfoBox label={t('button.addProcedure')} onClick={() => onFlyoutOpen(chapterId)} />
            <InfoBox label={t('button.getProcedure')} onClick={() => handleCloneProcedure(chapterId)} />
            <InfoBox label={t('button.getMaterial')} onClick={() => handleCloneMaterialKnowledge(chapterId)} />
          </ListItemAdd>
        </Can>
        <ProcedureListItemBody procedure={procedure} />
      </div>
    </div>
  );
});
