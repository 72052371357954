/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'config/config';
import { FileMetadataDTO } from 'dto/file';
import ErrorStore from 'stores/errorStore';
import { ApiError } from 'util/error';

export type UploadProgressCallback = (e: ProgressEvent) => void;
export type FileUploadFunc = (file: FormData, onUploadProgressCallback: UploadProgressCallback) => Promise<FileMetadataDTO>;

let token: string;
let errorStore: ErrorStore;
let onUnauthorized: () => void;

export const setErrorStore = (errStore: ErrorStore) => {
  errorStore = errStore;
};

export const getErrorStore = () => {
  return errorStore;
};

export const setNewJwt = (jwt: string) => {
  token = jwt;
};

export const setOnUnauthorized = (cb: () => void) => {
  onUnauthorized = cb;
};

const pathBuilder = (apiPath: string) => {
  if (!apiPath.startsWith('/')) {
    throw new Error("API-URL path has to start with '/'");
  }

  const { url, path, port } = config.api;
  return `${url}${port ? `:${port}` : ''}${path ? `/${path}` : ''}${apiPath}`;
};

const withAuth = (cfg?: AxiosRequestConfig): AxiosRequestConfig => {
  return {
    ...cfg,
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            ...cfg?.headers
          }
        }
      : {})
  };
};

export const xhrWithAuth = (xhr: XMLHttpRequest) => {
  if (token) {
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  }
};

function logoutOnUnauthorized(err: any): Promise<AxiosResponse<any>> {
  if (err.response?.status === 401) {
    console.error('unauthorized');
    if (onUnauthorized) {
      onUnauthorized();
    }
  }
  throw err;
}

function onError(hideError = false) {
  if (hideError) {
    return (err: any) => {
      throw err;
    };
  }

  return (err: any) => {
    if (errorStore && err.response?.status !== 401) {
      let error = {
        ...err
      };
      if (err.response?.data) {
        error = new ApiError(
          err.response?.data?.message,
          err.response?.data?.code,
          err.response?.data?.errorInstanceId,
          err.response?.data?.stack
        );
      }
      errorStore.onError(error);
    }
    throw err;
  };
}

export const get = async (apiPath: string, params?: Record<string, any>, axiosConfig?: AxiosRequestConfig, hideError = false) => {
  return axios
    .get(pathBuilder(apiPath), {
      params: { ...(params || {}) },
      ...withAuth(axiosConfig)
    })
    .catch(onError(hideError))
    .catch(logoutOnUnauthorized);
};

/**
 * getFile is the same as get() but just uses the path as it is and does not call the pathBuilder.
 * It can be used to download any resource together with authentication.
 * It is recommended to use
 * {
 *     responseType: 'blob'
 * }
 * as axiosConfig.
 * @param path
 * @param params
 * @param axiosConfig
 */
export const getFile = async (path: string, params?: Record<string, any>, axiosConfig?: AxiosRequestConfig, hideError = false) => {
  return axios
    .get(path, {
      params: { ...(params || {}) },
      ...withAuth(axiosConfig)
    })
    .catch(onError(hideError))
    .catch(logoutOnUnauthorized);
};

export const post = (apiPath: string, params?: Record<string, any>, axiosConfig?: AxiosRequestConfig, hideError = false) =>
  axios
    .post(pathBuilder(apiPath), params || {}, withAuth(axiosConfig))
    .catch(onError(hideError))
    .catch(logoutOnUnauthorized);

export const patch = (apiPath: string, params?: Record<string, any>, hideError = false) =>
  axios
    .patch(pathBuilder(apiPath), params || {}, withAuth())
    .catch(onError(hideError))
    .catch(logoutOnUnauthorized);

export const put = (apiPath: string, params?: Record<string, any>, hideError = false) =>
  axios
    .put(pathBuilder(apiPath), params || {}, withAuth())
    .catch(onError(hideError))
    .catch(logoutOnUnauthorized);

export const del = (apiPath: string, params?: Record<string, any>, hideError = false) => {
  return axios
    .delete(pathBuilder(apiPath), { data: { ...(params || {}) }, ...withAuth() })
    .catch(onError(hideError))
    .catch(logoutOnUnauthorized);
};

export const unsafePost = (apiPath: string, params?: Record<string, any>, hideError = false) => {
  return axios.post(pathBuilder(apiPath), params || {}).catch(onError(hideError));
};

export const unsafePatch = (apiPath: string, params?: Record<string, any>, hideError = false) =>
  axios.patch(pathBuilder(apiPath), params || {}).catch(onError(hideError));
