/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsString, IsUUID, IsEnum, IsOptional } from 'class-validator';
import 'reflect-metadata';

export enum ContentType {
  Unknown,
  Video,
  Picture,
  PDFDocument,
  WordDocument,
  ExcelDocument,
  PowerPointDocument,
  AccessDocument
}

export enum ProcessingStatus {
  /**
   * It is still waiting to be processed.
   */
  Pending,

  /**
   * It is currently processing.
   */
  Processing,

  /**
   * Processing finished, everything is fine.
   */
  Finished,

  /**
   * An error ocurred while processinG.
   */
  Error,

  /**
   * The server was restarted while this file was in Processing or Pending state.
   */
  Aborted,

  /**
   * It is currently uploading.
   */
  Uploading
}

export class FileMetadataDTO {
  @IsUUID()
  fileId!: string;

  // TODO: IsURL doesn't work
  @IsString()
  fullUrl!: string;

  // TODO: IsURL doesn't work
  @IsString()
  @IsOptional()
  thumbnailUrl?: string;

  @IsString()
  originalFileName!: string;

  @IsString()
  @IsOptional()
  fileExtension?: string;

  @IsString()
  mimeType!: string;

  @IsString()
  path!: string;

  @IsEnum(ProcessingStatus)
  status!: ProcessingStatus;

  @IsEnum(ContentType)
  contentType!: ContentType;
}

export class FileIdDTO {
  @IsUUID()
  fileId!: string;
}

export class OptionalPictureDTO {
  @IsUUID()
  @IsOptional()
  pictureFileId?: string;

  @IsString()
  @IsOptional()
  picture?: string;

  @IsString()
  @IsOptional()
  pictureThumbnail?: string;
}

export class PictureDTO {
  @IsUUID()
  pictureFileId!: string;

  @IsString()
  picture!: string;

  @IsString()
  pictureThumbnail!: string;
}

export class FileIdsDTO {
  @IsUUID(undefined, { each: true })
  fileIds!: string[];
}
