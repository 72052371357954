import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { useStores } from 'util/mobx/stores';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import { createStorageItem } from '../locationAdministrationUtils';

const LocationAdministrationRightMenu = observer(() => {
  const { t } = useTranslation('storageLocations');
  const { t: searchTranslation } = useTranslation('materialSearch');
  const { storageLocationStore, domainStore, searchStore } = useStores();

  const handleCreate = () => {
    if (storageLocationStore.selectedStorageLocation) {
      storageLocationStore.setSelectedParentId(storageLocationStore.selectedStorageLocation.storageLocationId);
      storageLocationStore.setSelectedIndexToCreate(storageLocationStore.selectedStorageLocationIndex + 1);
      storageLocationStore.setIsCreateFlyoutOpen(true);
    } else {
      storageLocationStore.setIsCreateFlyoutOpen(true);
    }
  };

  const handleSwitch = async () => domainStore.setShowSwitchDomainFlyout(true);

  const handleDelete = async () => storageLocationStore.setIsDeleteFlyoutOpen(true);

  const handleShowSearchPanel = () => {
    searchStore.openSearchPanel(m => {
      if (!storageLocationStore.selectedStorageLocation) {
        return;
      }
      createStorageItem(storageLocationStore, storageLocationStore.selectedStorageLocation, m);
    }, searchTranslation('searchItem.addItem'));
  };

  return (
    <>
      <SideMenuIconButton
        handleClick={handleCreate}
        label={
          storageLocationStore.selectedStorageLocation
            ? t('rightMenu.createNew', { locationName: storageLocationStore.selectedStorageLocation.name })
            : t('rightMenu.createNewStartpoint')
        }
        iconPath="icon_f-add_16.svg"
      />
      {storageLocationStore.selectedStorageLocation && (
        <SideMenuIconButton handleClick={handleShowSearchPanel} label={t('rightMenu.addMaterial')} iconPath="icon_f-add_16.svg" />
      )}
      <SideMenuIconButton handleClick={handleSwitch} label={t('rightMenu.changeFunctionalArea')} iconPath="icon_pin-edit_16.svg" />
      <Can I={actions.delete} this={subject(subjectArea.storageLocation, { functionalAreaId: domainStore.currentFunctionalArea.id })}>
        {storageLocationStore.selectedStorageLocation && (
          <SideMenuIconButton
            handleClick={handleDelete}
            label={t('rightMenu.delete', { locationName: storageLocationStore.selectedStorageLocation.name })}
            iconPath="icon_bin_16.svg"
            red
          />
        )}
      </Can>
    </>
  );
});

export default LocationAdministrationRightMenu;
