import React from 'react';
import { observer } from 'mobx-react';
import { TemplateDTO } from 'dto/template';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import Instructions from 'components/ListItems/components/Instructions';
import { UsedMaterialStatus } from 'dto/usedMaterial';
import SurgeryPackageListItem from '../SurgeryPackageListItem';
import './SurgeryTemplateListItem.css';

interface Props {
  template: TemplateDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
}

const SurgeryTemplateListItem = observer(({ template, surgeryGuide }: Props) => {
  const renderList = () => (
    <div className="content_holder no_padding padding_left in_matlist lvl_1 guide-template-instruments-list">
      {template.packages.map(packageItem => {
        // if (packageItem.packagingType === PackagingType.Single) {
        //   return <SingleInstrumentListItem key={packageItem.packageId} singleInstrument={packageItem} guideData={surgeryGuide} />;
        // }
        return <SurgeryPackageListItem packageItem={packageItem} surgeryGuide={surgeryGuide} key={packageItem.packageId} />;
      })}
      <div className="div-block-102_fixed-copy">
        <div className="div-block-108-copy" />
      </div>
    </div>
  );

  const amountOfCheckedPackages = surgeryGuide.usedMaterials.filter(m => m.status !== UsedMaterialStatus.None).length;

  return (
    <ListItemDropdownContainer list={renderList()} classPrefix="template" className="surgery-template" inGuide>
      <div className="item_count _1_lvl">
        <div>{surgeryGuide.amount}</div>
      </div>
      <div className="item_dropdown_holder" />
      <div className="image_wrapper_50">
        {amountOfCheckedPackages >= surgeryGuide.amount ? (
          <div className="status_only in_mainlist status-only checked-status">
            <img src="images/checked-icon.svg" alt="" />
          </div>
        ) : (
          <div className="status_only in_mainlist status-only" />
        )}

        <div className="image_border white image-border" />
        <img src="images/icon_layer.jpg" alt="" className="image_circle_50" />
      </div>
      <div className="material_info">
        <div className="material_text">
          <div>{template.name}</div>
          <Instructions notes={surgeryGuide.notes} />
        </div>
      </div>
    </ListItemDropdownContainer>
  );
});

export default SurgeryTemplateListItem;
