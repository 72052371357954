import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';
import ReportsDrawer from 'components/ReportsDrawer';
import AppBar from 'components/AppLayout/AppBar';
import SwitchDomainFlyout from 'pages/flyouts/SwitchDomainFlyout';
import FullScreenImageFlyout from 'pages/flyouts/FullScreenImageFlyout';
import PDFViewerFlyout from 'pages/flyouts/PDFViewerFlyout';
import GuideDetailDrawer from 'pages/flyouts/GuideDetailDrawer';
import SearchPanel from 'pages/search';
import AppMenu from './AppMenu';

import './AppLayout.css';

interface Props {
  drawers?: JSX.Element;
  fullPageDrawer?: JSX.Element;
  flyoutInDrawer?: JSX.Element;
  boardFlyout?: JSX.Element;
  children: JSX.Element[] | JSX.Element;
  appBarIcon: JSX.Element;
  appBarChildren: JSX.Element[] | JSX.Element;
  appBarRightChildren?: JSX.Element[] | JSX.Element;
  drawer?: JSX.Element[] | JSX.Element;
  flyout?: JSX.Element[] | JSX.Element;
}

const AppLayout = (props: Props) => {
  const history = useHistory();
  const { appNavigationStore: navigation, flyoutStore } = useStores();
  const itemRef: React.RefObject<HTMLDivElement> = useRef(null);
  const buttonItemRef: React.RefObject<HTMLDivElement> = useRef(null);

  // show drawer on specific page
  const drawerPages = ['/guide/materials'];
  const showDrawerOnCurrentPage = drawerPages.includes(history.location.pathname);
  if (!showDrawerOnCurrentPage) flyoutStore.setShowItemDetailDrawer(false);

  useEffect(() => {
    /**
     * closes the item menu
     * @param event
     */
    const onClose = (event: MouseEvent) => {
      if (
        navigation.isMenuOpen &&
        event.target instanceof Element &&
        !itemRef.current?.contains(event.target) &&
        !buttonItemRef.current?.contains(event.target)
      ) {
        navigation.closeMenu();
      }
    };
    if (itemRef.current) {
      if (navigation.isMenuOpen) {
        document.body.addEventListener('click', onClose);
      } else {
        document.body.removeEventListener('click', onClose);
      }
    }

    return () => {
      document.body.removeEventListener('click', onClose);
    };
  }, [navigation.isMenuOpen, navigation, itemRef]);
  return (
    <>
      {props.boardFlyout && props.boardFlyout}
      {props.fullPageDrawer && props.fullPageDrawer}
      {props.flyoutInDrawer && <div className="flyouts-wrapper">{props.flyoutInDrawer}</div>}
      {}

      <AppBar icon={props.appBarIcon} rightChildren={props.appBarRightChildren} buttonItemRef={buttonItemRef}>
        {props.appBarChildren}
      </AppBar>

      <div
        className={navigation.isMenuOpen ? 'menu-transition-wrapper__right' : 'menu-transition-wrapper__left remove-transform'}
        ref={itemRef}
      >
        <AppMenu />
      </div>
      <FullScreenImageFlyout />
      <PDFViewerFlyout />
      <ReportsDrawer />
      <SwitchDomainFlyout />
      <GuideDetailDrawer />
      <SearchPanel />

      {props.drawers && props.drawers}
      <div className={navigation.isMenuOpen ? 'transition-wrapper__right remove-transform' : 'transition-wrapper__left remove-transform'}>
        <div className="content-wrapper page-content-wrapper">
          {props.children}
          {props.flyout && props.flyout}
          {props.drawer && props.drawer}
        </div>
      </div>
    </>
  );
};

export default observer(AppLayout);
