import { CreateGroupMaterialDTO, UpdateSortGroupMaterialsDTO, GroupMaterialDTO } from 'dto/groupMaterial';
import { post, del, put } from './common';

import { validateBool } from './validation';

export const createGroupMaterial = async (groupMaterial: CreateGroupMaterialDTO): Promise<GroupMaterialDTO> => {
  const newSetGroup = await post('/material-set/set-group/group-material', groupMaterial).then(response => response.data);
  return newSetGroup;
};

export const deleteGroupMaterial = async (groupMaterialId: string) => {
  return del('/material-set/set-group/group-material', { groupMaterialId }).then(response => validateBool(response.data));
};

export const reorderGroupMaterials = async (reordered: UpdateSortGroupMaterialsDTO) => {
  return put('/material-set/set-group/group-materials/positions', reordered).then(response => validateBool(response.data));
};
