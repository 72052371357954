import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialKnowledge from 'components/MaterialKnowledge';
import ImageCircle from 'components/ImageCircle';
import SingleInstrumentContentRightMenu from './SingleInstrumentContentRightMenu';

interface Props {
  packageId: string;
}

const SingleInstrumentContent = observer(({ packageId }: Props) => {
  const { appNavigationStore, instrumentStore } = useStores();
  const { t } = useTranslation('packages');

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <SingleInstrumentContentRightMenu />;
    });
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('knowledge');

  return (
    <div className="single_colum_content">
      <div className="start_content_box">
        <div className="h1">{t('content.alsoApplies')}</div>
        <div className="start_box_wrapper">
          {instrumentStore.fullPackageItem?.template.packages.map(packageItem => (
            <div key={packageItem.packageId} className="list_item_link">
              <ImageCircle noHover pictures={packageItem.picture ? [packageItem.picture] : []} />
              <div className="link_item_info">
                <div className="link-block">
                  <div>{packageItem.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <MaterialKnowledge materialLikeId={{ packageId }} />
    </div>
  );
});

export default SingleInstrumentContent;
