import React, { useState } from 'react';
import Button from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import { parseFromDate, parseToDate } from 'util/date';
import { useStores } from 'util/mobx/stores';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import './CalendarButton.css';

const CalendarButton = () => {
  const { t } = useTranslation('date');
  const { surgeryStore } = useStores();
  const [isOpen, setIsOpen] = useState(false);

  const onSelectDate = (date: MaterialUiPickersDate) => {
    if (!date) return;
    surgeryStore.selectFilterDate(parseToDate(date));
  };

  return (
    <>
      <Button className="btn_material_detail as_filter calendar-button" onClick={() => setIsOpen(true)}>
        <img src="images/icon_calendar-2_16.svg" alt="" className="image_mat_detail as_filter" />
        <div>
          <strong>{t('selectDate')}</strong>
        </div>
      </Button>
      <DatePicker
        onClose={() => setIsOpen(false)}
        autoOk
        variant="inline"
        open={isOpen}
        className="hidden-input"
        value={parseFromDate(surgeryStore.selectedFilterDate)}
        onChange={onSelectDate}
      />
    </>
  );
};

export default CalendarButton;
