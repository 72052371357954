import { Ability, defineAbility } from '@casl/ability';
import { JwtPayloadDTO, UserRole } from 'dto/user';
import { createContextualCan } from '@casl/react';
import { createContext } from 'react';

export enum subjectArea {
  // guide
  guide = 'guide',
  createGuideFlyoutDepartmentSelector = 'createGuideFlyoutDepartmentSelector',

  // guideMaterial
  guideMaterial = 'guideMaterial',

  // guideProcedure
  guideProcedure = 'guideProcedure',

  // materialDrawer
  materialSettings = 'materialSettings',
  materialSettingsButton = 'materialSettingsButton',

  // storageLocation
  storageLocation = 'storageLocation',

  // left navigationBar
  appMenuSettingsButton = 'appMenuSettingsButton',

  // materialSet
  materialSetGroup = 'materialSetGroup',
  setGroupMenu = 'setGroupMenu',
  groupMaterial = 'groupMaterial',
  groupMaterialMenu = 'groupMaterialMenu',

  // Tag
  tag = 'tag'
}

export enum actions {
  see = 'see',
  add = 'add',
  delete = 'delete',
  deleteMany = 'deleteMany',
  update = 'update',
  dragAndDrop = 'dragAndDrop',
  selectDepartmentForCreate = 'selectDepartmentForCreate'
}

export const AbilityContext = createContext<Ability>(new Ability());
export const Can = createContextualCan(AbilityContext.Consumer);

/**
 * @param user contains details about logged in user: its id, name, email, etc
 */
export default function defineAbilitiesFor(jwtPayload: JwtPayloadDTO) {
  const ifIsDepartmentMaintainer = { departmentId: { $in: jwtPayload.departmentMaintainerOf } };
  const ifIsDepartmentMaintainerOfFunctionalArea = { functionalAreaId: { $in: jwtPayload.functionAreasWithPermissions } };
  const { add, deleteMany, dragAndDrop, see, selectDepartmentForCreate, update } = actions;
  const {
    appMenuSettingsButton,
    createGuideFlyoutDepartmentSelector,
    groupMaterialMenu,
    guide,
    guideMaterial,
    guideProcedure,
    materialSettings,
    materialSettingsButton,
    storageLocation,
    setGroupMenu,
    materialSetGroup,
    groupMaterial,
    tag
  } = subjectArea;

  return defineAbility((can, cannot) => {
    // admins can do everything
    if (jwtPayload.userRole === UserRole.Admin) {
      // "manage" and "all" are special keywords in CASL. manage represents any action and all represents any subject
      // https://casl.js.org/v4/en/guide/intro
      // https://github.com/stalniy/casl/blob/master/packages/casl-ability/CHANGELOG.md#caslability-v300-2019-02-04
      can('manage', 'all');
      return;
    }

    // importers can do nothing
    if (jwtPayload.userRole === UserRole.Importer) {
      return;
    }

    if (jwtPayload.userRole === UserRole.User) {
      // guide
      can(add, guide, ifIsDepartmentMaintainer);
      can(actions.delete, guide, ifIsDepartmentMaintainer);

      cannot(selectDepartmentForCreate, createGuideFlyoutDepartmentSelector);
      cannot(deleteMany, guide);

      // guideMaterial
      can(add, guideMaterial, ifIsDepartmentMaintainer);
      can(update, guideMaterial, ifIsDepartmentMaintainer);
      can(actions.delete, guideMaterial, ifIsDepartmentMaintainer);
      can(dragAndDrop, guideMaterial, ifIsDepartmentMaintainer);

      // guideProcedure
      can(add, guideProcedure, ifIsDepartmentMaintainer);
      can(update, guideProcedure, ifIsDepartmentMaintainer);
      can(dragAndDrop, guideProcedure, ifIsDepartmentMaintainer);

      // materialDrawer
      can(see, materialSettings, ifIsDepartmentMaintainer);
      can(see, materialSettingsButton, ifIsDepartmentMaintainer);

      // left navigationBar
      cannot(see, appMenuSettingsButton);

      // storageLocation
      can(actions.delete, storageLocation, ifIsDepartmentMaintainerOfFunctionalArea);

      // materialSet
      can(add, materialSetGroup, ifIsDepartmentMaintainer);
      can(add, groupMaterial, ifIsDepartmentMaintainer);
      can(see, setGroupMenu, ifIsDepartmentMaintainer);
      can(see, groupMaterialMenu, ifIsDepartmentMaintainer);

      // tag
      can(add, tag, ifIsDepartmentMaintainer);
      can(update, tag, ifIsDepartmentMaintainer);
    }
  });
}
