import { observable, action, computed, runInAction } from 'mobx';
import { MaterialSearchDTO, FilterBy, MaterialDTO, MaterialContainersDTO } from 'dto/material';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getMaterials, getMaterialContainers, getMaterial } from 'api/material';
import { fromPromise } from 'util/mobx/utils';
import { PackageDTO, SimplePackageDTO } from 'dto/package';
import { BasicMaterialSetDTO } from 'dto/materialSet';
import LoadingStore from './loadingStore';

export interface AnyMaterial {
  material?: MaterialDTO;
  pack?: PackageDTO | SimplePackageDTO; // TODO clarify / decide if we need to keep SimplePackageDTO
  materialSet?: BasicMaterialSetDTO;
}

type OnSelectCallback = (materialLike: AnyMaterial) => void;

export enum SelectableType {
  Material,
  Package,
  Set
}

export default class SearchStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  isSearchPanelOpen = false;

  @observable
  searchQuery: MaterialSearchDTO = { page: 0, pageSize: 20, filterBy: 'all' };

  @observable
  selectableTypes: SelectableType[] = [];

  @observable
  onSelect?: OnSelectCallback;

  @observable
  actionButtonLabel = '';

  @observable
  isMaterialContainerFlyoutOpen = false;

  @observable
  selectedSearchItem?: MaterialDTO;

  @observable
  selectedMaterialContainers?: MaterialContainersDTO;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  @computed
  get searchResults() {
    if (this.searchQuery.query && this.searchQuery.query.length > 2) {
      const query = this.searchQuery;
      return fromPromise([], getMaterials(query));
    }
    return () => [];
  }

  @action
  openSearchPanel = (onSelect?: OnSelectCallback, actionButtonLabel = '', ...selectableTypes: SelectableType[]) => {
    this.selectableTypes = selectableTypes;
    if (selectableTypes.length === 0) {
      this.selectableTypes = [SelectableType.Material, SelectableType.Set, SelectableType.Package];
    }

    this.isSearchPanelOpen = true;
    this.actionButtonLabel = actionButtonLabel;
    if (onSelect) {
      this.onSelect = (m: AnyMaterial) => {
        this.closeSearchPanel();
        onSelect(m);
      };
    } else {
      this.onSelect = undefined;
    }
  };

  @action
  closeSearchPanel() {
    this.closeMaterialContainerFlyout();

    this.isSearchPanelOpen = false;
    this.resetSearch();
    this.onSelect = undefined;
  }

  @action
  closeMaterialContainerFlyout() {
    this.isMaterialContainerFlyoutOpen = false;
    this.selectedSearchItem = undefined;
    this.selectedMaterialContainers = undefined;
  }

  @action
  search(query: string) {
    // search is done by computed searchResults value
    const newSearchQuery = this.searchQuery;
    this.searchQuery.query = query;
    this.searchQuery = newSearchQuery;
  }

  @action
  refreshSearch() {
    this.searchQuery = {
      ...this.searchQuery
    };
    this.refreshSelectedSearchItem();
  }

  @action
  resetSearch() {
    this.searchQuery = { page: 0, pageSize: 20, filterBy: 'all' };
    this.selectableTypes = [];
  }

  @action
  filterBy(filter: FilterBy) {
    this.searchQuery.filterBy = filter;
  }

  @action
  setIsMaterialContainerFlyoutOpen(status: boolean) {
    this.selectedMaterialContainers = undefined;
    this.isMaterialContainerFlyoutOpen = status;
  }

  @action
  async selectSearchItem(material: MaterialDTO) {
    this.selectedSearchItem = material;
    this.setIsMaterialContainerFlyoutOpen(true);

    const materialContainers = await this.loadingStore.withLoadingBar(() => getMaterialContainers(material.materialId));

    runInAction(() => {
      this.selectedMaterialContainers = materialContainers;
    });
  }

  async refreshSelectedSearchItem() {
    const refreshedMaterial = await this.loadingStore.withLoadingBar(() => {
      if (!this.selectedSearchItem) {
        return Promise.resolve(undefined);
      }
      return getMaterial(this.selectedSearchItem.materialId);
    });
    if (refreshedMaterial) {
      this.selectSearchItem(refreshedMaterial);
    }
  }
}
