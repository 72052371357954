import moment, { Moment } from 'moment';
import { TransProps } from 'react-i18next';
import { DateDTO, UTCTimeDTO } from 'dto/surgery';
import 'moment/locale/de';
import { TFunction } from 'i18next';

export const dateFormat = 'DD.MM.YYYY';
export const timeFormat = 'HH:mm';

export const getDateWording = (d: Date | undefined, { t }: TransProps, withArticle = false) => {
  if (!t || !d) return '';
  const today = moment();
  const date = moment(d);
  const yesterday = moment().subtract(1, 'days');
  if (date.isSame(today, 'day')) {
    return t('today');
  }
  if (date.isSame(yesterday, 'day')) {
    return t('yesterday');
  }
  return withArticle ? `${t('at')} ${date.format(dateFormat)}` : date.format(dateFormat);
};

export const getDayTimeWording = (d: Date, { t }: TransProps, withArticle = false) => {
  if (!t) return '';
  const date = moment(d);
  const dateWording = getDateWording(d, { t }, withArticle);

  return `${dateWording} ${moment(date).format(timeFormat)} ${t('time')}`;
};

export const getDayWording = (d: Date, { t }: TransProps, withArticle = false) => {
  if (!t) return '';
  const dateWording = getDateWording(d, { t }, withArticle);

  return `${dateWording}`;
};

export const isPostNew = (d: Date) => {
  const today = moment();
  const date = moment(d);
  return date.isSame(today, 'day');
};

export const getHoursTillNow = (d: Date) => {
  const time = moment.duration(
    moment(d)
      .locale('de')
      .diff(moment(new Date()))
  );

  return time;
};

export const getCurrentDate = (): DateDTO => {
  return {
    year: moment().year(),
    month: moment().month() + 1,
    dayInMonth: moment().date()
  };
};

export const parseToDate = (date: Moment): DateDTO => ({
  year: date.year(),
  month: date.month() + 1,
  dayInMonth: date.date()
});

export const parseFromDate = (date?: DateDTO) => {
  if (!date) return undefined;
  return moment(`${date.dayInMonth}.${date.month}.${date.year}`, 'DD.MM.YYYY');
};

export const parseToTime = (time: Moment): UTCTimeDTO => ({
  minute: time.minutes(),
  hour: time.hours(),
  second: time.seconds()
});

export const parseFromTime = (time?: UTCTimeDTO) => {
  if (!time) return undefined;
  return moment(`${time.hour}:${time.minute}:${time.second}`, 'HH:mm:ss');
};

export const getDayName = (date: DateDTO, t: TFunction) => {
  if (moment().date() === date.dayInMonth) {
    return t('today');
  }
  if (
    moment()
      .add(1, 'day')
      .date() === date.dayInMonth
  ) {
    return t('tomorrow');
  }
  return t('dayAfterTomorrow');
};

export const getTimeRangeString = (t: TFunction, start: UTCTimeDTO, end: UTCTimeDTO) => {
  const startString = parseFromTime(start)?.format(timeFormat);
  const endString = parseFromTime(end)?.format(timeFormat);
  return `${startString} ${t('time')} ${t('till')} ${endString} ${t('time')}`;
};

export const getTimeDifference = (start: UTCTimeDTO, end: UTCTimeDTO) => {
  const duration = moment.duration(parseFromTime(end)?.diff(parseFromTime(start)));
  return duration.asMinutes();
};
