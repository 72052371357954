import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { GroupedMaterialGuidesDTO } from 'dto/material';
import ItemHead from './ItemHead';

interface Props {
  type?: 'instrument' | 'material' | 'singleInstrument';
  filterButtonLabel: string;
}

const GlobalFunctions = observer(({ type = 'material', filterButtonLabel }: Props) => {
  const { materialStore, instrumentStore } = useStores();

  const materialLikeGuides = type === 'singleInstrument' ? instrumentStore.templateGuides : materialStore.materialGuides;

  return (
    <div id="grid-guides-wrapper_content" className="wrapper_content">
      <div className="single_colum_content">
        <div className="content_filter">
          <div className="btn_filter_content active">
            <div className="txt_dropout_1">{filterButtonLabel}</div>
          </div>
        </div>
        {materialLikeGuides &&
          materialLikeGuides.map((groupedGuide: GroupedMaterialGuidesDTO) => {
            return <ItemHead key={groupedGuide.department.departmentId} groupedGuide={groupedGuide} type={type} />;
          })}
      </div>
    </div>
  );
});

export default GlobalFunctions;
