/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { MouseEvent, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { getAnimationState } from 'util/animation';
import { useTranslation } from 'react-i18next';
import PostDetailsFlyout from './PostDetailsFlyout';
import DepartmentComments from './DepartmentComments';
import ManualUpdates from './ManualUpdates';
import Dialog from './Dialog';
import './BoardFlyout.css';

const BoardFlyout = observer(() => {
  const { postStore, domainStore } = useStores();
  const [isContainerShow, setIsContainerShow] = useState(false);
  const { t } = useTranslation('board');
  const onCloseFlyout = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    postStore.setIsChatBoardFlyoutShown(false);
    setTimeout(() => {
      setIsContainerShow(false);
    }, 1000);
  };
  const loadData = useCallback(() => {
    if (!postStore.isChatBoardFlyoutShown) return;
    postStore.loadGuidesPostsInFunctionalArea(domainStore.currentFunctionalArea.id);
    postStore.loadDepartmentPostsInFunctionalArea(domainStore.currentFunctionalArea.id);
    postStore.loadFunctionalAreaPosts(domainStore.currentFunctionalArea.id);
    setIsContainerShow(true);
  }, [domainStore.currentFunctionalArea.id, postStore]);
  useEffect(() => {
    loadData();
    const interval = setInterval(() => {
      loadData();
    }, 60000);
    return () => {
      clearInterval(interval);
      if (!postStore.isChatBoardFlyoutShown && !postStore.isChatFlyoutOpen) {
        setTimeout(() => {
          postStore.resetPosts();
          setIsContainerShow(false);
        }, 500);
      }
    };
  }, [postStore, postStore.isChatBoardFlyoutShown, loadData]);
  return (
    <div style={{ display: isContainerShow ? 'inherit' : 'none' }} className="board-wrapper board-wrapper-custom">
      <PostDetailsFlyout />
      <div className={`board_content board-content ${getAnimationState(postStore.isChatBoardFlyoutShown, 'shown', 'hidden')}`}>
        <a href="#" className="btn_close_board w-inline-block btn-close-board" onClick={onCloseFlyout}>
          <img src="images/icon_e-remove_12.svg" width="16" alt="" className="image_close_board" />
        </a>
        <div className="w-layout-grid board_grid board-grid">
          <div className="board_head board-head">
            <div className="icon_board activ">
              <img src="images/icon_f-comment_16.svg" alt="" />
            </div>
            <div className="title_info in_board">
              <div>{t('label.title')}</div>
              <div className="t2 top-margin">
                {domainStore.currentLocation.shortName} | {domainStore.currentFunctionalArea.shortName}
              </div>
            </div>
          </div>
          <DepartmentComments posts={postStore.departmentPostsInFunctionalArea} />
          <ManualUpdates posts={postStore.guidesPostsInFunctionalArea} />
          <Dialog posts={postStore.functionalAreaPosts} />
        </div>
      </div>
      <div className={`board_background board-background ${getAnimationState(postStore.isChatBoardFlyoutShown, 'shown', 'hide')}`}>
        <div className="board_image_icon board-image-icon">
          <img src="images/icon_f-comment_16.svg" alt="" />
        </div>
      </div>
    </div>
  );
});

export default BoardFlyout;
