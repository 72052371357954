import React from 'react';
import { SurgeryGuideMaterialHistoryDTO, HistoryActionType, HistoryDTO } from 'dto/history';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import ProcedureContentElementHistoryIconsFactory from 'pages/guideProcedure/ProcedureContentElementHistory/ProcedureContentElementHistoryIcons';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import Picture from 'components/Picture';
import MaterialValueTags from 'components/MaterialValueTags';
import SurgeryHistoryItem from '../SurgeryHistoryItem';

interface Props {
  histories: SurgeryGuideMaterialHistoryDTO[];
}

const getHistoryDescription = (translate: TFunction, item: HistoryDTO) => {
  if (item.action === HistoryActionType.create) {
    return translate('chatAndHistory.historyMaterials.created');
  }
  if (item.action === HistoryActionType.update && item.field === 'status' && item.dataValue === '1') {
    return translate('chatAndHistory.historyMaterials.checked');
  }
  if (item.action === HistoryActionType.update && item.field === 'status' && item.dataValue === '0') {
    return translate('chatAndHistory.historyMaterials.unchecked');
  }
  if (item.action === HistoryActionType.update && item.field === 'status' && item.dataValue === '4') {
    return translate('chatAndHistory.historyMaterials.used');
  }
  if (item.action === HistoryActionType.update && item.field === 'status' && item.dataValue === '5') {
    return translate('chatAndHistory.historyMaterials.implanted');
  }
  if (item.action === HistoryActionType.update && item.field === 'status' && item.dataValue === '6') {
    return translate('chatAndHistory.historyMaterials.notImplanted');
  }
  if (item.action === HistoryActionType.update && item.field === 'status' && item.dataValue === '3') {
    return translate('chatAndHistory.historyMaterials.notRequired');
  }
  if (item.action === HistoryActionType.update && item.field === 'amount') {
    return translate('chatAndHistory.historyMaterials.amount', {previousDataValue: item.previousDataValue, dataValue: item.dataValue});
  }
  if (item.action === HistoryActionType.update && (item.field === 'noteCircular' || item.field === 'noteSterile' || item.field === 'notePreparer' || item.field === 'unpackOnInstruction')) {
    return translate('chatAndHistory.historyMaterials.note');
  }
  return translate('chatAndHistory.historyMaterials.fallback');
};

const SurgeryHistoryMaterials = ({ histories }: Props) => {
  const { t } = useTranslation('surgery');

  const renderList = (materialHistories: HistoryDTO[]) => {
    return (
      <>
        {materialHistories.map(historyItem => (
          <SurgeryHistoryItem
            className="surgery-material-history-item"
            icon={<ProcedureContentElementHistoryIconsFactory action={historyItem.action} />}
            item={historyItem}
            description={getHistoryDescription(t, historyItem)}
            key={historyItem.historyId}
          />
        ))}
      </>
    );
  };
  return (
    <div className="history-guide-items-list">
      {histories.map(guideMaterialHistory => (
        <ListItemDropdownContainer
          className="list_item_material with_dropdown history-material"
          list={renderList(guideMaterialHistory.materialHistories)}
          classPrefix="history-material"
        >
          <>
            {guideMaterialHistory.surgeryGuideMaterial.material && (
              <>
                <div className="image_wrapper_50">
                  <Picture
                    src={guideMaterialHistory.surgeryGuideMaterial.material?.picture}
                    width={50}
                    alt=""
                    className="image_circle_50"
                  />
                </div>
                <div className="material_info">
                  <div className="material_text">
                    <div>{guideMaterialHistory.surgeryGuideMaterial.material?.name}</div>
                  </div>
                  <MaterialValueTags material={guideMaterialHistory.surgeryGuideMaterial.material} />
                </div>
              </>
            )}
            {guideMaterialHistory.surgeryGuideMaterial.materialSet && (
              <>
                <div className="image_wrapper_50">
                  <Picture
                    src={guideMaterialHistory.surgeryGuideMaterial.materialSet?.picture}
                    width={50}
                    alt=""
                    className="image_circle_50"
                  />
                </div>
                <div className="material_info">
                  <div className="material_text">
                    <div>{guideMaterialHistory.surgeryGuideMaterial.materialSet?.name}</div>
                  </div>
                  <MaterialValueTags materialSet={guideMaterialHistory.surgeryGuideMaterial.materialSet} />
                </div>
              </>
            )}
            {guideMaterialHistory.surgeryGuideMaterial.template && (
              <>
                <div className="image_wrapper_50">
                  <div className="image_border white image-border" />
                  <img src="images/icon_layer.jpg" alt="" className="image_circle_50" />
                </div>
                <div className="material_info">
                  <div className="material_text">
                    <div>{guideMaterialHistory.surgeryGuideMaterial.template.name}</div>
                  </div>
                  <MaterialValueTags template={guideMaterialHistory.surgeryGuideMaterial.template} />
                </div>
              </>
            )}
          </>
        </ListItemDropdownContainer>
      ))}
    </div>
  );
};

export default SurgeryHistoryMaterials;
