import {
  GroupedSurgeryGuideMaterialsDTO,
  UpdateSortSurgeryGuideMaterialsDTO,
  UpdateSurgeryGuideMaterialDTO,
  SurgeryGuideMaterialLikeDTO,
  FullSurgeryGuideDTO,
  SurgeryGuideIdDTO,
  CreateSurgeryGuideMaterialDTO
} from 'dto/surgeryGuide';
import {
  GroupedSurgeryGuideProcedureDTO,
  UpdateSortSurgeryGuideProceduresDTO,
  SurgeryProcedureDTO,
  CreateSurgeryGuideProcedureDTO,
  UpdateSurgeryGuideProcedureDTO
} from 'dto/surgeryGuideProcedure';
import { SetUsedMaterialDTO, UsedMaterialDTO } from 'dto/usedMaterial';
import { get, put, patch, post, del } from './common';
import { validateArray, validateBool, validate } from './validation';

export const getSurgeryGuide = async (surgeryGuideId: string): Promise<FullSurgeryGuideDTO> => {
  const surgeryGuide = await get('/surgery/briefing/surgery-guide', { surgeryGuideId }).then(response => response.data);
  return validate(FullSurgeryGuideDTO, surgeryGuide);
};

export const deleteSurgeryGuide = async (surgeryGuideId: string): Promise<boolean> => {
  return del('/surgery/briefing/surgery-guide', { surgeryGuideId }).then(response => validateBool(response.data));
};

export const getSurgeryGuideMaterials = async (surgeryGuideId: string): Promise<GroupedSurgeryGuideMaterialsDTO[]> => {
  const surgeryGuideMaterials = await get('/surgery/briefing/surgery-guide/materials', { surgeryGuideId }).then(response => response.data);
  return validateArray(GroupedSurgeryGuideMaterialsDTO, surgeryGuideMaterials);
};

export const reorderSurgeryGuideMaterials = async (reorderedGuideMaterials: UpdateSortSurgeryGuideMaterialsDTO[]) => {
  return put('/surgery/briefing/surgery-guide/materials/positions', reorderedGuideMaterials).then(response => validateBool(response.data));
};

export const updateSurgeryGuideMaterial = async (
  surgeryGuideMaterialUpdateFields: UpdateSurgeryGuideMaterialDTO
): Promise<SurgeryGuideMaterialLikeDTO> => {
  const updatedGuideMaterial = await patch('/surgery/briefing/surgery-guide/material', surgeryGuideMaterialUpdateFields).then(
    response => response.data
  );
  return validate(SurgeryGuideMaterialLikeDTO, updatedGuideMaterial);
};

export const setUsedSurgeryGuideMaterial = async (fields: SetUsedMaterialDTO): Promise<UsedMaterialDTO[]> => {
  const updatedGuideMaterial = await put('/surgery/briefing/surgery-guide/material/used', fields).then(response => response.data);
  return validateArray(UsedMaterialDTO, updatedGuideMaterial);
};

export const getSurgeryGuideProcedures = async (query: SurgeryGuideIdDTO): Promise<GroupedSurgeryGuideProcedureDTO[]> => {
  const procedureGroups = await get('/surgery/briefing/surgery-guide/procedures', query).then(response => response.data);
  return validateArray(GroupedSurgeryGuideProcedureDTO, procedureGroups);
};

export const reorderSurgeryGuideProcedures = async (reorderedGuideProcedures: UpdateSortSurgeryGuideProceduresDTO[]) => {
  return put('/surgery/briefing/surgery-guide/procedures/positions', reorderedGuideProcedures).then(response =>
    validateBool(response.data)
  );
};

export const updateSurgeryGuideProcedure = async (
  guideProcedureUpdateFields: UpdateSurgeryGuideProcedureDTO
): Promise<SurgeryProcedureDTO> => {
  const updatedGuideProcedure = await patch('/surgery/briefing/surgery-guide/procedure', guideProcedureUpdateFields).then(
    response => response.data
  );
  return validate(SurgeryProcedureDTO, updatedGuideProcedure);
};

export const createSurgeryGuideProcedure = async (newGuideProcedure: CreateSurgeryGuideProcedureDTO) => {
  const createdGuideProcedure = await post('/surgery/briefing/surgery-guide/procedure', newGuideProcedure).then(response => response.data);
  return validate(SurgeryProcedureDTO, createdGuideProcedure);
};

export const createSurgeryGuideMaterial = async (newGuideMaterial: CreateSurgeryGuideMaterialDTO) => {
  const createdGuideMaterial = await post('/surgery/briefing/surgery-guide/material', newGuideMaterial).then(response => response.data);
  return validate(SurgeryGuideMaterialLikeDTO, createdGuideMaterial);
};
