/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import {
  IsString,
  IsUUID,
  ValidateNested,
  IsNotEmptyObject,
  IsNumber,
  IsEnum,
  MinLength,
  MaxLength,
  IsEmail,
  IsOptional,
  IsBoolean,
  IsArray,
  IsDateString,
} from 'class-validator';
import { Type } from 'class-transformer';
import { DepartmentDTO } from './department';
import 'reflect-metadata';
import { OptionalPictureDTO } from './file';
import { DepartmentMaintainerDTO } from './departmentMaintainer';

export enum UserStatus {
  Inactive,
  Active,
  Pending,
}

export enum UserRole {
  User,
  Admin,
  Importer,
}

export enum Gender {
  Female,
  Male,
  Diverse
}

/**
 * UserDTO contains only data of a user which is publicly available
 */
export class UserDTO extends OptionalPictureDTO {
  @IsUUID()
  userId!: string;

  @IsString()
  firstName!: string;

  @IsString()
  lastName!: string;

  @IsEnum(Gender)
  salutationKey!: Gender;

  @IsString()
  @IsOptional()
  title?: string;

  @IsOptional()
  @IsString()
  titleId?: string;

  @IsEnum(UserRole)
  userRole!: UserRole;

  @IsEnum(UserStatus)
  status!: UserStatus;

  @IsString()
  @IsOptional()
  @IsEmail()
  emailAddress?: string;

  @IsString()
  @IsOptional()
  phoneNumber?: string;
}

/**
 * ExtendedUserDTO contains only data of a user which is publicly available but also includes where it is the maintainer.
 */
export class ExtendedUserDTO extends UserDTO {
  @ValidateNested()
  @Type(() => DepartmentMaintainerDTO)
  departmentMaintainerOf!: DepartmentMaintainerDTO[];

  @IsString()
  username!: string;

  @IsOptional()
  @IsDateString()
  lastOnline?: string;
}

/**
 * AccountDTO contains everything from a user including private
 * account data (e.g. username, current department, etc.)
 */
export class AccountDTO extends OptionalPictureDTO {
  @IsUUID()
  userId!: string;

  @IsString()
  firstName!: string;

  @IsString()
  lastName!: string;

  @IsEnum(Gender)
  salutationKey!: Gender;

  @IsString()
  title!: string;

  @IsString()
  username!: string;

  @IsString()
  @IsOptional()
  @IsEmail()
  emailAddress?: string;

  @IsString()
  @IsOptional()
  phoneNumber?: string;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => DepartmentDTO)
  department!: DepartmentDTO;

  @IsEnum(UserRole)
  userRole!: UserRole;

  @IsEnum(UserStatus)
  status!: UserStatus;

  @IsBoolean()
  isTempPassword!: boolean;
}

export class LoginDTO {
  @IsString()
  username!: string;

  @IsString()
  password!: string;
}

export class CheckTempPasswordDTO {
  @IsString()
  username!: string;

  @IsString()
  tempPassword!: string;
}

export class UpdatePasswordWithTempPasswordDTO extends LoginDTO {
  @IsString()
  tempPassword!: string;
}

export class SessionDTO {
  @IsString()
  token!: string;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => AccountDTO)
  account!: AccountDTO;
}

export class RegisterDTO {
  @IsUUID()
  titleId!: string;

  @IsString()
  firstName!: string;

  @IsEnum(Gender)
  salutationKey!: Gender;

  @IsString()
  lastName!: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;

  @IsString()
  @IsOptional()
  @IsEmail()
  emailAddress?: string;

  @IsString()
  @IsOptional()
  phoneNumber?: string;

  @IsString()
  @MinLength(4)
  @MaxLength(20)
  username!: string;

  @IsString()
  @MinLength(8)
  password!: string;
}

export class OptionalAccountDTO extends OptionalPictureDTO {
  @IsUUID()
  userId!: string;

  @IsOptional()
  @IsString()
  firstName?: string;

  @IsOptional()
  @IsString()
  lastName?: string;

  @IsOptional()
  @IsEnum(Gender)
  salutationKey?: Gender;

  @IsOptional()
  @IsString()
  titleId?: string;

  @IsOptional()
  @IsString()
  username?: string;

  @IsString()
  @IsOptional()
  @IsEmail()
  emailAddress?: string;

  @IsString()
  @IsOptional()
  phoneNumber?: string;
}

export class SecureOptionalAccountDTO extends OptionalAccountDTO {
  @IsString()
  @IsOptional()
  newPassword?: string;

  @IsString()
  currentPassword!: string;
}

/**
 * only authorized users can use this so a user for Example cant patch his UserRole
 */
export class AdminOptionalAccountDTO extends OptionalAccountDTO {
  @IsOptional()
  @IsEnum(UserRole)
  userRole?: UserRole;

  @IsOptional()
  @IsEnum(UserStatus)
  status?: UserStatus;
}

export class JwtPayloadDTO {
  @IsUUID()
  userId!: string;

  @IsString()
  username!: string;

  @IsEnum(UserRole)
  userRole!: UserRole;

  @IsEnum(UserStatus)
  userStatus!: UserStatus;

  @IsArray()
  departmentMaintainerOf!: string[];

  // these are just the functionalAreas of the departments where the user has maintainer rights
  @IsArray()
  functionAreasWithPermissions!: string[];

  @IsOptional()
  @IsNumber()
  iat?: number;

  @IsOptional()
  @IsNumber()
  exp?: number;
}
