import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { savePackageFile } from 'api/file';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { InstrumentDTO } from 'dto/instrument';
import Form, { InputField } from 'components/Form';
import Picture from 'components/Picture';

interface InstrumentDetailFormValues {
  materialName: string;
  lastSynchronisation?: string;
  manufacturerArticleNumber: string;
  manufacturerName?: string;
  amount: number;
  description: string;
  expectedAmount?: number;
}

interface Props {
  instrument: InstrumentDTO;
}

const InstrumentDetailForm = observer(({ instrument }: Props) => {
  const { flyoutStore, instrumentStore } = useStores();
  const { t } = useTranslation('instruments');
  const onSubmit = (values: InstrumentDetailFormValues) => {
    if (!values || Object.keys(values).length === 0) return;
    if (values.materialName) {
      instrumentStore.updateMaterial({ materialId: instrument.material.materialId, name: values.materialName });
    }
  };

  const initialValues: InstrumentDetailFormValues = {
    materialName: instrument.material.name,
    lastSynchronisation: instrument.lastSynchronisation || '',
    manufacturerArticleNumber: instrument.material.manufacturerArticleNumber || '',
    manufacturerName: instrument.material.manufacturerName,
    amount: instrument.amount,
    description: instrument.description || '',
    expectedAmount: instrument.expectedAmount
  };

  const onUpdateFile = async (fileId: string) => {
    await instrumentStore.updateMaterial({ materialId: instrument.material.materialId, pictureFileId: fileId });
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  useEffect(() => {
    const handleSubmitMyForm = () => {
      if (submitMyForm) submitMyForm();
    };
    return () => {
      handleSubmitMyForm();
    };
  });

  const handleAddImagesToView = () => {
    if (instrument.material.picture) {
      flyoutStore.setImagesToView(instrument.material.picture);
    }
  };

  return (
    <div className="single_colum_content">
      <div className="div-block-45">
        <div className="stamm_big_image_wrapper">
          <div className="div-block-101">
            <div className="image_post_wide in_data" onClick={handleAddImagesToView}>
              {instrument.material.picture ? (
                <Picture alt="" src={instrument.material.picture} />
              ) : (
                <GetCameraImageButton buttonStyle="detailForm" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
              )}
            </div>
            <GetCameraImageButton
              isButtonVisible
              apiCall={savePackageFile}
              description={instrument.material.pictureFileId ? t('packageDetail.button.toChange') : t('packageDetail.button.toAdd')}
              onUpdateFile={onUpdateFile}
              buttonStyle="square"
              picturesOnly
              isAddable
            />
          </div>
        </div>
      </div>
      <div className="div-block-44">
        <Form initialValues={initialValues} bindSubmitForm={bindSubmitForm} onSubmit={onSubmit}>
          {() => (
            <>
              <div className="content_field">
                <div>{t('packageDetail.label.materialName')}</div>
                <InputField name="materialName" placeholder={t('packageDetail.label.materialName')} />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.lastSynchronisation')}</div>
                <InputField name="lastSynchronisation" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.manufacturerArticleNumber')}</div>
                <InputField name="manufacturerArticleNumber" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.manufacturerName')}</div>
                <InputField name="manufacturerName" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.amount')}</div>
                <InputField name="amount" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.other')}</div>
                <InputField name="description" placeholder={t('packageDetail.label.other')} viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.expectedAmount')}</div>
                <InputField name="expectedAmount" viewOnly />
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
});

export default InstrumentDetailForm;
