import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useStores } from 'util/mobx/stores';
import { GroupedMaterialKnowledgeDTO } from 'dto/materialKnowledge';
import { ChapterType } from 'dto/materialChapter';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { useTranslation } from 'react-i18next';
import CollapsibleBox from 'components/CollapsibleBox';
import { IconType } from 'dto/procedure';
import { MaterialLikeIdOrPackageIdDTO } from 'dto/material';
import KnowledgeElements from './KnowledgeElements';

interface Props {
  isCollapsible?: boolean;
  materialLikeId: MaterialLikeIdOrPackageIdDTO;
  onCreateSynonym?: () => void;
}

const isPreviousElementHasKnowledgeElements = (materialKnowledgeElements: GroupedMaterialKnowledgeDTO[], index: number) => {
  if (index === 0) {
    return false;
  }
  if (!materialKnowledgeElements[index - 1]) {
    return false;
  }
  if (materialKnowledgeElements[index - 1] && materialKnowledgeElements[index - 1].knowledgeElements.length === 0) {
    return false;
  }
  return true;
};

const MaterialKnowledge = observer(({ materialLikeId, isCollapsible = false, onCreateSynonym }: Props) => {
  const { t } = useTranslation('material');
  const { knowledgeStore } = useStores();

  useEffect(() => {
    knowledgeStore.loadMaterialKnowledge(materialLikeId);
  }, [knowledgeStore, materialLikeId]);

  /**
   * @param position if undefined, it is added at the end
   */
  const handleCreate = (materialKnowledge: GroupedMaterialKnowledgeDTO, position?: number) => {
    if (materialKnowledge.chapterType === ChapterType.Synonym) {
      if (onCreateSynonym) {
        onCreateSynonym();
      } else {
        knowledgeStore.setIsCreateMaterialSynonymOpen(true);
      }
    } else {
      knowledgeStore.setIsMaterialKnowledgeFormFlyoutOpen(true);
      if (materialKnowledge.materialChapterId) {
        knowledgeStore.setDataForNewMaterialKnowledge(materialKnowledge.materialChapterId, position);
      }
    }
  };

  const onDragEnd = (result: DropResult) => {
    knowledgeStore.reorder(result);
  };

  // Drawer
  if (isCollapsible) {
    return (
      <>
        {knowledgeStore.materialKnowledge.map((materialKnowledge: GroupedMaterialKnowledgeDTO) => {
          if (materialKnowledge.chapterType === ChapterType.StorageLocation) {
            return null; // TODO: not yet implemented
          }
          if (materialKnowledge.knowledgeElements.length > 0 || materialKnowledge.chapterType === ChapterType.Synonym) {
            return (
              <div key={materialKnowledge.materialChapterId} className="post_item_head_topic">
                {materialKnowledge.chapterType === ChapterType.Synonym ||
                materialKnowledge.chapterType === ChapterType.ProductInformation ? (
                  <CollapsibleBox
                    head={
                      <>
                        <div>{materialKnowledge.materialChapter}</div>
                      </>
                    }
                  >
                    <>
                      {materialKnowledge.chapterType === ChapterType.Synonym && (
                        <ListItemAdd className="post_item_add post-item-add" onClick={() => handleCreate(materialKnowledge)}>
                          <InfoBox label={t('content.infoBoxSynonym')} />
                        </ListItemAdd>
                      )}
                    </>
                    <KnowledgeElements
                      materialKnowledge={materialKnowledge}
                      onCreate={position => handleCreate(materialKnowledge, position)}
                      isCollapsible
                    />
                  </CollapsibleBox>
                ) : (
                  <CollapsibleBox
                    isExpandedByDefault
                    head={
                      <>
                        <div>{materialKnowledge.materialChapter}</div>
                      </>
                    }
                  >
                    <KnowledgeElements
                      materialKnowledge={materialKnowledge}
                      onCreate={position => handleCreate(materialKnowledge, position)}
                      isCollapsible
                    />
                  </CollapsibleBox>
                )}
              </div>
            );
          }
          return null;
        })}
      </>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {knowledgeStore.materialKnowledge.map((materialKnowledge: GroupedMaterialKnowledgeDTO, index) => {
        if (materialKnowledge.chapterType !== ChapterType.Synonym && materialKnowledge.chapterType !== ChapterType.Knowledge) {
          return null;
        }
        return (
          <Droppable droppableId={materialKnowledge.materialChapterId} key={materialKnowledge.materialChapterId}>
            {provided => (
              <div ref={provided.innerRef}>
                <div id={materialKnowledge.materialChapterId}>
                  <div
                    className={`h1 ${isPreviousElementHasKnowledgeElements(knowledgeStore.materialKnowledge, index) ? '' : 'top-margin'}`}
                  >
                    {materialKnowledge.materialChapter}
                  </div>
                  <KnowledgeElements
                    materialKnowledge={materialKnowledge}
                    listItemAddButtons
                    iconType={IconType.UserPicture}
                    onCreate={position => handleCreate(materialKnowledge, position)}
                  />
                  {provided.placeholder}
                  {materialKnowledge.knowledgeElements.length < 1 && materialKnowledge.chapterType !== ChapterType.StorageLocation && (
                    <ListItemAdd className="post_item_add post-item-add" onClick={() => handleCreate(materialKnowledge)}>
                      <InfoBox
                        label={materialKnowledge.chapterType === ChapterType.Synonym ? t('content.infoBoxSynonym') : t('content.infoBox')}
                      />
                    </ListItemAdd>
                  )}
                </div>
              </div>
            )}
          </Droppable>
        );
      })}
    </DragDropContext>
  );
});

export default MaterialKnowledge;
