import React from 'react';

interface Props extends React.HTMLProps<HTMLInputElement> {
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  containerClassName?: string;
  noMarginTop?: boolean;
  isSearchInput?: boolean;
}

const CustomInput = ({
  placeholder,
  onChange,
  onBlur,
  value,
  containerClassName = '',
  noMarginTop,
  type = 'text',
  isSearchInput = false,
  ...rest
}: Props) => {
  return (
    <div className={containerClassName || 'input_field'} style={{ marginTop: noMarginTop ? '0px' : '20px' }}>
      {isSearchInput && <img src="images/icon_magnifier_16_blue.svg" alt="" className="image_search" />}
      {value ? (
        <input
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          style={{ width: '100%', outline: 'none', border: '0', color: 'black' }}
          value={value}
          maxLength={255}
          type={type}
          {...rest}
        />
      ) : (
        <input
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          style={{ width: '100%', outline: 'none', border: '0', color: 'black' }}
          maxLength={255}
          type={type}
          {...rest}
        />
      )}
      {!isSearchInput && (
        <img src="images/icon_s-edit_16.svg" style={{ filter: 'saturate(0%) brightness(167%)' }} alt="" className="image_write" />
      )}
    </div>
  );
};
export default CustomInput;
