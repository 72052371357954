import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';

import CustomInput from 'components/CustomInput';
import FlyoutContainer from 'components/FlyoutContainer';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

export default observer(({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('guide');
  const { t: materialT } = useTranslation('materialSynonym');
  const { animationStore, knowledgeStore } = useStores();
  const [synonymName, setSynonymName] = useState('');
  const closeFlyout = () => {
    if (onClose) {
      onClose();
    } else {
      knowledgeStore.setIsCreateMaterialSynonymOpen(false);
    }

    setSynonymName('');
  };

  const onSubmit = async () => {
    const synonym = await knowledgeStore.createMaterialSynonym({
      materialId: knowledgeStore.selectedMaterialLikeId?.materialId,
      materialSetId: knowledgeStore.selectedMaterialLikeId?.materialSetId,
      packageId: knowledgeStore.selectedMaterialLikeId?.packageId,
      name: synonymName
    });
    closeFlyout();
    if (synonym) {
      animationStore.setElementIdToShake([synonym.id]);
    }
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_tag.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={isOpen === undefined ? knowledgeStore.isCreateMaterialSynonymOpen : isOpen}
      closePopUp={closeFlyout}
      cancelLabel={t('updateGuide.button.cancel')}
      onSubmit={onSubmit}
      submitLabel={t('updateGuide.button.submit')}
      isAllowedToSubmit={synonymName !== ''}
      title={materialT('createSynonym.headline')}
    >
      <div className="flyout_box">
        <div>{materialT('createSynonym.label.synonymName')}</div>
        <CustomInput
          value={synonymName}
          onChange={event => setSynonymName(event.target.value)}
          placeholder={materialT('createSynonym.label.synonymNamePlaceholder')}
        />
      </div>
    </FlyoutContainer>
  );
});
