import React from 'react';

interface Props extends React.HTMLProps<HTMLDivElement> {
  title: string;
  value?: string;
  classNameTitle?: string;
  classNameItem?: string;
  classNameValue?: string;
}

const ValueTag = ({ title, value, classNameTitle, classNameItem, classNameValue, ...rest }: Props) => {
  if (!value) {
    return null;
  }
  return (
    <div className={`item_number ${classNameItem || ''}`} {...rest}>
      <div className={`txt_number_type ${classNameTitle || ''}`}>{title}</div>
      <div className={`tag-value ${classNameValue || ''}`}>{value}</div>
    </div>
  );
};

export default ValueTag;
