/**
 * NEVER REMOVE ANY CODE, else the numbers behind them would change.
 * NEVER REUSE CODES, each code should only be used once
 *
 * Just put new codes at the end if you need a new one!
 */
export enum ErrorCode {
  UnknownError,
  FrontendValidationError,
  Error2,
  Error3,
  Error4,
  Error5,
  Error6,
  Error7,
  Error8,
  Error9,
  Error10,
  Error11,
  Error12,
  Error13,
  Error14,
  Error15,
  Error16,
  Error17,
  Error18,
  Error19,
  Error20,
  Error21,
  Error22,
  Error23,
  Error24,
  Error25,
  Error26,
  Error27,
  Error28,
  Error29,
  Error30,
  Error31,
  Error32,
  Error33,
  Error34,
  Error35,
  Error36,
  Error37,
  Error38,
  Error39,
  Error40,
  Error41,
  Error42,
  Error43,
  Error44,
  Error45,
  Error46,
  Error47,
  Error48,
  Error49,
  Error50,
  Error51,
  Error52,
  Error53,
  Error54,
  Error55,
  Error56,
  Error57,
  Error58,
  Error59,
  Error60,
  Error61,
  Error62,
  Error63,
  Error64,
  Error65,
  Error66,
  Error67,
  Error68,
  Error69,
  Error70,
  Error71,
  Error72,
  Error73,
  Error74,
  Error75,
  Error76,
  Error77,
  Error78,
  Error79,
  Error80,
  Error81,
  Error82,
  Error83,
  Error84,
  Error85,
  Error86,
  Error87,
  Error88,
  Error89,
  Error90,
  Error91,
  Error92,
  Error93,
  Error94,
  Error95,
  Error96,
  Error97,
  Error98,
  Error99,
  Error100,
  Error101,
  Error102,
  Error103,
  Error104,
  Error105,
  Error106,
  Error107,
  Error108,
  Error109,
  Error110,
  Error111,
  Error112,
  Error113,
  Error114,
  Error115,
  Error116,
  Error117,
  Error118,
  Error119,
  Error120,
  Error121,
  Error122,
  Error123,
  Error124,
  Error125,
  Error126,
  Error127,
  Error128,
  Error129,
  Error130,
  Error131,
  Error132,
  Error133,
  Error134,
  Error135,
  Error136,
  Error137,
  Error138,
  Error139,
  Error140,
  Error141,
  Error142,
  Error143,
  Error144,
  Error145,
  Error146,
  Error147,
  Error148,
  Error149,
  Error150,
  Error151,
  Error152,
  Error153,
  Error154,
  Error155,
  Error156,
  Error157,
  Error158,
  Error159,
  Error160,
  Error161,
  Error162,
  Error163,
  Error164,
  Error165,
  Error166,
  Error167,
  Error168,
  Error169,
  Error170,
  Error171,
  Error172,
  Error173,
  Error174,
  Error175,
  Error176,
  Error177,
  Error178,
  Error179,
  Error180,
  Error181,
  Error182,
  Error183,
  Error184,
  Error185,
  Error186,
  Error187,
  Error188,
  Error189,
  Error190,
  Error191,
  Error192,
  Error193,
  Error194,
  Error195,
  Error196,
  Error197,
  Error198,
  Error199,
  Error200,
  Error201,
  Error202,
  Error203,
  Error204,
  Error205,
  Error206,
  Error207,
  Error208,
  Error209,
  Error210,
  Error211,
  Error212,
  Error213,
  Error214,
  Error215,
  Error216,
  Error217,
  Error218,
  Error219,
  Error220,
  Error221,
  Error222,
  Error223,
  Error224,
  Error225,
  Error226,
  Error227,
  Error228,
  Error229,
  Error230,
  Error231,
  Error232,
  Error233,
  Error234,
  Error235,
  Error236,
  Error237,
  Error238,
  Error239,
  Error240,
  Error241,
  Error242,
  Error243,
  Error244,
  Error245,
  Error246,
  Error247,
  Error248,
  Error249,
  Error250,
  Error251,
  Error252,
  Error253,
  Error254,
  Error255,
  Error256,
  Error257,
  Error258,
  Error259,
  Error260,
  Error261,
  Error262,
  Error263,
  Error264,
  Error265,
  Error266,
  Error267,
  Error268,
  Error269,
  Error270,
  Error271,
  Error272,
  Error273,
  Error274,
  Error275,
  Error276,
  Error277,
  Error278,
  Error279,
  Error280,
  Error281,
  Error282,
  Error283,
  Error284,
  Error285,
  Error286,
  Error287,
  Error288,
  Error289,
  Error290,
  Error291,
  Error292,
  Error293,
  Error294,
  Error295,
  Error296,
  Error297,
  Error298,
  Error299,
  Error300,
  Error301,
  Error302,
  Error303,
  Error304,
  Error305,
  Error306,
  Error307,
  Error308,
  Error309,
  Error310,
  Error311,
  Error312,
  Error313,
  Error314,
  Error315,
  Error316,
  Error317,
  Error318,
  Error319,
  Error320,
  Error322,
  Error323,
  Error324,
  Error325,
  Error326,
  Error327,
  Error328,
  Error329,
  Error330,
  Error331,
  Error332,
  Error333,
  Error334,
  Error335,
  Error336,
  Error337,
  Error338,
  Error339,
  Error340,
  Error341,
  Error342,
  Error343,
  Error344,
  Error345,
  Error346,
  Error347,
  Error348,
  Error349,
  Error350,
  Error351,
  Error352,
  Error353,
  Error354,
  Error355,
  Error356,
  Error357,
  Error358,
  Error359,
  Error360,
  Error361,
  Error362,
  Error363,
  Error364,
  Error365,
  Error366,
  Error367,
  Error368,
  Error369,
  Error370,
  Error371,
  Error372,
  Error373,
  Error374,
  Error375,
  Error376,
  Error377,
  Error378,
  Error379,
  Error380,
  Error381,
  Error382,
  Error383,
  Error384,
  Error385,
  Error386,
  Error387,
  Error388,
  Error389,
  Error390,
  Error391,
  Error392,
  Error393,
  Error394,
  Error395,
  Error396,
  Error397,
  Error398,
  Error399,
  Error400,
  Error401,
  Error402,
}
