import React from 'react';
import { observer } from 'mobx-react';

import GenderIcon from 'components/GenderIcon';
import { parseFromDate, dateFormat } from 'util/date';
import { useStores } from 'util/mobx/stores';

const BriefingBarHeader = observer(() => {
  const { surgeryGuideStore } = useStores();
  if (!surgeryGuideStore.briefing?.surgery) {
    return null;
  }
  const { surgery } = surgeryGuideStore.briefing;
  return (
    <div>
      {/* Add patient picture later */}
      <div className="title_info">
        <div>
          <strong>{surgeryGuideStore.briefing.surgery.name}</strong>
        </div>
        <div className="div-block-134">
          <div className="t2 top-margin">
            {surgery.name}, {surgery.lastName} *{parseFromDate(surgery.birthday)?.format(dateFormat)}{' '}
            <GenderIcon gender={surgery.gender} className="size_16 image-arrow-list" isGrey />
          </div>
        </div>
      </div>
    </div>
  );
});

export default BriefingBarHeader;
