import React from 'react';
import { MaterialStorageLocationDTO } from 'dto/storageLocation';
import MaterialValueTags from 'components/MaterialValueTags';
import Picture from 'components/Picture';

interface Props extends React.HTMLProps<HTMLDivElement> {
  materialStorageLocation: MaterialStorageLocationDTO;
  isSelected?: boolean;
  whiteBorder?: boolean;
  noArrow?: boolean;
}

const MaterialStorageLocationListItem = ({
  materialStorageLocation,
  isSelected = false,
  whiteBorder = false,
  noArrow = false,
  ...rest
}: Props) => {
  return (
    <div
      {...rest}
      className={`list_item_name storage-location-list-item lagerort image-border-container list-item ${isSelected ? 'selected' : ''} ${
        whiteBorder && !isSelected ? 'white' : ''
      }`}
    >
      <div className="image_wrapper_50">
        <div className="image_border image-border" />
        <Picture src={materialStorageLocation.picture} width={50} alt="" className="image_circle_50" />
      </div>
      <div className="item_name">{materialStorageLocation.name}</div>
      <MaterialValueTags materialStorageLocation={materialStorageLocation} />
      {!noArrow && <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />}
    </div>
  );
};

export default MaterialStorageLocationListItem;
