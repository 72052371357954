import React from 'react';
import { MaterialSetListItem } from 'components/ListItems';
import { MaterialSetDTO } from 'dto/materialSet';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import EmptyIndicator from 'components/EmptyIndicator';

interface Props {
  groups: MaterialSetDTO[];
  groupType: string;
}

const GroupsList = ({ groups, groupType }: Props) => {
  const { flyoutStore } = useStores();
  const { t } = useTranslation(groupType);
  return (
    <>
      <div className="list_item_book_add mobile-only" onClick={() => flyoutStore.setIsCreateMaterialSetFlyoutOpen(true)}>
        <div className="image_wrapper_50">
          <img src="images/icon_add.jpg" width="50" alt="" className="image_circle_50" />
        </div>
        <div className="info_box">
          <div>{t('rightMenu.newItem')}</div>
        </div>
      </div>
      <EmptyIndicator message={t('groupsList.emptyGroupInfo')} isEmpty={!groups.length}>
        {groups.map(materialSet => (
          <MaterialSetListItem materialSet={materialSet} key={materialSet.materialSetId} />
        ))}
      </EmptyIndicator>
    </>
  );
};

export default GroupsList;
