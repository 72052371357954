import React from 'react';
import { observer } from 'mobx-react';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { MaterialDTO } from 'dto/material';
import { useStores } from 'util/mobx/stores';

import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Picture from 'components/Picture';
import Popover from 'components/Popover';
import HoverWrapper from 'components/HoverWrapper';
import MaterialValueTags from 'components/MaterialValueTags';
import Instructions from '../components/Instructions';
import GuideMaterialListItemMenu from './GuideMaterialListItemMenu';

interface Props {
  material: MaterialDTO;
  guideData: GuideMaterialLikeDTO;
  listItemMaterialClass: string;
  isClone?: boolean;
}

const GuideMaterialListItem = observer(({ material, listItemMaterialClass, isClone, guideData }: Props) => {
  const { guideStore, materialStore, guideDetailDrawerStore } = useStores();

  const handleClick = (isPicture = false) => {
    if (guideStore.guideMaterialsToClone || isPicture || !guideData.material) return;
    guideDetailDrawerStore.setSelectedMaterial(guideData.material, guideData);
    guideDetailDrawerStore.setIsOpen(true);
  };

  const handleOpenDetailsGuideMaterial = () => handleClick();

  const handleDeleteGuideMaterialClick = () => {
    if (guideStore.selectedGuide) {
      guideStore.deleteGuideMaterial({ guideMaterialId: guideData.guideMaterialId }, guideStore.selectedGuide.guideId);
    }
  };
  const onUpdateFile = async (fileId: string) => {
    await materialStore.updateMaterial({ materialId: material.materialId, pictureFileId: fileId });
    if (guideStore.selectedGuide) {
      guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
  };

  return (
    <HoverWrapper className={`${listItemMaterialClass} image-border-container`}>
      {({ hover }) => (
        <>
          <div className="item_count _1_lvl">
            <div>{guideData.amount}</div>
          </div>
          <div className="item_dropdown_holder">
            <img src="images/icon_s-edit_17.svg" width={16} alt="" className="image_arrow_dropdown hidden" />
          </div>
          <div className="image_wrapper_50">
            {!guideData.storageLocationId && (
              <div className="icon_no_location">
                <img src="images/icon_orange_flag.jpg" alt="" />
              </div>
            )}
            {material.picture ? (
              <>
                <div className="image_border image-border" onClick={() => handleClick()} />
                <Picture src={material.pictureThumbnail} alt={material.name} className="image_circle_50" />
              </>
            ) : (
              <GetCameraImageButton
                buttonStyle="icon"
                onUpdateFile={onUpdateFile}
                description=""
                picturesOnly
                isAddable={!guideStore.selectedGuideCloneFlyout && true}
                hover={hover}
              />
            )}
          </div>
          <div className="material_info">
            <div className="material_text" onClick={() => handleClick()}>
              <div>{material.name}</div>
              <Instructions notes={guideData.notes} />
            </div>
            <MaterialValueTags material={material} onClick={() => handleClick()} />
            {!isClone && (
              <div className="btn_show_detail btn-detail-position">
                <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                  {({ handleClose, isOpen }) => (
                    <GuideMaterialListItemMenu
                      isOpen={!!isOpen}
                      handleClose={handleClose}
                      handleDeleteGuideMaterialClick={handleDeleteGuideMaterialClick}
                      handleOpenDetailsGuideMaterial={handleOpenDetailsGuideMaterial}
                    />
                  )}
                </Popover>
              </div>
            )}
          </div>
        </>
      )}
    </HoverWrapper>
  );
});

export default GuideMaterialListItem;
