import React from 'react';
import { observer } from 'mobx-react';
import { MaterialKnowledgeDTO, GroupedMaterialKnowledgeDTO } from 'dto/materialKnowledge';
import { ChapterType } from 'dto/materialChapter';
import PostItem from 'components/PostItem';
import { useStores } from 'util/mobx/stores';
import { IconType } from 'dto/procedure';
import MaterialKnowledgeMenu from '../MaterialKnowledgeMenu';

interface Props {
  knowledgeElement: MaterialKnowledgeDTO;
  materialKnowledge: GroupedMaterialKnowledgeDTO;
  isCollapsible: boolean;
  iconType?: IconType;
}

const KnowledgeElementItem = observer(({ knowledgeElement, materialKnowledge, isCollapsible, iconType }: Props) => {
  const { knowledgeStore } = useStores();
  const handleDeleteSynonym = async (materialSynonymId?: string) => {
    if (materialSynonymId) {
      knowledgeStore.deleteMaterialSynonym(materialSynonymId);
    }
  };

  const handleUpdateMaterialKnowledgeFormFlyoutOpen = (data: MaterialKnowledgeDTO) => {
    knowledgeStore.setSelectedKnowledge(data);
    knowledgeStore.setIsMaterialKnowledgeFormFlyoutOpen(true);
  };

  const handleDeleteKnowledgeElement = (materialKnowledgeElementId?: string) => {
    if (materialKnowledgeElementId) {
      knowledgeStore.deleteKnowledgeElement(materialKnowledgeElementId);
    }
  };

  let newIconType = !isCollapsible ? IconType.MaterialPicture : IconType.UserPicture;
  if (iconType) {
    newIconType = iconType;
  }

  if (materialKnowledge.chapterType === ChapterType.Synonym) {
    return (
      <PostItem
        key={knowledgeElement.materialSynonymId}
        title={knowledgeElement.content.title}
        user={knowledgeElement.content.user}
        menuClassName={isCollapsible ? 'material_detail' : 'knowledge_element'}
        iconType={newIconType}
        menu={
          !isCollapsible && (
            <MaterialKnowledgeMenu
              materialKnowledge={materialKnowledge}
              onDeleteClick={() => handleDeleteSynonym(knowledgeElement?.materialSynonymId)}
            />
          )
        }
      >
        <div>{knowledgeElement.content.description}</div>
      </PostItem>
    );
  }

  return (
    <PostItem
      key={knowledgeElement.materialKnowledgeElementId}
      title={knowledgeElement.content.title}
      user={knowledgeElement.content.user}
      files={knowledgeElement.content.contentElementMedias}
      iconType={newIconType}
      menuClassName={isCollapsible ? 'material_detail' : 'knowledge_element'}
      withUserInfoBoxLeft
      menu={
        !isCollapsible && (
          <MaterialKnowledgeMenu
            materialKnowledge={materialKnowledge}
            onEditClick={() => handleUpdateMaterialKnowledgeFormFlyoutOpen(knowledgeElement)}
            onDeleteClick={() => handleDeleteKnowledgeElement(knowledgeElement?.materialKnowledgeElementId)}
          />
        )
      }
    >
      <>{knowledgeElement.content.description}</>
    </PostItem>
  );
});

export default KnowledgeElementItem;
