import { observable, action } from 'mobx';

export default class LoadingStore {
  @observable
  isLoading = false;

  @observable
  isFlyoutLoading = false;

  @observable
  withOverlay = true;

  @action
  async withLoadingBar<T>(apiCall: () => Promise<T>): Promise<T> {
    this.setIsLoading(true);
    this.setWithOverlay(true);
    const response = apiCall();

    return response
      .then(res => {
        this.setIsLoading(false);
        this.setWithOverlay(false);
        return res;
      })
      .catch(err => {
        this.setIsLoading(false);
        this.setWithOverlay(false);
        throw err;
      });
  }

  /**
   * withOnlyLoadingBar enables only the loading bar but not the overlay.
   * @param apiCall
   */
  @action
  async withOnlyLoadingBar<T>(apiCall: () => Promise<T>): Promise<T> {
    this.setIsLoading(true);
    this.setWithOverlay(false);
    const response = apiCall();

    return response
      .then(res => {
        this.setIsLoading(false);
        return res;
      })
      .catch(err => {
        this.setIsLoading(false);
        throw err;
      });
  }

  @action
  async withFlyoutLoadingBar<T>(apiCall: () => Promise<T>): Promise<T> {
    this.setIsFlyoutLoading(true);
    this.setWithOverlay(true);

    const response = apiCall();

    return response
      .then(res => {
        this.setIsFlyoutLoading(false);
        this.setWithOverlay(false);
        return res;
      })
      .catch(err => {
        this.setIsFlyoutLoading(false);
        this.setWithOverlay(false);
        throw err;
      });
  }

  @action
  private setIsLoading = (status: boolean) => {
    this.isLoading = status;
  };

  @action
  private setIsFlyoutLoading = (status: boolean) => {
    this.isFlyoutLoading = status;
  };

  @action
  private setWithOverlay = (status: boolean) => {
    this.withOverlay = status;
  };
}
