import React from 'react';
import { SurgeryDTO } from 'dto/surgery';
import { parseFromDate, dateFormat } from 'util/date';
import GenderIcon from 'components/GenderIcon';

interface Props {
  patient: SurgeryDTO;
}

const PatientHead = ({ patient }: Props) => {
  return (
    <div className="div-block-124">
      <div>
        {patient.lastName}{patient.lastName && patient.firstName && <>,</>} {patient.firstName} {!!patient.birthday && <>*{parseFromDate(patient.birthday)?.format(dateFormat)}</>}
        <GenderIcon className="size_16" gender={patient.gender} />
      </div>
    </div>
  );
};

export default PatientHead;
