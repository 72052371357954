import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialLikeStorageLocations from 'components/MaterialLikeStorageLocations';
import MaterialStorageLocationsRightMenu from './MaterialStorageLocationsRightMenu';

interface Props {
  materialId: string;
}

const MaterialStorageLocations = observer(({ materialId }: Props) => {
  const { appNavigationStore, storageLocationStore } = useStores();

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <MaterialStorageLocationsRightMenu />;
    });
  }, [appNavigationStore]);

  useEffect(() => {
    return () => {
      storageLocationStore.refreshStore();
    };
  });

  appNavigationStore.useSubPageIdSetter('locations');

  return <MaterialLikeStorageLocations materialLikeOrPackageId={{ materialId }} />;
});

export default MaterialStorageLocations;
