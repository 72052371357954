import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import OverviewBox from 'components/OverviewBox';
import MaterialOverviewRightMenu from './MaterialOverviewRightMenu';

interface Props {
  materialId: string;
}

const MaterialOverview = observer(({ materialId }: Props) => {
  const { t } = useTranslation('material');
  const { appNavigationStore } = useStores();
  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <MaterialOverviewRightMenu />;
    });
  }, [appNavigationStore]);
  const { materialStore } = useStores();

  appNavigationStore.useSubPageIdSetter('overview');

  return (
    <div className="single_colum_content no_padding_right">
      <div className="div-block-48">
        <OverviewBox
          link={`/material/detail?materialId=${materialId}`}
          name={t('overview.baseData.name')}
          text={t('overview.baseData.textHtml')}
          btnText={t('overview.baseData.btnText')}
          image="data-table_16"
        />
        <OverviewBox
          link={`/material/content?materialId=${materialId}`}
          name={t('overview.knowledge.name')}
          text={t('overview.knowledge.textHtml')}
          btnText={t('overview.knowledge.btnText')}
          image="bulb-62_16"
        />
        <OverviewBox
          link={`/material/functions?materialId=${materialId}`}
          name={t('overview.globalFunctions.name')}
          text={t('overview.globalFunctions.textHtml')}
          btnText={t('overview.globalFunctions.btnText')}
          image="globe_16"
        />

        <OverviewBox
          link={`/material/locations?materialId=${materialId}`}
          name={t('overview.storageLocation.name')}
          text={t('overview.storageLocation.textHtml')}
          btnText={t('overview.storageLocation.btnText')}
          image="pin-3_16"
        />
        {materialStore.materialDetail && materialStore.materialDetail.user && (
          <OverviewBox
            link={`/material/overview?materialId=${materialId}`}
            name={t('overview.history.name')}
            text={t('overview.history.textPartOne')}
            additionText={t('overview.history.textPartTwo')}
            image="icon_time-clock_16_grey"
            user={materialStore.materialDetail.user}
            createdDate={materialStore.materialDetail.createdAt}
          />
        )}
      </div>
    </div>
  );
});

export default MaterialOverview;
