import React from 'react';
import { observer } from 'mobx-react';
import Picture from 'components/Picture';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { useStores } from 'util/mobx/stores';
import { GroupMaterialDTO } from 'dto/groupMaterial';
import HoverWrapper from 'components/HoverWrapper';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Tags from '../components/Tags';
import GuideGroupMaterialListItemMenu from './GuideGroupMaterialListItemMenu';
import './GroupMaterialListItem.css';

interface Props {
  groupMaterial: GroupMaterialDTO;
  guideData?: GuideMaterialLikeDTO;
  hideTags?: boolean;
  isClone?: boolean;
}

const GroupMaterialListItem = observer(({ groupMaterial, guideData, hideTags = false, isClone = false }: Props) => {
  const { guideDetailDrawerStore, materialStore, guideStore, materialSetsStore } = useStores();

  const onOpenMaterialSetDetails = () => {
    if (!isClone) {
      guideDetailDrawerStore.setSelectedMaterial(groupMaterial.material, guideData, true);
      guideDetailDrawerStore.setIsOpen(true);
    }
  };

  const onUpdateFile = async (fileId: string) => {
    await materialStore.updateMaterial({ materialId: groupMaterial.material.materialId, pictureFileId: fileId });
    await materialSetsStore.refreshMaterialSets();
    if (guideStore.selectedGuide) {
      guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
  };
  return (
    <HoverWrapper className={`list_item_material margin-right image-border-container ${guideData ? '' : 'no_hover'}`}>
      {({ hover }) => (
        <>
          <div className="item_count" onClick={guideData && onOpenMaterialSetDetails} />
          <div className="image_wrapper_50">
            {groupMaterial.material.picture ? (
              <Picture src={groupMaterial.material.picture} width={50} alt="" className="image_circle_50" />
            ) : (
              <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
            )}
          </div>

          <div className={`material_info group-material-info ${guideData ? '' : 'no_hover'}`}>
            <div className={`material_text ${guideData ? '' : 'no_hover'}`} onClick={guideData && onOpenMaterialSetDetails}>
              <div>{groupMaterial.material.name}</div>
              {!hideTags ? <Tags tags={groupMaterial.tags} /> : <></>}
            </div>
            <MaterialValueTags material={groupMaterial.material} />
            {guideData && !isClone && (
              <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                {({ handleClose, isOpen }) => (
                  <GuideGroupMaterialListItemMenu
                    handleClose={handleClose}
                    isOpen={!!isOpen}
                    handleOpenDetails={onOpenMaterialSetDetails}
                  />
                )}
              </Popover>
            )}
          </div>
        </>
      )}
    </HoverWrapper>
  );
});

export default GroupMaterialListItem;
