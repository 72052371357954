import React from 'react';
import Document from 'components/Document';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';

interface Props {
  file: ContentElementMediaDTO;
  inDrawer?: boolean;
}

export default ({ file, inDrawer }: Props) => {
  return (
    <>
      <div className="data_file">
        <Document file={file} type={file.contentType} title={inDrawer ? undefined : file.title} showDownloadButton />
      </div>
    </>
  );
};
