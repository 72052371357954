import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { MaterialSearchResultDTO } from 'dto/material';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { MaterialListItem } from 'components/ListItems';
import './MaterialList.css';
import EmptyIndicator from 'components/EmptyIndicator';

interface Props {
  materials: MaterialSearchResultDTO[];
}

const MaterialList = observer(({ materials }: Props) => {
  const { flyoutStore } = useStores();
  const { t } = useTranslation('guideSingleMaterial');

  const handleCreateMaterial = () => {
    flyoutStore.setIsCreateSingleMaterialFlyoutOpen(true);
  };

  return (
    <>
      <ListItemAdd className="list_item_material_add no-padding mobile-only " onClick={handleCreateMaterial}>
        <InfoBox label={t('button.createMaterial')} />
      </ListItemAdd>
      <EmptyIndicator message={t('emptyMaterialsInfo')} isEmpty={!materials.length}>
        {materials.map((materialSearchResult, i) => {
          if (materialSearchResult.material) {
            return (
              <MaterialListItem
                // here is no other way to get a more unique key
                // eslint-disable-next-line react/no-array-index-key
                key={materialSearchResult.material.materialId + i}
                material={materialSearchResult.material}
                routePath={`/material/overview?materialId=${materialSearchResult.material.materialId}`}
              />
            );
          }
          return <></>;
        })}
      </EmptyIndicator>
    </>
  );
});

export default MaterialList;
