/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, IsString, ValidateNested, IsNotEmpty, IsOptional, IsEnum } from 'class-validator';
import { Type } from 'class-transformer';
import { InstrumentDTO } from './instrument';
import { SimpleTemplateDTO } from './template';
import { PictureDTO } from './file';

export enum PackagingType {
  Single, // The packages always contain only one material.
  Multi // The packages can contain several materials.
}

export class LotNumberDTO {
  @IsString()
  lotNumber!: string;

  @IsOptional()
  @IsString()
  note?: string;
}

export class SimplePackageDTO {
  @IsUUID()
  packageId!: string;

  @IsOptional()
  @Type(() => PictureDTO)
  picture?: PictureDTO;

  @IsString()
  name!: string;

  @IsEnum(PackagingType)
  packagingType!: PackagingType;

  /**
   * serialNumber is called "set. ident. nr."
   */
  @IsString()
  serialNumber!: string;

  @IsUUID()
  templateId!: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsString()
  manufacturerName?: string;

  @IsString()
  @IsOptional()
  lastSynchronisation?: string;
}

export class PackageDTO extends SimplePackageDTO {
  @ValidateNested({ each: true })
  @IsNotEmpty()
  @Type(() => InstrumentDTO)
  instruments!: InstrumentDTO[];

  @ValidateNested()
  @IsOptional()
  @Type(() => LotNumberDTO)
  lotNumber?: LotNumberDTO;

  @ValidateNested({ each: true })
  @IsNotEmpty()
  @Type(() => PictureDTO)
  pictures!: PictureDTO[];
}

export class FullPackageDTO {
  @IsNotEmpty()
  @Type(() => SimpleTemplateDTO)
  template!: SimpleTemplateDTO;

  @IsNotEmpty()
  @Type(() => PackageDTO)
  pack!: PackageDTO;
}

export class UpdatePackageDTO {
  @IsUUID()
  packageId!: string;

  @IsString()
  @IsOptional()
  packageName?: string;

  @IsUUID(undefined, { each: true })
  @IsOptional()
  pictureFileIds?: string[];
}

export class SetImportPackageDTO {
  @IsString()
  name!: string;

  @IsString()
  serialNumber!: string;

  @IsString()
  templateNumber!: string;

  @IsString()
  @IsOptional()
  storageLocation?: string;
}
