import React, { useState, useEffect } from 'react';
import { useSecureURL } from 'util/hooks';

const defaultImage = 'images/icon_book_makephoto.jpg';
const errorImage = 'images/icon_book_missing_photo.jpg';
interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  altSrc?: string;
}

const Picture = ({ src = defaultImage, ...props }: Props) => {
  const [currentSrc, setCurrentSrc] = useState(src);
  useEffect(() => {
    setCurrentSrc(src);
  }, [src]);

  const onError = () => {
    if (props.altSrc) {
      setCurrentSrc(props.altSrc);
    } else {
      setCurrentSrc(errorImage);
    }
  };

  // remove altSrc prop from the dom-img tag
  const imgProps = props;
  delete imgProps.altSrc;

  const url = useSecureURL(currentSrc);

  return url ? (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img {...imgProps} src={url} onError={onError} />
  ) : (
    <></>
  );
};

export default Picture;
