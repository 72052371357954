/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsUUID, IsString, IsOptional, IsEnum } from 'class-validator';
import { ContentType, ProcessingStatus } from './file';

export class PictureLabellingDTO {
  @IsString()
  @IsOptional()
  pictureLabelling?: string;

  /**
   * pictureLabellingUrl contains the url of the picture which
   * is the already rendered labels on the picture.
   */
  @IsString()
  @IsOptional()
  pictureLabellingUrl?: string;
}

export class UpdatePictureLabellingDTO {
  @IsString()
  @IsOptional()
  pictureLabelling?: string;

  /**
   * pictureLabellingFileId contains the fileId of the picture which
   * is the already rendered labels on the picture.
   */
  @IsUUID()
  @IsOptional()
  pictureLabellingFileId?: string;
}

export class UpdateSortContentElementMediaDTO {
  @IsUUID(undefined, { each: true })
  contentElementMediaIds!: string[];
}

export class CreateContentElementMediaDTO extends UpdatePictureLabellingDTO {
  @IsUUID()
  fileId!: string;

  @IsOptional()
  @IsString()
  title?: string;
}

export class OptionalContentElementMediaDTO extends UpdatePictureLabellingDTO {
  @IsUUID()
  fileId!: string;

  @IsOptional()
  @IsString()
  title?: string;
}

export class ContentElementMediaDTO extends PictureLabellingDTO {
  @IsUUID()
  fileId!: string;

  @IsString()
  originalFileName!: string;

  @IsString()
  @IsOptional()
  title?: string;

  @IsOptional()
  @IsUUID()
  contentElementMediaId?: string;

  @IsEnum(ContentType)
  contentType!: ContentType;

  // TODO: IsURL doesn't work
  @IsString()
  fullUrl!: string;

  // TODO: IsURL doesn't work
  @IsOptional()
  @IsString()
  thumbnailUrl?: string;

  @IsOptional()
  @IsString()
  fileExtension?: string;

  @IsEnum(ProcessingStatus)
  status!: ProcessingStatus;
}
