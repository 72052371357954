import React, { useCallback } from 'react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import SideMenuIconButton from 'components/SideMenuIconButton';

const SingleInstrumentContentRightMenu = observer(() => {
  const { t } = useTranslation('singleInstruments');

  const { knowledgeStore, domainStore } = useStores();

  const handleCreateFlyoutOpen = useCallback(() => {
    knowledgeStore.setDataForNewMaterialKnowledge(undefined, 0);
    knowledgeStore.setIsMaterialKnowledgeFormFlyoutOpen(true);
  }, [knowledgeStore]);

  return (
    <>
      {!domainStore.isMobile && (
        <SideMenuIconButton
          handleClick={() => handleCreateFlyoutOpen()}
          label={t('content.rightMenu.addNewKnowledge')}
          iconPath="icon_f-add_16.svg"
        />
      )}
      {!domainStore.isMobile && (
        <SideMenuIconButton
          handleClick={() => knowledgeStore.setIsCreateMaterialSynonymOpen(true)}
          label={t('content.rightMenu.newSynonym')}
          iconPath="icon_f-add_16.svg"
        />
      )}
    </>
  );
});

export default SingleInstrumentContentRightMenu;
