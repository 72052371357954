/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import InstrumentDetailRightMenu from './InstrumentDetailRightMenu';
import InstrumentDetailForm from './InstrumentDetailForm';

const InstrumentDetail = observer(() => {
  const { instrumentStore, appNavigationStore } = useStores();

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <InstrumentDetailRightMenu />;
    });
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('detail');

  if (instrumentStore.instrument) {
    return <InstrumentDetailForm instrument={instrumentStore.instrument} />;
  }
  return null;
});

export default InstrumentDetail;
