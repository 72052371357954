import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onAddTagInputBlur: (tagName: string) => void;
}

const AddTag = ({ onAddTagInputBlur }: Props) => {
  const { t } = useTranslation('tags');
  const [isInputInFocus, setIsInputInFocus] = useState(false);
  const [tagName, setTagName] = useState('');
  const onInputChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setTagName(target.value);
  };

  const onTagAdd = (name: string) => {
    if (name) {
      onAddTagInputBlur(name);
    }
    setTagName('');
    setIsInputInFocus(false);
  };

  const onFocusIn = () => setIsInputInFocus(true);
  const onFocusOut = async () => {
    onTagAdd(tagName);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      onTagAdd(tagName);
    }
  };

  const onInputClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
  };

  return (
    <div className={`material_note_add add-new-tag ${isInputInFocus ? 'in-focus' : ''}`}>
      <input
        onClick={onInputClick}
        value={tagName}
        onChange={onInputChange}
        onFocus={onFocusIn}
        onBlur={onFocusOut}
        type="text"
        className="txt_add"
        placeholder={t('createNew')}
        onKeyDown={onKeyDown}
      />
      <div className="div-block-109">
        <img src="images/icon_c-add_12.svg" alt="" />
      </div>
    </div>
  );
};

export default AddTag;
