import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialLikeStorageLocations from 'components/MaterialLikeStorageLocations';
import MaterialSetStorageLocationsRightMenu from './MaterialSetStorageLocationsRightMenu';

interface Props {
  materialSetId: string;
}

const MaterialSetStorageLocations = observer(({ materialSetId }: Props) => {
  const { appNavigationStore, storageLocationStore } = useStores();

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <MaterialSetStorageLocationsRightMenu />;
    });
  }, [appNavigationStore]);

  useEffect(() => {
    return () => {
      storageLocationStore.refreshStore();
    };
  });

  appNavigationStore.useSubPageIdSetter('locations');

  return <MaterialLikeStorageLocations materialLikeOrPackageId={{ materialSetId }} />;
});

export default MaterialSetStorageLocations;
