import React from 'react';
import { useTranslation } from 'react-i18next';
import './PackageImageThumbnail.css';
import { PictureDTO } from 'dto/file';
import Picture from 'components/Picture';

interface Props {
  image: PictureDTO;
  onImageDelete: (imageId: string) => void;
}

const PackageImageThumbnail = ({ image, onImageDelete }: Props) => {
  const { t } = useTranslation('packages');
  return (
    <div className="post_image package-image-thumbnail">
      <div className="download_file delete-icon" onClick={() => onImageDelete(image.pictureFileId)}>
        <div className="hitbox_helper">
          <div className="div-block-75">
            <img src="images/bin.svg" alt="" className="icon_question" />
          </div>
        </div>
        <div className="div-block-76 width_auto delete image-delete-tooltip">
          <div>
            <strong>{t('packageDetail.button.deletePicture')}</strong>
          </div>
        </div>
      </div>
      <div className="image_post_wide in_content package-image-thumbnail-content">
        <Picture src={image.pictureThumbnail} alt="" className="image_110" />
      </div>
    </div>
  );
};

export default PackageImageThumbnail;
