import {
  CreateMaterialDTO,
  MaterialDTO,
  UpdateMaterialDTO,
  GroupedMaterialGuidesDTO,
  MaterialSearchDTO,
  MaterialSearchResultDTO,
  MaterialLikeIdDTO
} from 'dto/material';
import {
  createMaterial,
  getMaterial,
  updateMaterial,
  getMaterialGuides,
  getLatestMaterials,
  getMaterialStorageLocations
} from 'api/material';
import { deleteGuidesMaterial } from 'api/guide';
import { MaterialStorageLocationsDTO, LocationSource } from 'dto/storageLocation';
import { observable, runInAction, action } from 'mobx';
import { GroupedMaterialKnowledgeDTO, MaterialKnowledgeDTO } from 'dto/materialKnowledge';
import LoadingStore from './loadingStore';

export default class MaterialStore {
  @observable
  private loadingStore: LoadingStore;

  @observable
  materialDetail?: MaterialDTO;

  @observable
  materialId?: string;

  @observable
  materialGuides?: GroupedMaterialGuidesDTO[] = [];

  @observable
  materialKnowledge?: GroupedMaterialKnowledgeDTO[];

  @observable
  isCreateMaterialSynonymOpen = false;

  @observable
  isMaterialKnowledgeFormFlyoutOpen = false;

  @observable
  selectedMaterialKnowledge: MaterialKnowledgeDTO | null = null;

  @observable
  chapterIdForNewMaterialKnowledge = '';

  @observable
  positionForNewMaterialKnowledge?: number = undefined;

  @observable
  latestMaterials: MaterialSearchResultDTO[] = [];

  @observable
  erpStorageLocations: MaterialStorageLocationsDTO[] = [];

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;
  }

  createMaterial(material: CreateMaterialDTO) {
    return this.loadingStore.withFlyoutLoadingBar(() => createMaterial(material));
  }

  async loadStorageLocations() {
    const erpStorageLocations = await this.loadingStore.withFlyoutLoadingBar(() =>
      getMaterialStorageLocations({ materialId: this.materialId, locationSource: LocationSource.ERP })
    );
    runInAction(() => {
      this.erpStorageLocations = erpStorageLocations;
    });
  }

  @action
  async loadMaterial(materialId: string) {
    this.materialDetail = undefined;
    const materialDetail = await this.loadingStore.withLoadingBar(() => getMaterial(materialId));
    runInAction(() => {
      this.materialDetail = materialDetail;
    });
  }

  async loadMaterialGuides(materialId: string) {
    const materialGuides = await this.loadingStore.withLoadingBar(() => getMaterialGuides(materialId));
    runInAction(() => {
      this.materialGuides = materialGuides;
    });
  }

  async updateMaterial(fields: UpdateMaterialDTO) {
    const materialDetail = await this.loadingStore.withFlyoutLoadingBar(() => updateMaterial(fields));
    runInAction(() => {
      this.materialDetail = materialDetail;
    });
  }

  async deleteGuidesMaterial(id: MaterialLikeIdDTO) {
    await this.loadingStore.withFlyoutLoadingBar(() => deleteGuidesMaterial(id));
    return this.loadingStore.withLoadingBar(async () => {
      if (id.materialId) {
        return this.loadMaterialGuides(id.materialId);
      }
      return undefined;
    });
  }

  async loadLatestManualMaterials(departmentId: string) {
    const latestMaterials: MaterialSearchDTO = {
      filterBy: 'manual',
      sortBy: 'createdAt',
      departmentId,
      sortDirection: 'desc'
    };
    const materials = await this.loadingStore.withLoadingBar(() => getLatestMaterials(latestMaterials));

    runInAction(() => {
      this.latestMaterials = materials;
    });
  }

  @action
  setMaterialId = (materialId: string) => {
    this.materialId = materialId;
  };
}
