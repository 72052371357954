/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsUUID, IsString, IsOptional, ValidateNested, IsInt } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { GroupMaterialDTO } from './groupMaterial';
import { TagDTO } from './tag';
import { OptionalPictureDTO } from './file';

export class SetGroupDTO extends OptionalPictureDTO {
  @IsUUID()
  setGroupId!: string;

  @IsString()
  name!: string;

  @ValidateNested({ each: true })
  @Type(() => GroupMaterialDTO)
  groupMaterials!: GroupMaterialDTO[];

  @ValidateNested({ each: true })
  @Type(() => TagDTO)
  tags!: TagDTO[];
}

export class SimpleSetGroupDTO {
  @IsUUID()
  setGroupId!: string;

  @IsString()
  name!: string;
}

export class CreateSetGroupDTO {
  @IsString()
  name!: string;

  @IsUUID()
  materialSetId!: string;

  @IsInt()
  position!: number;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

export class UpdateSetGroupDTO {
  @IsUUID()
  setGroupId!: string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsUUID()
  @IsOptional()
  pictureFileId?: string;
}

export class UpdateSortSetGroupsDTO {
  @IsUUID(undefined, { each: true })
  setGroupIds!: string[];
}
