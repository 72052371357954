import React from 'react';
import { useTranslation } from 'react-i18next';
import SideMenuIconButton from 'components/SideMenuIconButton';
import { useStores } from 'util/mobx/stores';
import { downloadFile } from 'util/download';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';

interface Props {
  handleCreateProcedureFlyoutOpen: () => void;
  handleCloneProcedure: () => void;
  handleCloneMaterialKnowledge: () => void;
}

const GuideProcedureRightMenu = ({ handleCreateProcedureFlyoutOpen, handleCloneProcedure, handleCloneMaterialKnowledge }: Props) => {
  const { guideStore, postStore, domainStore } = useStores();
  const { t } = useTranslation('guideProcedure');

  const menuButtonClickHandlerWrapper = (event: React.MouseEvent, handler: (event: React.MouseEvent) => void) => {
    handler(event);
    event.preventDefault();
  };

  const onCommentsFlyoutOpen = async () => {
    if (guideStore.selectedGuide?.guideId) {
      await postStore.loadGuidePosts(guideStore.selectedGuide?.guideId);
      postStore.setIsChatFlyoutOpen(true);
    }
  };

  return (
    <>
      <Can I={actions.add} this={subject(subjectArea.guideProcedure, { departmentId: guideStore.selectedGuide?.departmentId })}>
        {!domainStore.isMobile && (
          <SideMenuIconButton
            handleClick={() => handleCreateProcedureFlyoutOpen()}
            label={t('button.addProcedure')}
            iconPath="icon_f-add_16.svg"
          />
        )}
        {!domainStore.isMobile && (
          <SideMenuIconButton
            handleClick={() => handleCloneProcedure()}
            label={t('button.getProcedure')}
            iconPath="icon_archive-e-download_16.svg"
          />
        )}
        {!domainStore.isMobile && (
          <SideMenuIconButton
            handleClick={() => handleCloneMaterialKnowledge()}
            label={t('button.getMaterial')}
            iconPath="icon_archive-e-download_16.svg"
          />
        )}
      </Can>
      <SideMenuIconButton handleClick={onCommentsFlyoutOpen} label={t('button.allComments')} iconPath="icon_icon_f-comment_16_blue.svg" />
      <Can I={actions.update} this={subject(subjectArea.guideProcedure, { departmentId: guideStore.selectedGuide?.departmentId })}>
        <SideMenuIconButton
          handleClick={event =>
            menuButtonClickHandlerWrapper(event, () => {
              if (guideStore.selectedGuide) {
                guideStore.setSelectedGuide(guideStore.selectedGuide);
                guideStore.setShowGuideFlyout(true);
              }
            })
          }
          label={t('button.renameManual')}
          iconPath="icon_background_16.svg"
        />
      </Can>
      {!domainStore.isMobile && (
        <SideMenuIconButton
          handleClick={() => guideStore.exportGuide().then(downloadFile)}
          label={t('button.export')}
          iconPath="icon_share_16.svg"
        />
      )}
      <Can I={actions.delete} this={subject(subjectArea.guide, { departmentId: guideStore.selectedGuide?.departmentId })}>
        <SideMenuIconButton
          handleClick={() => {
            guideStore.deleteGuide();
          }}
          red
          label={t('button.deleteManual')}
          iconPath="icon_bin_16.svg"
        />
      </Can>
    </>
  );
};

export default GuideProcedureRightMenu;
