import React, { useState, useEffect } from 'react';
import { useAbility } from '@casl/react';
import { TagDTO } from 'dto/tag';
import { Can, subjectArea, actions, AbilityContext } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import { useStores } from 'util/mobx/stores';
import Tag from './Tag';
import AddTag from './AddTag';

import './Tags.css';

interface Props {
  tags: TagDTO[];
  editable?: boolean;
  onTagCreate?: (tagName: string) => Promise<TagDTO>;
  onTagUpdate?: (tag: TagDTO) => Promise<TagDTO>;
  onTagDelete?: (tagId: string) => Promise<boolean>;
}

const Tags = ({ tags, onTagCreate, editable = false, onTagDelete: _onTagDelete, onTagUpdate: _onTagUpdate }: Props) => {
  const ability = useAbility(AbilityContext);
  const [tagsList, setTagsList] = useState(tags);
  const [selectedTagId, setSelectedTagId] = useState('');
  const { domainStore } = useStores();

  const onAddTagInputBlur = async (tagName: string) => {
    if (onTagCreate) {
      const tag = await onTagCreate(tagName);
      setTagsList([...tagsList, tag]);
    }
  };

  const onSelectTag = (tagId: string) => {
    setSelectedTagId(tagId);
  };

  const onTagDelete = (tagId: string) => {
    if (_onTagDelete) {
      _onTagDelete(tagId);
      setTagsList(tagsList.filter(t => t.tagId !== tagId));
    }
  };

  const onFocusOut = () => setSelectedTagId('');

  const onTagUpdate = (updatedTag: TagDTO) => {
    const updatedTagList = tagsList.map(tag => {
      if (tag.tagId === updatedTag.tagId) {
        return updatedTag;
      }
      return tag;
    });
    setTagsList(updatedTagList);
    if (_onTagUpdate) {
      return _onTagUpdate(updatedTag);
    }
    return undefined;
  };

  useEffect(() => {
    const onSelectedTagInputBlur = (event: MouseEvent) => {
      const target = event.target as HTMLDivElement;
      if (!target.classList.contains('tag-item') && !target.closest('.tag-item')) {
        setSelectedTagId('');
      }
    };
    document.body.addEventListener('click', onSelectedTagInputBlur);
    return () => {
      document.body.removeEventListener('click', onSelectedTagInputBlur);
    };
  });

  return (
    <div className="note_wrapper tags-list">
      {tagsList.map(tag => (
        <React.Fragment key={tag.tagId}>
          <Tag
            editable={editable && ability.can(actions.update, subject(subjectArea.tag, { departmentId: domainStore.currentDepartment.id }))}
            tag={tag}
            key={tag.tagId}
            inFocus={selectedTagId === tag.tagId}
            onClick={() => editable && onSelectTag(tag.tagId)}
            onTagDelete={onTagDelete}
            onTagUpdate={onTagUpdate}
            onFocusOut={onFocusOut}
          />
        </React.Fragment>
      ))}
      {editable && (
        <Can I={actions.add} this={subject(subjectArea.tag, { departmentId: domainStore.currentDepartment.id })}>
          <AddTag onAddTagInputBlur={onAddTagInputBlur} />
        </Can>
      )}
    </div>
  );
};

export default Tags;
