import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { SetGroupDTO } from 'dto/setGroup';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import Picture from 'components/Picture';
import HoverWrapper from 'components/HoverWrapper';
import MaterialValueTags from 'components/MaterialValueTags';
import GetCameraImageButton from 'components/GetCameraImageButton';
import Tags from 'components/ListItems/components/Tags';
import ListItemDropdownContainer from 'components/ListItems/ListItemDropdownContainer';
import { useStores } from 'util/mobx/stores';
import SurgeryGroupMaterialListItem from '../SurgeryGroupMaterialListItem';
import './SurgerySetGroupListItem.css';

interface Props {
  setGroup: SetGroupDTO;
  surgeryGuide: SurgeryGuideMaterialLikeDTO;
}

const SurgerySetGroupListItem = observer(({ setGroup, surgeryGuide }: Props) => {
  const [groupMaterials, setGroupMaterials] = useState(setGroup.groupMaterials);
  const { materialSetsStore, guideStore } = useStores();

  useEffect(() => {
    setGroupMaterials(setGroup.groupMaterials);
  }, [setGroup.groupMaterials]);

  const renderList = () => (
    <div className="content_holder no_padding padding_left instruments-list">
      {groupMaterials.map(groupMaterial => {
        return (
          <SurgeryGroupMaterialListItem
            groupMaterial={groupMaterial}
            key={`${groupMaterial.groupMaterialId}${groupMaterial.material.pictureThumbnail}`}
            surgeryGuide={surgeryGuide}
          />
        );
      })}
    </div>
  );

  const onUpdateFile = async (file: string) => {
    await materialSetsStore.updateSetGroup({ setGroupId: setGroup.setGroupId, pictureFileId: file });
    if (guideStore.selectedGuide) {
      guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
  };

  return (
    <ListItemDropdownContainer classPrefix="set-group" className="surgery-set-group" list={renderList()} key={setGroup.setGroupId}>
      <HoverWrapper className="list_item_material">
        {({ hover }) => (
          <>
            <div className="image_wrapper_50">
              <div className="image_border image-border" />
              {setGroup.picture ? (
                <Picture src={setGroup.picture} width={50} alt="" className="image_circle_50" />
              ) : (
                <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
              )}
            </div>
            <div className="material_info w-inline-block package-info">
              <div className="material_text material-text">
                <div className="link-block">{setGroup.name}</div>
                <Tags tags={setGroup.tags} />
              </div>
              <MaterialValueTags setGroup={setGroup} fullInfo />
            </div>
          </>
        )}
      </HoverWrapper>
    </ListItemDropdownContainer>
  );
});

export default SurgerySetGroupListItem;
