import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import OverviewBox, { OverviewBoxPhotoLink } from 'components/OverviewBox';
import { downloadFile } from 'util/download';
import { QRSize } from 'dto/qr';
import MaterialSetOverviewRightMenu from './MaterialSetOverviewRightMenu';

interface Props {
  materialSetId: string;
  groupType: string;
  hideStorageLocation: boolean;
  hideQrCode: boolean;
}

const ImplantOverview = observer(({ materialSetId, groupType, hideStorageLocation, hideQrCode }: Props) => {
  const { t } = useTranslation(groupType);
  const { appNavigationStore, materialSetsStore } = useStores();
  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <MaterialSetOverviewRightMenu />;
    });
  }, [appNavigationStore, groupType]);

  appNavigationStore.useSubPageIdSetter('overview');

  return (
    <div className="single_colum_content no_padding_right">
      <div className="div-block-48">
        <OverviewBox
          link={`/group/${groupType}/detail?materialSetId=${materialSetId}`}
          name={t('overview.baseData.name')}
          text={t('overview.baseData.textHtml')}
          btnText={t('overview.baseData.btnText')}
          image="data-table_16"
        />
        <OverviewBox
          link={`/group/${groupType}/list?materialSetId=${materialSetId}`}
          name={t('overview.implantsControl.name')}
          text={t('overview.implantsControl.textHtml')}
          btnText={t('overview.implantsControl.btnText')}
          image="icon_document_16"
        />
        <OverviewBox
          link={`/group/${groupType}/content?materialSetId=${materialSetId}`}
          name={t('overview.knowledge.name')}
          text={t('overview.knowledge.textHtml')}
          btnText={t('overview.knowledge.btnText')}
          image="bulb-62_16"
        />
        <OverviewBox
          link={`/group/${groupType}/functions?materialSetId=${materialSetId}`}
          name={t('overview.globalFunctions.name')}
          text={t('overview.globalFunctions.textHtml')}
          btnText={t('overview.globalFunctions.btnText')}
          image="globe_16"
        />
        {!hideStorageLocation && (
          <OverviewBox
            link={`/group/${groupType}/locations?materialSetId=${materialSetId}`}
            name={t('overview.storageLocation.name')}
            text={t('overview.storageLocation.textHtml')}
            btnText={t('overview.storageLocation.btnText')}
            image="pin-3_16"
          />
        )}
        {!hideQrCode && (
          <OverviewBoxPhotoLink
            className="desktop-only"
            groupType={groupType}
            onClick={() =>
              materialSetsStore
                .getQRCodePDF([
                  {
                    headingTop: t('overview.photoLink.qrCodePDF.headingTop'),
                    description: t('overview.photoLink.qrCodePDF.description'),
                    heading2: t('overview.photoLink.qrCodePDF.heading2'),
                    fileName: t('overview.photoLink.qrCodePDF.fileName'),
                    size: QRSize.Big
                  },
                  {
                    headingTop: t('overview.photoLink.qrCodePDF.smallHeadingTop'),
                    description: t('overview.photoLink.qrCodePDF.description'),
                    heading2: t('overview.photoLink.qrCodePDF.heading2'),
                    fileName: t('overview.photoLink.qrCodePDF.fileName'),
                    size: QRSize.Small
                  }
                ])
                .then(downloadFile)
            }
          />
        )}
        {materialSetsStore.materialSetDetail && materialSetsStore.materialSetDetail.user && (
          <OverviewBox
            link={`/group/${groupType}/overview?materialSetId=${materialSetId}`}
            name={t('overview.history.name')}
            text={t('overview.history.textPartOne')}
            additionText={t('overview.history.textPartTwo')}
            image="icon_time-clock_16_grey"
            user={materialSetsStore.materialSetDetail.user}
            createdDate={materialSetsStore.materialSetDetail.createdAt}
          />
        )}
      </div>
    </div>
  );
});

export default ImplantOverview;
