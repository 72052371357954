import React, { useEffect } from 'react';
import { InstrumentDTO } from 'dto/instrument';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialKnowledge from 'components/MaterialKnowledge';
import InstrumentContentRightMenu from './InstrumentContentRightMenu';

interface Props {
  instrument?: InstrumentDTO;
}

const InstrumentContent = observer(({ instrument }: Props) => {
  const { appNavigationStore, materialStore } = useStores();
  useEffect(() => {
    if (instrument?.material.materialId) {
      materialStore.setMaterialId(instrument.material.materialId);
    }
  }, [materialStore, instrument]);

  useEffect(() => {
    // set the component for the right menu
    appNavigationStore.setRightMenuBuilder(() => {
      return <InstrumentContentRightMenu />;
    });
  }, [appNavigationStore]);

  appNavigationStore.useSubPageIdSetter('knowledge');

  return (
    <div className="single_colum_content">
      {instrument ? <MaterialKnowledge materialLikeId={{ materialId: instrument.material.materialId }} /> : <></>}
    </div>
  );
});

export default InstrumentContent;
