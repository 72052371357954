import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';

import FlyoutContainer from 'components/FlyoutContainer';
import ImageCircle from 'components/ImageCircle';

const DeleteGuideFlyout = observer(() => {
  const { t } = useTranslation('guide');
  const history = useHistory();
  const { guideStore } = useStores();
  const isOpen = guideStore.deleteGuideFlyout.isOpen;
  const closePopUp = () => guideStore.hideDeleteGuideFlyout();
  const onSubmit = async () => {
    await guideStore.deleteGuide();
    closePopUp();
    history.push('/guides');
  };

  const picture = '';

  const translationSuffix = guideStore.deleteGuideFlyout.guideId ? '' : 'Several';

  return (
    <FlyoutContainer
      icon={<img src="images/icon_delete_prompt.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={isOpen}
      closePopUp={closePopUp}
      cancelLabel={t('deleteGuide.button.cancel')}
      onSubmit={onSubmit}
      submitLabel={t(`deleteGuide.button.submit${translationSuffix}`)}
      isAllowedToSubmit
      title={t(`deleteGuide.headline${translationSuffix}`)}
      warning
    >
      <div className="flyout_box">
        <div className="h4">{t(`deleteGuide.label.description${translationSuffix}`)}</div>
        {guideStore.deleteGuideFlyout.guideId ? (
          <div className="list_item_book in_promp">
            <div className="image_wrapper_50">
              <ImageCircle highlight={false} user={guideStore.selectedGuideOwner} builtinPictureName={picture} size="50" marginRight={0} />
            </div>
            <div className="book_info in_prompt">
              <div className="t2 bottom-margin">{`${guideStore.selectedGuideOwner?.title} ${guideStore.selectedGuideOwner?.lastName} ${guideStore.selectedGuideOwner?.firstName}`}</div>

              <div>{guideStore.selectedGuide?.name}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </FlyoutContainer>
  );
});
export default DeleteGuideFlyout;
