import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { SurgeryProcedureDTO } from 'dto/surgeryGuideProcedure';
import PostMediaList from 'components/PostItem/PostMediaList';
import SurgeryMaterialStatusBox from 'pages/surgeryMaterials/SurgeryMaterialLikeListItem/SurgeryMaterialStatusBox';
import BlackCheckBox from '../SurgeryProcedureDrawerFooter/BlackCheckBox';
import './ProcedureContentDrawerBox.css';

interface Props {
  procedure: SurgeryProcedureDTO;
}

const ProcedureContentDrawerBox = observer(({ procedure }: Props) => {
  const { surgeryGuideStore } = useStores();

  const onStatusChanged = (checked: boolean) => {
    surgeryGuideStore.updateProcedure({
      surgeryGuideProcedureId: procedure.surgeryGuideProcedureId,
      checked
    });
  };

  return (
    <div className="op-pilot_content_box">
      <div className="post_item in_op-pilot">
        <BlackCheckBox small status={procedure.checked} onStatusChanged={onStatusChanged} key={+procedure.checked} />
        <div className="post_box in_op-pilot">
          <div className="headline_op-pilot">
            <strong>{procedure.content.title}</strong>
          </div>
          <div className="txt_content">{procedure.content.description}</div>
          <PostMediaList files={procedure.content.contentElementMedias} />
          <SurgeryMaterialStatusBox procedure={procedure} />
        </div>
      </div>
    </div>
  );
});

export default ProcedureContentDrawerBox;
