import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import MaterialLikeStorageLocations from 'components/MaterialLikeStorageLocations';
import FlyoutContainer from 'components/FlyoutContainer';

const SelectMaterialLocationFlyout = observer(() => {
  const { t } = useTranslation('storageLocations');
  const { storageLocationStore, guideDetailDrawerStore } = useStores();

  const onClose = () => {
    storageLocationStore.setIsSelectMaterialLocationFlyoutOpen(false);
    // wait until flyout closed
    setTimeout(() => {
      storageLocationStore.refreshStore();
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (!storageLocationStore.isSelectMaterialLocationFlyoutOpen) {
        storageLocationStore.refreshMaterialLikeData();
      }
    };
  }, [storageLocationStore, storageLocationStore.isSelectMaterialLocationFlyoutOpen]);

  const onSelect = async () => {
    if (storageLocationStore.selectedMaterialStorageLocation && storageLocationStore.selectedMaterialFlyoutCallback) {
      storageLocationStore.selectedMaterialFlyoutCallback(storageLocationStore.selectedMaterialStorageLocation.storageLocationId);
    }

    onClose();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_location.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={storageLocationStore.isSelectMaterialLocationFlyoutOpen}
      closePopUp={onClose}
      cancelLabel={t('selectLocationFlyout.cancel')}
      onSubmit={storageLocationStore.selectedMaterialFlyoutCallback && onSelect}
      submitLabel={
        storageLocationStore.selectedMaterialFlyoutCallback &&
        t('selectLocationFlyout.submit', {
          locationName: storageLocationStore.selectedMaterialStorageLocation
            ? storageLocationStore.selectedMaterialStorageLocation.name
            : ''
        })
      }
      isAllowedToSubmit={!!storageLocationStore.selectedMaterialStorageLocation}
      title={t('selectLocationFlyout.headline')}
    >
      <div className="div-block-149">
        <div className="div-block-131 padding padding_bottom location-list-container">
          {guideDetailDrawerStore.selectedMaterial && (
            <MaterialLikeStorageLocations
              materialLikeOrPackageId={{ materialId: guideDetailDrawerStore.selectedMaterial.materialId }}
              inFlyout
            />
          )}
          {guideDetailDrawerStore.selectedMaterialSet && (
            <MaterialLikeStorageLocations
              materialLikeOrPackageId={{ materialSetId: guideDetailDrawerStore.selectedMaterialSet.materialSetId }}
              inFlyout
            />
          )}
          {guideDetailDrawerStore.selectedPackage && (
            <MaterialLikeStorageLocations
              materialLikeOrPackageId={{ packageId: guideDetailDrawerStore.selectedPackage.packageId }}
              inFlyout
            />
          )}
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default SelectMaterialLocationFlyout;
