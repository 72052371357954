import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import Button from 'components/Form/Button';
import { useStores } from 'util/mobx/stores';
import { MergedGuideMaterialDTO } from 'stores/guideDetailDrawerStore';
import GuideSettingsForm from './GuideSettingsForm';
import '../Settings.css';

interface Props {
  onSettingsClose: () => void;
  guideMaterial?: GuideMaterialLikeDTO;
  surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO;
  hideStorageLocation?: boolean;
}

const MaterialSettings = observer(({ guideMaterial, surgeryGuideMaterial, hideStorageLocation = false, onSettingsClose }: Props) => {
  const { t } = useTranslation('guideSettings');
  const { guideDetailDrawerStore, guideStore } = useStores();

  const guideItem: MergedGuideMaterialDTO | undefined = guideMaterial || surgeryGuideMaterial;

  if (!guideItem) {
    return null;
  }

  const handleDeleteGuideMaterial = () => {
    if (guideMaterial) {
      guideDetailDrawerStore.deleteGuideMaterial(guideMaterial.guideMaterialId, () => guideStore.refreshGuideMaterials());
    }
    guideDetailDrawerStore.setIsOpen(false);
  };

  return (
    <div className="mat_settings_wrapper guide-settings">
      <div className="mat_settings_header white-backgroud">
        <div className="div-block-25 mat_head">
          <img src="images/icon_settings-gear_16.svg" alt="" className="image_function" />
          <div>{t('headline')}</div>
        </div>
        <div className="close_icon_mobile" onClick={onSettingsClose}>
          <img src="images/icon_e-remove_16.svg" alt="" />
        </div>
      </div>
      <GuideSettingsForm guideItem={guideItem} hideStorageLocation={hideStorageLocation} />
      <div className="mat_settings_header">
        <img src="images/icon_settings-gear_16.svg" alt="" className="image_function" />
        <div>{t('functions')}</div>
      </div>
      {guideMaterial ? (
        <div className="setting_wrapper function">
          <Button className="btn_materialset_detail delete-button w-inline-block" onClick={handleDeleteGuideMaterial}>
            <img src="images/icon_bin_16.svg" alt="" className="image_mat_detail" />
            <div className="text-block-6-copy">{t('button.delete')}</div>
          </Button>
        </div>
      ) : (
        <div className="delete-text">{t('surgeryDeleteDescription')}</div>
      )}
    </div>
  );
});

export default MaterialSettings;
