import React from 'react';
import { saveFile } from 'api/file';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import Button from 'components/Form/Button';
import ItemMenuButton from 'components/ItemMenuButton';
import { unlockPortrait, lockPortrait } from 'util/mobile/mobileUtils';
import { FileMetadataDTO } from 'dto/file';
import './GetCameraImageButton.css';
import Picture from 'components/Picture';

interface Props {
  onUpdateFile?: (fileId: string) => Promise<void>;
  onSaveFile?: (file: File) => Promise<void>;
  apiCall?: (formData: FormData) => Promise<FileMetadataDTO>;
  description?: string;
  isButtonVisible?: boolean;
  hover?: boolean;
  buttonStyle:
    | 'square'
    | 'circle'
    | 'icon'
    | 'menu'
    | 'comment'
    | 'board'
    | 'itemMenuButton'
    | 'head'
    | 'detailForm'
    | 'appBar'
    | 'postItem'
    | 'user'
    | 'userIcon';
  className?: string;
  picturesOnly?: boolean;
  isAddable?: boolean;
  openDetail?: () => void;
}

interface ButtonProps<T> {
  // disable as the props are used by different buttons
  // eslint-disable-next-line react/no-unused-prop-types
  isButtonVisible?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  hover?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  description?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  isAddable?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  openDetail?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onClick: (event: React.MouseEvent<T, MouseEvent>) => void;
}

const CircleButton = ({ onClick, isButtonVisible, description = '' }: ButtonProps<HTMLDivElement>) => {
  return (
    <ListItemAdd className="post_item_add" visibleCondition={isButtonVisible} onClick={onClick}>
      <InfoBox label={description} />
    </ListItemAdd>
  );
};

const SquareButton = ({ onClick, description = '' }: ButtonProps<HTMLButtonElement>) => {
  return (
    <Button className="btn_small margin-left" onClick={onClick}>
      <div className="txt_dropout_1">{description}</div>
    </Button>
  );
};

const MenuButton = ({ onClick, description = '', hover }: ButtonProps<HTMLButtonElement>) => {
  return (
    <Button className={`btn_material_detail ${hover && 'btn-material-detail-hover'} w-inline-block`} onClick={onClick}>
      <img src="images/icon_camera-2_16_grey.svg" alt="" className={`image_mat_detail ${hover && 'image-mat-detail-hover'}`} />
      <div>{description}</div>
    </Button>
  );
};

const IconButton = ({ onClick, hover, isAddable }: ButtonProps<HTMLDivElement>) => {
  const imageSrc = isAddable ? 'images/picture-add.jpg' : 'images/icon_book_makephoto.jpg';
  const className = isAddable && 'icon-pointer';
  return (
    <>
      <div onClick={onClick} style={{ opacity: hover ? 1 : 0 }} className={`image_border ${className}`} />
      <img src={imageSrc} width={50} alt="" className="image_circle_50" />
    </>
  );
};

const UserIconButton = ({ onClick, hover, isAddable }: ButtonProps<HTMLDivElement>) => {
  const imageSrc = isAddable ? 'images/icon_add_patient.jpg' : 'images/icon_person.jpg';
  return (
    <>
      <div onClick={onClick} style={{ opacity: hover ? 1 : 0 }} className="image_border" />
      {!isAddable ? (
        <Picture src={imageSrc} width={40} sizes="40px" alt="" className="image_circle_40" />
      ) : (
        <Picture src={imageSrc} width={50} alt="" className="image_circle_50" />
      )}
    </>
  );
};

const PostItemIconButton = ({ openDetail }: ButtonProps<HTMLDivElement>) => {
  const className = openDetail && 'icon-pointer';
  return (
    <>
      <div className={`image_circle_40_post ${className}`} onClick={openDetail}>
        <img src="images/icon_book_makephoto.jpg" width={40} alt="" className="image_circle_40" />
      </div>
    </>
  );
};

const AppBarPageIconButton = ({ onClick, hover, isAddable }: ButtonProps<HTMLDivElement>) => {
  const imageSrc = isAddable ? 'images/picture-add.jpg' : 'images/icon_book_makephoto.jpg';
  return (
    <>
      <div className="image_wrapper_40 image-wrapper-40">
        <div onClick={onClick} style={{ opacity: hover ? 1 : 0 }} className="image_border icon-pointer" />
        <img src={imageSrc} width={40} alt="" className="image_circle_40" />
      </div>
    </>
  );
};

const HeadIconButton = ({ onClick, isAddable }: ButtonProps<HTMLDivElement>) => {
  const imageSrc = isAddable ? 'images/picture-add.jpg' : 'images/icon_book_makephoto.jpg';
  return (
    <>
      <div onClick={onClick} className="image_border icon-pointer" />
      <img src={imageSrc} width={50} alt="" className="image_circle_100" />
    </>
  );
};

const DetailFormButton = ({ onClick }: ButtonProps<HTMLDivElement>) => {
  return (
    <>
      <div onClick={onClick} className="icon-pointer add-picture">
        <img src="images/no_image_small.jpeg" alt="" />
      </div>
    </>
  );
};

const CircleButtonChat = ({ onClick, className = '' }: ButtonProps<HTMLDivElement>) => {
  return (
    <div className={`btn_add_media_chat-copy add-media-chat ${className}`} onClick={onClick}>
      <div className="image_border" />
      <img src="images/icon_add.jpg" alt="" className="image_circle_40" />
    </div>
  );
};

const BoardButton = ({ onClick, className = '' }: ButtonProps<HTMLButtonElement>) => {
  return (
    <Button className={className} onClick={onClick}>
      <img src="images/icon_camera-2_16.svg" alt="" className="image_camera_dialog image-camera-dialog" />
    </Button>
  );
};

const ItemMenu = ({ onClick, description = '' }: ButtonProps<HTMLDivElement>) => {
  return <ItemMenuButton label={description} icon="icon_camera-2_16.svg" isInPopover handleClick={onClick} />;
};

const addUserPictureButton = ({ onClick, isButtonVisible, description = '' }: ButtonProps<HTMLDivElement>) => {
  return (
    <ListItemAdd className="post_item_add" visibleCondition={isButtonVisible} onClick={onClick} addUserPicture>
      <InfoBox label={description} />
    </ListItemAdd>
  );
};

const BUTTONS = {
  square: SquareButton,
  circle: CircleButton,
  menu: MenuButton,
  icon: IconButton,
  comment: CircleButtonChat,
  board: BoardButton,
  itemMenuButton: ItemMenu,
  head: HeadIconButton,
  detailForm: DetailFormButton,
  appBar: AppBarPageIconButton,
  postItem: PostItemIconButton,
  user: addUserPictureButton,
  userIcon: UserIconButton
};

export default function GetCameraImageButton({
  apiCall,
  onSaveFile,
  onUpdateFile,
  description,
  isButtonVisible,
  hover,
  buttonStyle,
  className,
  picturesOnly,
  isAddable,
  openDetail
}: Props) {
  const inputFile = React.createRef<HTMLInputElement>();

  const updatePicture = async (bodyFormData: FormData) => {
    if (apiCall) {
      const result = await apiCall(bodyFormData);
      return result;
    }
    const result = await saveFile(bodyFormData);

    return result;
  };

  const handleFileUpload = async (file: File) => {
    if (onSaveFile) {
      onSaveFile(file);
      return;
    }
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    const response = await updatePicture(bodyFormData);
    if (response) {
      if (onUpdateFile) {
        await onUpdateFile(response.fileId);
      }
    }
  };

  async function takePicture<T>(event: React.MouseEvent<T, MouseEvent>) {
    if (!isAddable) {
      return;
    }
    event.preventDefault();

    // WORKAROUND: it crashes if the view is locked and a photo is taken in not-portrait mode when switching back to the app.
    // -> Disable lock when taking the picture and reenabel it when saving or aborting.
    await unlockPortrait();
    if (inputFile.current) inputFile.current.click();
  }

  const handleChange = async (selectorFiles: FileList) => {
    const file = selectorFiles[0];
    if (!picturesOnly || ['image/gif', 'image/jpeg', 'image/png'].indexOf(file.type) !== -1) {
      handleFileUpload(file);
    } else {
      console.error(new Error(`file type "${file.type}" is not supported for picture-only files`));
    }
    // reenabel the lock
    lockPortrait();
  };
  const renderButton = () => {
    const ButtonComponent = BUTTONS[buttonStyle];

    return (
      <ButtonComponent
        hover={hover}
        className={className}
        description={description}
        onClick={takePicture}
        isButtonVisible={isButtonVisible}
        isAddable={isAddable}
        openDetail={openDetail}
      />
    );
  };

  return (
    <>
      <input
        type="file"
        onAbort={lockPortrait}
        onChange={e => e.target.files && handleChange(e.target.files)}
        ref={inputFile}
        style={{ display: 'none' }}
        accept={`capture=camera${picturesOnly ? ',image/*' : ''}`}
      />
      {renderButton()}
    </>
  );
}
