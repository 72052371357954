import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import FlyoutContainer from 'components/FlyoutContainer';
import { useStores } from 'util/mobx/stores';
import ImageCircle from 'components/ImageCircle';
import moment from 'moment';
import { dateFormat, timeFormat } from 'util/date';
import Button from 'components/Form/Button';

interface Props {
  groupType: string;
}

const CheckItemsListFlyout = observer(({ groupType }: Props) => {
  const { t } = useTranslation('checkListItemsFlyout');
  const { t: dateTranslate } = useTranslation('date');
  const { materialSetsStore } = useStores();

  const closePopUp = () => materialSetsStore.setIsCheckListFlyoutOpen(false);

  useEffect(() => {
    if (materialSetsStore.isCheckListFlyoutOpen) {
      materialSetsStore.loadMaterialSetChecks(materialSetsStore.materialSetId);
    }
  }, [materialSetsStore, materialSetsStore.isCheckListFlyoutOpen]);

  const onSelectSetCheckItem = async (setCheckId: string) => {
    closePopUp();
    await materialSetsStore.loadSelectedSetCheck(setCheckId);
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_show_check_list.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={materialSetsStore.isCheckListFlyoutOpen}
      closePopUp={closePopUp}
      cancelLabel={t(`${groupType}.button.cancel`)}
      title={t(`${groupType}.headline`)}
    >
      <div className="flyout_scroll_wrapper">
        <div className="flyout_box">
          <div className="h4">{t(`${groupType}.subhead`)}</div>
          <div className="menu_list_scrollable in_flyout flex_height">
            <div className="div-block-38-copy">
              {materialSetsStore.materialSetCheckList
                .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                .map(setCheck => (
                  <div className="list_item_material_no_hover martin_bottom" key={setCheck.setCheckId}>
                    <ImageCircle user={setCheck.user} size="50" highlight={false} />
                    <div className="book_info in_prompt">
                      <div className="t2 bottom-margin bold">
                        {setCheck.user.title} {setCheck.user.lastName} {setCheck.user.firstName}
                      </div>
                      <div>
                        {moment(setCheck.createdAt).format(`${dateFormat} - ${timeFormat}`)} {dateTranslate('time')}
                      </div>
                    </div>
                    <Button className="btn_small_flyout" onClick={() => onSelectSetCheckItem(setCheck.setCheckId)}>
                      <div className="txt_dropout_1">{t(`${groupType}.button.seeDocumentation`)}</div>
                    </Button>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </FlyoutContainer>
  );
});
export default CheckItemsListFlyout;
