import React from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import animationData from './animationData.json';

const LoadingIcon = () => {
  const defaultOptions: LottieProps['options'] = {
    autoplay: true,
    loop: true,
    animationData
  };
  return (
    <div className="lotti_video_opt">
      <Lottie options={defaultOptions} direction={1} speed={1.5}>
        <img src="images/animation.svg" alt="" />
      </Lottie>
    </div>
  );
};

export default LoadingIcon;
