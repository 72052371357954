import {
  GetPostDTO,
  PostDTO,
  CreateDepartmentPostDTO,
  CreateGuidePostDTO,
  GetFunctionalAreaPostDTO,
  GetDepartmentPostDTO,
  CreateFunctionalAreaPostDTO,
  DeletePostsDTO,
  GetBriefingPostDTO,
  CreateBriefingPostDTO
} from 'dto/post';
import { get, post, del } from './common';
import { validateArray, validate, validateBool } from './validation';

export const getGuidePosts = async (query: GetPostDTO): Promise<PostDTO[]> => {
  const posts = await get('/posts/guide', query).then(response => response.data);
  return validateArray(PostDTO, posts);
};

export const getDepartmentPosts = async (query: GetDepartmentPostDTO): Promise<PostDTO[]> => {
  const posts = await get('/posts/department', query).then(response => response.data);
  return validateArray(PostDTO, posts);
};

export const getFunctionalAreaPost = async (query: GetFunctionalAreaPostDTO): Promise<PostDTO[]> => {
  const posts = await get('/posts/functionalArea', query).then(response => response.data);
  return validateArray(PostDTO, posts);
};

export const getBriefingPost = async (query: GetBriefingPostDTO): Promise<PostDTO[]> => {
  const posts = await get('/posts/briefing', query).then(response => response.data);
  return validateArray(PostDTO, posts);
};

export const createDepartmentPosts = async (newPost: CreateDepartmentPostDTO): Promise<PostDTO> => {
  const createdPost = await post('/post/department', newPost).then(response => response.data);
  return validate(PostDTO, createdPost);
};

export const createGuidePosts = async (newPost: CreateGuidePostDTO): Promise<PostDTO> => {
  const createdPost = await post('/post/guide', newPost).then(response => response.data);
  return validate(PostDTO, createdPost);
};

export const createFunctionalAreaPosts = async (newPost: CreateFunctionalAreaPostDTO): Promise<PostDTO> => {
  const createdPost = await post('/post/functionalArea', newPost).then(response => response.data);
  return validate(PostDTO, createdPost);
};

export const createBriefingPost = async (newPost: CreateBriefingPostDTO): Promise<PostDTO> => {
  const createdPost = await post('/post/briefing', newPost).then(response => response.data);
  return validate(PostDTO, createdPost);
};

export const deletePosts = async (delPosts: DeletePostsDTO): Promise<boolean> => {
  return del('/posts', delPosts).then(response => validateBool(response.data));
};
