/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import 'reflect-metadata';
import { IsUUID, IsInt, IsOptional, ValidateNested, IsBoolean, IsEnum, IsString } from 'class-validator';
import { Type } from 'class-transformer';
import { CreateContentElementDTO, SurgeryProcedureContentElementDTO, OptionalSurgeryProcedureContentElementDTO } from './contentElement';
import { MaterialLikeIdDTO } from './material';
import { PictureDTO } from './file';
import { ProcedureType } from './procedure';

export class SurgeryProcedureDTO {
  @IsUUID()
  surgeryGuideProcedureId!: string;

  @IsUUID()
  guideChapterId!: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => MaterialLikeIdDTO)
  materialLikeId?: MaterialLikeIdDTO;

  @ValidateNested()
  @IsOptional()
  @Type(() => PictureDTO)
  materialPicture?: PictureDTO;

  @ValidateNested()
  @Type(() => SurgeryProcedureContentElementDTO)
  content!: SurgeryProcedureContentElementDTO;

  @IsInt()
  linkCount!: number;

  @IsEnum(ProcedureType)
  type!: ProcedureType;

  @IsUUID()
  @IsOptional()
  originalGuideProcedureId?: string;

  @IsBoolean()
  disabled!: boolean;

  @IsBoolean()
  checked!: boolean;
}

export class CreateSurgeryProcedureContentElementDTO extends CreateContentElementDTO {
  @IsBoolean()
  checked!: boolean;
}

export class CreateSurgeryGuideProcedureDTO {
  @IsUUID()
  guideChapterId!: string;

  @IsUUID()
  surgeryGuideId!: string;

  @IsInt()
  @IsOptional()
  position?: number;

  @IsUUID()
  @IsOptional()
  contentElementId?: string;

  @ValidateNested()
  @Type(() => CreateSurgeryProcedureContentElementDTO)
  content?: CreateSurgeryProcedureContentElementDTO;
}

export class UpdateSortSurgeryGuideProceduresDTO {
  @IsUUID()
  chapterId!: string;

  @IsUUID(undefined, { each: true })
  surgeryGuideProceduresIds!: string[];
}

export class UpdateSurgeryGuideProcedureDTO {
  @IsUUID()
  surgeryGuideProcedureId!: string;

  @ValidateNested()
  @Type(() => OptionalSurgeryProcedureContentElementDTO)
  content?: OptionalSurgeryProcedureContentElementDTO;

  @IsBoolean()
  @IsOptional()
  unlink?: boolean;

  @IsBoolean()
  @IsOptional()
  checked?: boolean;

  @IsBoolean()
  @IsOptional()
  disabled?: boolean;
}

export class GroupedSurgeryGuideProcedureDTO {
  @IsString()
  chapter!: string;

  @IsUUID()
  chapterId!: string;

  @ValidateNested({ each: true })
  @Type(() => SurgeryProcedureDTO)
  surgeryGuideProcedures!: SurgeryProcedureDTO[];
}
