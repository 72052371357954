import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Validator } from 'class-validator';

import FlyoutContainer from 'components/FlyoutContainer';
import UploadMultipleFiles, { MixedContentElementMediaDTO } from 'components/UploadMultipleFiles/UploadMultipleFiles';
import { saveFileContentElement } from 'api/file';
import Form, { InputField, Switch, TextareaField } from 'components/Form';
import { useStores } from 'util/mobx/stores';
import { FormikTouched, FormikValues } from 'formik';
import {
  prepareMediasToSave,
  getInitialValues,
  generateCreateProcedureData,
  generateCreateSurgeryProcedureData,
  ProcedureFormValues
} from './procedureFormFlyoutUtils';
import ProcedureGuidesFlyout from '../ProcedureGuidesFlyout';
import ProcedureContentElementHistory from '../ProcedureContentElementHistory';

interface Props {
  isSurgery?: boolean;
}

const ProcedureFormFlyout = observer(({ isSurgery = false }: Props) => {
  const { t } = useTranslation('guideProcedureFlyout');
  const { t: itemFormFlyOutTranslate } = useTranslation('itemFormFlyOut');
  const [isAllowedToSubmit, setIsAllowedToSubmit] = useState(false);
  const { procedureStore, guideStore, animationStore, surgeryGuideStore } = useStores();
  const [isProcedureGuidesFlyoutShown, setIsProcedureGuidesFlyoutShown] = useState(false);
  const [isHistoryFlyoutShown, setIsHistoryFlyoutShown] = useState(false);

  async function onHistoryFlyoutOpen() {
    if (procedureStore.selectedProcedure?.content.contentElementId) {
      await procedureStore.loadContentElementHistory(procedureStore.selectedProcedure?.content.contentElementId);
    }
    setIsHistoryFlyoutShown(true);
  }

  useEffect(() => {
    if (procedureStore.selectedProcedure?.guideProcedureId) {
      procedureStore.getProcedureGuides(procedureStore.selectedProcedure?.guideProcedureId);
    }
  }, [procedureStore, procedureStore.selectedProcedure]);

  const initialValues: ProcedureFormValues = getInitialValues(procedureStore, surgeryGuideStore, isSurgery);

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const isFieldsChanged = (values: FormikValues, touched: FormikTouched<FormikValues>) => {
    if (
      values.title !== initialValues.title ||
      values.description !== initialValues.description ||
      touched.contentElementMedias ||
      (values.createPost !== initialValues.createPost && procedureStore.selectedProcedure)
    ) {
      return true;
    }
    return false;
  };

  const bindAllowedToSubmit = (isDirty: boolean, values: FormikValues, touched: FormikTouched<FormikValues>) => {
    let isUploading = false;
    const validator = new Validator();
    if (values.contentElementMedias) {
      values.contentElementMedias.forEach((media: MixedContentElementMediaDTO) => {
        if (!validator.isUUID(media.fileId)) {
          isUploading = true;
        }
      });
    }
    setIsAllowedToSubmit(isDirty && !isUploading && isFieldsChanged(values, touched));
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const onFlyoutClose = () => {
    setIsHistoryFlyoutShown(false);
    if (isSurgery) {
      surgeryGuideStore.clearSelectedSurgeryProcedure();
      procedureStore.setIsProcedureFormFlyoutOpen(false);
      return;
    }
    procedureStore.setIsProcedureFormFlyoutOpen(false);
    procedureStore.setSelectedProcedure(null);
    surgeryGuideStore.setSelectedSurgeryProcedure(undefined);
  };

  async function onProcedureGuidesFlyoutShow() {
    setIsProcedureGuidesFlyoutShown(true);
  }

  const headerButton = () => (
    <div className="btn_small_flyout" onClick={onHistoryFlyoutOpen}>
      <div className="txt_dropout_1">Bearbeitungsverlauf anzeigen</div>
    </div>
  );

  const onProcedureCreate = async (values: ProcedureFormValues) => {
    let procedureId: string | undefined;
    if (surgeryGuideStore.surgeryGuide && isSurgery) {
      const chapterId = surgeryGuideStore.groupedGuideProcedures[0].chapterId;
      const procedureToCreate = generateCreateSurgeryProcedureData(surgeryGuideStore.surgeryGuide.surgeryGuideId, chapterId, values);
      procedureId = await surgeryGuideStore.createSurgeryGuideProcedure(procedureToCreate).then(v => v.surgeryGuideProcedureId);
    }
    if (guideStore.selectedGuide && procedureStore.chapterIdForNewProcedures && !isSurgery) {
      const procedureToCreate = generateCreateProcedureData(
        guideStore.selectedGuide.guideId,
        procedureStore.chapterIdForNewProcedures,
        procedureStore.positionForNewProcedures,
        values
      );
      procedureId = await procedureStore.createGuideProcedure(procedureToCreate).then(v => v.guideProcedureId);
    }
    if (procedureId) {
      animationStore.setElementIdToShake([procedureId]);
    }

    onFlyoutClose();
  };

  const onProcedureUpdate = async (data: ProcedureFormValues) => {
    if (surgeryGuideStore.selectedSurgeryProcedure && isSurgery) {
      animationStore.setElementIdToShake([surgeryGuideStore.selectedSurgeryProcedure.surgeryGuideProcedureId]);
      await surgeryGuideStore.updateSurgeryGuideProcedure({
        content: {
          title: data.title,
          description: data.description,
          contentElementMedias: prepareMediasToSave(data.contentElementMedias)
        },
        surgeryGuideProcedureId: surgeryGuideStore.selectedSurgeryProcedure.surgeryGuideProcedureId
      });
    }
    if (guideStore.selectedGuide && procedureStore.selectedProcedure) {
      animationStore.setElementIdToShake([procedureStore.selectedProcedure.guideProcedureId]);
      await procedureStore.updateSelectedProcedure(
        {
          title: data.title,
          description: data.description,
          contentElementMedias: prepareMediasToSave(data.contentElementMedias)
        },
        guideStore.selectedGuide.guideId,
        data.createPost
      );
    }
    onFlyoutClose();
  };

  const onSubmit = (data: ProcedureFormValues) => {
    if (procedureStore.selectedProcedure || surgeryGuideStore.selectedSurgeryProcedure) {
      onProcedureUpdate(data);
    } else {
      onProcedureCreate(data);
    }
  };

  const getTitle = () => {
    if (procedureStore.selectedProcedure && procedureStore.selectedProcedure.linkCount > 0) return t('updateProcedure.headlineForLink');
    if (procedureStore.selectedProcedure) return t('updateProcedure.headline');
    return t('createProcedure.headline');
  };

  return (
    <FlyoutContainer
      icon={
        procedureStore.selectedProcedure ? (
          <img src="images/icon_edit.jpg" width="50" alt="" className="image_circle_40" />
        ) : (
          <img src="images/icon_new_1.jpg" width="50" alt="" className="image_circle_40" />
        )
      }
      isOpen={procedureStore.isProcedureFormFlyoutOpen}
      closePopUp={onFlyoutClose}
      cancelLabel={itemFormFlyOutTranslate('button.cancel')}
      onSubmit={handleSubmitMyForm}
      submitLabel={itemFormFlyOutTranslate('button.submit')}
      isAllowedToSubmit={isAllowedToSubmit}
      title={getTitle()}
      headerButton={!isSurgery && procedureStore.selectedProcedure && headerButton()}
    >
      <Form initialValues={initialValues} bindSubmitForm={bindSubmitForm} bindAllowedToSubmit={bindAllowedToSubmit} onSubmit={onSubmit}>
        {form => {
          return (
            <div className="flyout_scroll_wrapper">
              {procedureStore.selectedProcedure && procedureStore.selectedProcedure.linkCount > 0 && (
                <div className="flyout_box border">
                  <div className="t2 top-margin">
                    {t('updateProcedure.label.connectWith', {
                      count: procedureStore.selectedProcedure.linkCount
                    })}
                  </div>
                  {procedureStore.procedureGuides && procedureStore.procedureGuides?.length > 0 && (
                    <div className="btn_small_flyout" onClick={onProcedureGuidesFlyoutShow}>
                      <div className="txt_dropout_1">{t('button.moreInfo')}</div>
                    </div>
                  )}
                </div>
              )}
              {!isSurgery && (
                <div className="flyout_box border">
                  <div className="t2 top-margin">{t('showUpdate')}</div>
                  <div className="div-block-11">
                    <div>
                      {form.values.createPost ? (
                        <span style={{ color: '#3adf27' }}>{itemFormFlyOutTranslate('label.showUpdateYes')}</span>
                      ) : (
                        itemFormFlyOutTranslate('label.showUpdateNo')
                      )}
                    </div>
                    <div className="div-block-9">
                      <InputField name="createPost" component={Switch} />
                    </div>
                  </div>
                </div>
              )}
              <div className="flyout_box">
                <div>{itemFormFlyOutTranslate('label.title')}</div>
                <InputField name="title" placeholder={itemFormFlyOutTranslate('label.titlePlaceholder')} />
              </div>
              <div className="flyout_box">
                <div>{itemFormFlyOutTranslate('label.description')}</div>
                <TextareaField name="description" placeholder={itemFormFlyOutTranslate('label.descriptionPlaceholder')} />
              </div>
              <div className="flyout_box">
                <UploadMultipleFiles
                  saveFile={saveFileContentElement}
                  meta={form}
                  files={procedureStore.selectedProcedure?.content.contentElementMedias || []}
                  name="contentElementMedias"
                  reorderContentElementMedia={reorderedMedias => {
                    form.setFieldValue('contentElementMedias', reorderedMedias);
                  }}
                />
              </div>
            </div>
          );
        }}
      </Form>
      <ProcedureGuidesFlyout
        relatedGuides={procedureStore.procedureGuides}
        isOpen={isProcedureGuidesFlyoutShown}
        onClose={() => setIsProcedureGuidesFlyoutShown(false)}
      />
      <ProcedureContentElementHistory isOpen={isHistoryFlyoutShown} onClose={() => setIsHistoryFlyoutShown(false)} />
    </FlyoutContainer>
  );
});

export default ProcedureFormFlyout;
