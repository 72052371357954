import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { GroupedGuideMaterialsDTO } from 'dto/guide';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { Can, subjectArea, actions } from 'casl/setupCaslAbility';
import { subject } from '@casl/ability';
import GuideMaterialList from './GuideMaterialList';

interface Props {
  guideMaterialGroup: GroupedGuideMaterialsDTO;
  className: string;
  isClone?: boolean;
  noDrag?: boolean;
}

const GuideMaterialGroup = observer(({ guideMaterialGroup, className, isClone = false, noDrag = false }: Props) => {
  const { t } = useTranslation('guideMaterials');
  const { t: searchTranslation } = useTranslation('materialSearch');
  const { guideStore, searchStore } = useStores();

  const handleAddMaterialToGuide = () => {
    guideStore.setGuideCategoryIdForAddMaterial(guideMaterialGroup.categoryId);
    searchStore.openSearchPanel(m => guideStore.addMaterialToGuide(m), searchTranslation('searchItem.addItem'));
  };

  return (
    <>
      <div className={className}>{guideMaterialGroup.category}</div>
      <Can I={actions.add} this={subject(subjectArea.guideMaterial, { departmentId: guideStore.selectedGuide?.departmentId })}>
        {!isClone && (
          <ListItemAdd className="list_item_material_add" onClick={handleAddMaterialToGuide}>
            <InfoBox label={t('button.addMaterial')} />
          </ListItemAdd>
        )}
      </Can>
      <GuideMaterialList materials={guideMaterialGroup.materials} isClone={isClone} noDrag={noDrag} />
    </>
  );
});

export default GuideMaterialGroup;
