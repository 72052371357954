/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import MaterialDetailRightMenu from './MaterialDetailRightMenu';
import MaterialDetailForm from './MaterialDetailForm';

interface Props {
  materialId: string;
}

const MaterialGlobalFunctionsGrid = observer(({ materialId }: Props) => {
  const [loading, setLoading] = useState(false);
  const { domainStore, materialStore, appNavigationStore } = useStores();
  useEffect(() => {
    async function loadData() {
      setLoading(true);
      if (domainStore.departments.length === 0) {
        await domainStore.loadAllDepartments();
      }
      if (materialStore.materialDetail?.materialId !== materialId) await materialStore.loadMaterial(materialId);
      setLoading(false);
    }
    loadData();
  }, [domainStore, materialStore, materialId]);

  appNavigationStore.useSubPageIdSetter('detail');

  // set the component for the right menu
  useEffect(() => {
    appNavigationStore.setRightMenuBuilder(() => {
      return <MaterialDetailRightMenu />;
    });
  }, [appNavigationStore]);
  if (!loading && materialStore.materialDetail) {
    return (
      // <div id="grid-guides-wrapper_content" className="wrapper_content">
      <MaterialDetailForm
        departments={domainStore.departments}
        materialDetail={materialStore.materialDetail}
        departmentsDropdownOptions={domainStore.departmentsDropdownOptions}
      />
      // </div>
    );
  }
  return null;
});

export default MaterialGlobalFunctionsGrid;
