import React from 'react';
import { InstrumentDTO } from 'dto/instrument';
import HoverWrapper from 'components/HoverWrapper';
import Popover from 'components/Popover';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import GetCameraImageButton from 'components/GetCameraImageButton';
import MaterialValueTags from 'components/MaterialValueTags';
import Picture from 'components/Picture';
import SurgeryInstrumentListItemMenu from './SurgeryInstrumentListItemMenu';
import './SurgeryInstrumentListItem.css';

interface Props {
  instrument: InstrumentDTO;
}

const SurgeryInstrumentListItem = ({ instrument }: Props) => {
  return (
    <HoverWrapper className="surgery-instrument-item">
      {({ hover }) => {
        return (
          <div className="list_item_material margin-right w-inline-block package-info image-border-container">
            <div className="list_item_material margin-right image-border-container">
              <div className="item_count">
                <div>{instrument.amount}</div>
              </div>
              <div className="image_wrapper_50">
                {instrument.material.picture ? (
                  <Picture src={instrument.material.picture} width={50} alt="" className="image_circle_50" />
                ) : (
                  <GetCameraImageButton buttonStyle="icon" description="" picturesOnly isAddable />
                )}
                <div className="material_lvl1_line lvl-line">
                  <div className="dot in_mat_list" />
                </div>
              </div>
              <div className="material_info instrument-info">
                <div className="material_text">
                  <div>{instrument.material.name}</div>
                </div>
                <MaterialValueTags instrument={instrument} />
                <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                  {({ handleClose, isOpen }) => <SurgeryInstrumentListItemMenu handleClose={handleClose} isOpen={!!isOpen} />}
                </Popover>
              </div>
            </div>
          </div>
        );
      }}
    </HoverWrapper>
  );
};

export default SurgeryInstrumentListItem;
