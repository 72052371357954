import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import SmallFlyoutContainer from 'components/FlyoutContainer/SmallFlyoutContainer';
import { UserDTO } from 'dto/user';
import CustomInput from 'components/CustomInput';
import GuideOwnerListItemBody from '../../ownerList/GuideOwnerListItemBody';

interface Props {
  handleUserSelection: (user: UserDTO) => void;
  isSelectUserFlyoutOpen: boolean;
  closeSelectUserFlyout: () => void;
  preselectedUser: UserDTO | null;
}

interface UserGroup {
  groupChar: string;
  users: UserDTO[];
}

const SelectUserFlyout = observer((props: Props) => {
  const { t } = useTranslation('selectUserFlyout');
  const { userStore } = useStores();

  const [filterTerm, setFilterTerm] = useState('');
  const [filteredGroupedUsers, setFilteredGroupedUsers] = useState<UserGroup[]>([]);

  const groupUsers = (users: UserDTO[]) => {
    const groupedUsers: UserGroup[] = [];
    users.forEach(user => {
      if (groupedUsers.some(userGroup => userGroup.groupChar === user.lastName[0])) {
        const relevantGroup = groupedUsers.find(userGroup => userGroup.groupChar === user.lastName[0]);
        if (relevantGroup) relevantGroup.users.push(user);
      } else {
        const groupedUser: UserGroup = { groupChar: user.lastName[0], users: [user] };
        groupedUsers.push(groupedUser);
      }
    });
    setFilteredGroupedUsers(groupedUsers);
  };

  useEffect(() => {
    const filterUsers = (allUsers: UserDTO[]) => {
      if (!filterTerm) {
        groupUsers(allUsers);
      }
      if (allUsers) {
        const filteredUsers = allUsers.filter(user => {
          const bothNames = user.firstName.toLowerCase() + user.lastName.toLowerCase();
          const reversedBothNames = user.lastName.toLowerCase() + user.firstName.toLowerCase();
          return bothNames.includes(filterTerm) || reversedBothNames.includes(filterTerm);
        });
        groupUsers(filteredUsers);
      }
    };

    const allUsers = userStore.allUsers || [];
    filterUsers(allUsers);
  }, [filterTerm, userStore.allUsers]);

  return (
    <SmallFlyoutContainer
      isOpen={props.isSelectUserFlyoutOpen}
      closePopUp={props.closeSelectUserFlyout}
      title={t('headline')}
      className="small_flyout_wrapper-copy"
      cancelLabel={t('closeButton')}
    >
      <>
        <CustomInput
          isSearchInput
          containerClassName="input_field_settings non-bold with_search"
          onChange={event => setFilterTerm(event.target.value ? event.target.value.toLowerCase().replace(/ /g, '') : '')}
          placeholder={t('searchFieldPlaceholder')}
        />
        {filteredGroupedUsers.map(userGroup => (
          <>
            <div className="sticky_head white">
              <div className="div-block-139">
                <div>{userGroup.groupChar}</div>
                <div className="info_box counter">
                  <div>{userGroup.users.length}</div>
                </div>
              </div>
            </div>

            <div className="content_holder_list">
              {userGroup.users.map(user => {
                const isSelected = props.preselectedUser && props.preselectedUser.userId === user.userId;
                const className = isSelected ? 'list_item_name selected in_user_mgmt' : 'list_item_name  in_user_mgmt';
                return (
                  <div className={className} key={user.userId} onClick={() => props.handleUserSelection(user)}>
                    <GuideOwnerListItemBody user={user} disableMenu className="flex" selected={isSelected} withUserInfoBoxRight={false} />
                  </div>
                );
              })}
            </div>
          </>
        ))}
      </>
    </SmallFlyoutContainer>
  );
});

export default SelectUserFlyout;
