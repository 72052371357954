import React from 'react';
import Button from 'components/Form/Button';
import './SurgeryDropdownButton.css';

interface Props {
  onClick: () => void;
  icon: string;
  className: string;
  mainText: string;
  secondaryText?: string;
}

const SurgeryDropdownButton = ({ onClick, icon, className, mainText, secondaryText }: Props) => {
  return (
    <Button className={`surgery-dropdown-button ${className}`} onClick={onClick}>
      <img src={`images/${icon}`} alt="" className="image_function_checkbox surgery-dropdown-button-image" />
      <div>
        {mainText}{' '}
        {secondaryText && (
          <span className="text-span-5">
            <br />
            {secondaryText}
          </span>
        )}
      </div>
    </Button>
  );
};

export default SurgeryDropdownButton;
