import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'util/mobx/stores';
import Shake from 'components/Shake';
import { PictureDTO, OptionalPictureDTO } from 'dto/file';
import { SurgeryProcedureDTO } from 'dto/surgeryGuideProcedure';
import PostMediaList from 'components/PostItem/PostMediaList';
import Popover from 'components/Popover';
import HoverWrapper from 'components/HoverWrapper';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import ImageCircle from 'components/ImageCircle';
import SurgeryProcedureStatusBox from './SurgeryProcedureStatusBox';
import SurgeryProcedureCheckItem from './SurgeryProcedureCheckItem';
import SurgeryProcedureMenu from './SurgeryProcedureMenu';

interface Props {
  procedure: SurgeryProcedureDTO;
}

const SurgeryProcedureListItem = observer(({ procedure }: Props) => {
  const { animationStore, surgeryGuideStore } = useStores();

  const image: PictureDTO | OptionalPictureDTO | undefined = procedure.materialPicture || {};

  if (procedure.materialLikeId?.templateId) {
    image.picture = 'images/icon_layer.jpg';
  }

  const onStatusChanged = (checked: boolean) => {
    surgeryGuideStore.updateProcedure({
      surgeryGuideProcedureId: procedure.surgeryGuideProcedureId,
      checked
    });
  };

  return (
    <HoverWrapper className="post_item in_prozplan">
      {({ hover }) => (
        <>
          {procedure.content.isCheckable && (
            <SurgeryProcedureCheckItem
              onStatusChanged={onStatusChanged}
              status={procedure.checked}
              procedureId={procedure.surgeryGuideProcedureId}
            />
          )}
          <Shake
            className="post_box in_prozplan"
            active={animationStore.elementIdsToShake?.indexOf(procedure.surgeryGuideProcedureId) > -1}
          >
            <div className="h2">{procedure.content.title}</div>
            <div className="txt_content">{procedure.content.description}</div>
            <PostMediaList files={procedure.content.contentElementMedias} />
            <SurgeryProcedureStatusBox procedure={procedure} />
            {procedure.linkCount === 0 ? (
              <>
                <div className="btn_show_detail_post">
                  <Popover trigger={<ArrowDotsVerticalIcon dotsOnly direction={!hover} />}>
                    {({ handleClose, isOpen }) => (
                      <SurgeryProcedureMenu handleClose={handleClose} isOpen={!!isOpen} surgeryProcedure={procedure} />
                    )}
                  </Popover>
                </div>
                <ImageCircle
                  className="image_circle_40_post"
                  user={procedure.content.user}
                  highlight={false}
                  noWrapper
                  withUserInfoBoxLeft
                />
              </>
            ) : (
              <></>
            )}
          </Shake>
        </>
      )}
    </HoverWrapper>
  );
});

export default SurgeryProcedureListItem;
