import React from 'react';
import HoverWrapper from 'components/HoverWrapper';
import LinkWrapper from 'components/LinkWrapper';
import { useStores } from 'util/mobx/stores';
import { GuideDTO } from 'dto/guide';
import BookListItemBody from './BookListItemBody';

interface Props {
  guide: GuideDTO;
  routePath: string;
}

const BookListItem = (props: Props) => {
  const { guideStore, procedureStore } = useStores();

  const onClickHandler = () => {
    // Reset selected guide to prevent short display of the old one.
    // The new selected guide is loaded later. (GuideMaterialsContent)
    if (guideStore.guideMaterialsToClone) {
      guideStore.getGuideMaterialsCloneFlyout(props.guide.guideId);
      guideStore.setSelectedGuideCloneFlyout(props.guide);
      return;
    }

    if (guideStore.guideProcedureToClone) {
      procedureStore.loadProceduresClone(props.guide.guideId);
      guideStore.setSelectedGuideCloneFlyout(props.guide);
      return;
    }
    guideStore.setSelectedGuide(props.guide);
  };

  return (
    <>
      {guideStore.guideMaterialsToClone || guideStore.guideProcedureToClone ? (
        <div onClick={onClickHandler} style={{ width: '100%' }}>
          <HoverWrapper className="list_item_book in_flyout">
            {({ hover }) => <BookListItemBody guide={props.guide} hover={hover} />}
          </HoverWrapper>
        </div>
      ) : (
        <LinkWrapper onClick={onClickHandler} routePath={props.routePath} fullWidth>
          <HoverWrapper className="list_item_book">{({ hover }) => <BookListItemBody guide={props.guide} hover={hover} />}</HoverWrapper>
        </LinkWrapper>
      )}
    </>
  );
};
export default BookListItem;
