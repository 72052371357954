/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsUUID, ValidateNested, IsInt } from 'class-validator';
import 'reflect-metadata';
import { Type } from 'class-transformer';
import { MaterialDTO } from './material';
import { TagDTO } from './tag';

export class GroupMaterialDTO {
  @IsUUID()
  groupMaterialId!: string;

  @ValidateNested()
  @Type(() => MaterialDTO)
  material!: MaterialDTO;

  @ValidateNested({ each: true })
  @Type(() => TagDTO)
  tags!: TagDTO[];
}

export class CreateGroupMaterialDTO {
  @IsUUID()
  setGroupId!: string;

  @IsUUID()
  materialId!: string;

  @IsInt()
  position!: number;
}

export class UpdateSortGroupMaterialsDTO {
  @IsUUID(undefined, { each: true })
  groupMaterialIds!: string[];
}
