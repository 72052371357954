import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { savePackageFile } from 'api/file';
import GetCameraImageButton from 'components/GetCameraImageButton';
import { FullPackageDTO, PackagingType } from 'dto/package';
import Form, { InputField } from 'components/Form';
import Picture from 'components/Picture';
import PackageImagesThumbnails from './PackageImagesThumbnails';

interface PackageDetailForm {
  packageName: string;
  templateName: string;
  packageSerialNumber: string;
  lastSynchronisation?: string;
  lotNumber: string;
  lotNote: string;
  templateNumber: string;
  packagingType: PackagingType;
  description: string;
  manufacturerName: string;
}

interface Props {
  packageDetail: FullPackageDTO;
}

const PackageDetailForm = observer(({ packageDetail }: Props) => {
  const { instrumentStore, flyoutStore } = useStores();
  const { t } = useTranslation('packages');
  const onSubmit = (values: PackageDetailForm) => {
    if (!values || Object.keys(values).length === 0) return;
    if (values.templateName) {
      instrumentStore.updateTemplate(
        { templateName: values.templateName, templateId: packageDetail.template.templateId },
        packageDetail.pack.packageId
      );
    }
    if (values.packageName) {
      instrumentStore.updatePackage({ packageName: values.packageName, packageId: packageDetail.pack.packageId });
    }
  };

  const initialValues: PackageDetailForm = {
    packageName: packageDetail.pack.name,
    templateName: packageDetail.template.name,
    packageSerialNumber: packageDetail.pack.serialNumber,
    templateNumber: packageDetail.template.templateNumber,
    lastSynchronisation: packageDetail.pack.lastSynchronisation || '',
    packagingType: packageDetail.template.packagingType,
    lotNote: packageDetail.pack.lotNumber?.note || '',
    lotNumber: packageDetail.pack.lotNumber?.lotNumber || '',
    description: packageDetail.pack.description || '',
    manufacturerName: packageDetail.pack.manufacturerName || ''
  };

  const onUpdateFile = async (fileId: string) => {
    const pictureFileIds = packageDetail.pack.pictures.map((file, index) => {
      if (index === 0) {
        return fileId;
      }
      return file.pictureFileId;
    });
    if (pictureFileIds.length === 0) {
      pictureFileIds.push(fileId);
    }
    await instrumentStore.updatePackage({ pictureFileIds, packageId: packageDetail.pack.packageId });
  };

  const onDeleteFile = async (fileId: string) => {
    const packageFileIds = packageDetail.pack.pictures.map(f => f.pictureFileId);
    await instrumentStore.updatePackage({
      pictureFileIds: packageFileIds.filter(id => id !== fileId),
      packageId: packageDetail.pack.packageId
    });
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  useEffect(() => {
    const handleSubmitMyForm = () => {
      if (submitMyForm) submitMyForm();
    };
    return () => {
      handleSubmitMyForm();
    };
  });

  const handleAddImagesToView = () => {
    if (packageDetail.pack.picture && packageDetail.pack.picture.picture) {
      flyoutStore.setImagesToView(packageDetail.pack.picture.picture);
    }
  };

  useEffect(() => {
    instrumentStore.loadStorageLocations({ packageId: packageDetail.pack.packageId });
  }, [instrumentStore, packageDetail.pack.packageId]);

  const erpStorageLocationsString = instrumentStore.erpStorageLocations.map(sl => sl.originStorageLocation.name).join(', ');

  return (
    <div className="single_colum_content">
      <div className="div-block-45">
        <div className="stamm_big_image_wrapper">
          <div className="div-block-101">
            <div className="image_post_wide in_data" onClick={handleAddImagesToView}>
              {packageDetail.pack.picture ? (
                <Picture alt="" src={packageDetail.pack.picture.picture} />
              ) : (
                <GetCameraImageButton buttonStyle="detailForm" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
              )}
            </div>
            <GetCameraImageButton
              isButtonVisible
              apiCall={savePackageFile}
              description={
                packageDetail.pack.picture && packageDetail.pack.picture.pictureFileId
                  ? t('packageDetail.button.toChange')
                  : t('packageDetail.button.toAdd')
              }
              onUpdateFile={onUpdateFile}
              buttonStyle="square"
              picturesOnly
              isAddable
            />
          </div>
        </div>
        {packageDetail.pack.pictures.length > 1 && (
          <PackageImagesThumbnails images={packageDetail.pack.pictures} onImageDelete={onDeleteFile} />
        )}
      </div>

      <div className="div-block-44">
        <Form initialValues={initialValues} bindSubmitForm={bindSubmitForm} onSubmit={onSubmit}>
          {() => (
            <>
              <div className="content_field">
                <div>{t('packageDetail.label.description')}</div>
                <InputField name="packageName" placeholder={t('packageDetail.label.description')} />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.currentLotNumber')}</div>
                <InputField name="lotNumber" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.setIdentificationNumber')}</div>
                <InputField name="packageSerialNumber" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.preparationForLotNumber')}</div>
                <InputField name="lotNote" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.belongingsToOverview')}</div>
                <InputField name="templateName" placeholder={t('packageDetail.label.belongingsToOverview')} />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.lastSynchronisation')}</div>
                <InputField name="lastSynchronisation" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.storageLocation')}</div>
                <InputField value={erpStorageLocationsString} name="storageLocation" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.manufacturer')}</div>
                <InputField name="manufacturerName" viewOnly />
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.type')}</div>
                <div className="input_field without_input top-margin">
                  <div className="material_note-copy">
                    {t(`packageDetail.packagingTypes.${PackagingType[packageDetail.template.packagingType].toLocaleLowerCase()}`)}
                  </div>
                </div>
              </div>
              <div className="content_field">
                <div>{t('packageDetail.label.miscellaneous')}</div>
                <InputField name="description" viewOnly />
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
});

export default PackageDetailForm;
