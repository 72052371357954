/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import MaterialSetQRScanButton from 'components/MaterialSetQRScanButton';

const InputField = () => {
  const { searchStore } = useStores();
  const [isFocus, setIsFocus] = useState(false);
  const [name, setName] = React.useState<string>('');
  const inputField: React.RefObject<HTMLInputElement> = useRef(null);

  const handleMaterialSearchChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentName = event.currentTarget.value.trim();
    setName(currentName);
    searchStore.search(currentName);
  };

  const { t } = useTranslation('materialSearch');

  const searchField = isFocus ? 'search_field search-panel active' : 'search_field search-panel';

  const handleScan = () => {
    searchStore.closeSearchPanel();
  };

  return (
    <div className="search-input-grid">
      <div className="h1 white in_search">{t('inputField.title')}</div>
      <div
        className={searchField}
        onClick={() => {
          setIsFocus(true);
          if (inputField.current) {
            inputField.current.focus();
          }
        }}
        onBlur={() => {
          if (!name) {
            setIsFocus(false);
          }
        }}
      >
        <div className="div-block-26 search-input-container">
          <img src="images/icon_magnifier_16.svg" alt="" className="image_search image_search_input" />
          {isFocus ? (
            <input
              type="text"
              onKeyUp={handleMaterialSearchChange}
              className="search_material_input"
              ref={inputField}
              autoFocus
              maxLength={255}
            />
          ) : (
            <div>{t('inputField.placeholder')}</div>
          )}
        </div>
        <MaterialSetQRScanButton className="txt_dropout_1" onScan={handleScan} />
      </div>
    </div>
  );
};

export default InputField;
