import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { updateSurgeryGuideMaterial } from 'api/surgeryGuide';
import { updateGuideMaterial } from 'api/guide';
import Button from 'components/Form/Button';
import { useStores } from 'util/mobx/stores';
import { MergedGuideMaterialDTO } from 'stores/guideDetailDrawerStore';
import './SettingsStorageLocationBox.css';

interface Props {
  guideItem: MergedGuideMaterialDTO;
}

const SettingsStorageLocationBox = observer(({ guideItem }: Props) => {
  const { t } = useTranslation('guideSettings');
  const { guideDetailDrawerStore, storageLocationStore, loadingStore } = useStores();

  const onUpdateGuideMaterial = async (storageLocationId: string | null) => {
    if (guideItem.guideMaterialId) {
      const updatedGuideMaterial = await loadingStore.withFlyoutLoadingBar(async () => {
        if (!guideItem.guideMaterialId) return undefined;
        return updateGuideMaterial({
          guideMaterialId: guideItem.guideMaterialId,
          storageLocationId
        });
      });
      if (updatedGuideMaterial?.storageLocationId) {
        guideDetailDrawerStore.loadStorageLocationPath(updatedGuideMaterial.storageLocationId);
      }
      storageLocationStore.setIsSelectMaterialLocationFlyoutOpen(false);
    }
    if (guideItem.surgeryGuideMaterialId) {
      const updatedGuideMaterial = await loadingStore.withFlyoutLoadingBar(async () => {
        if (!guideItem.surgeryGuideMaterialId) return undefined;
        return updateSurgeryGuideMaterial({
          surgeryGuideMaterialId: guideItem.surgeryGuideMaterialId,
          storageLocationId
        });
      });
      if (updatedGuideMaterial?.storageLocationId) {
        guideDetailDrawerStore.loadStorageLocationPath(updatedGuideMaterial.storageLocationId);
      }
      storageLocationStore.setIsSelectMaterialLocationFlyoutOpen(false);
    }
  };

  const onFlyoutOpen = async () => {
    if (guideItem.material) {
      storageLocationStore.setMaterialLikeId({ materialId: guideItem.material.materialId });
    }
    if (guideItem.materialSet) {
      storageLocationStore.setMaterialLikeId({ materialSetId: guideItem.materialSet.materialSetId });
    }
    await storageLocationStore.loadMaterialLikeLocations(guideItem.storageLocationId);
    storageLocationStore.setIsSelectMaterialLocationFlyoutOpen(true, onUpdateGuideMaterial);
  };

  const onRemoveStorageLocation = () => {
    onUpdateGuideMaterial(null);
    guideDetailDrawerStore.clearStorageLocationPath();
  };

  const renderStorageLocationAction = () => {
    if (guideDetailDrawerStore.storageLocationPath && guideDetailDrawerStore.storageLocationPath.storageLocations.length !== 0) {
      return (
        <>
          <Button className="btn_small align_left" onClick={onFlyoutOpen}>
            {t('button.storageLocation.edit')}
          </Button>
          <Button className="btn_small align_left remove-storage-button" onClick={onRemoveStorageLocation}>
            {t('remove')}
          </Button>
        </>
      );
    }
    if (guideDetailDrawerStore.materialStorageLocationPaths && guideDetailDrawerStore.materialStorageLocationPaths.length !== 0) {
      return (
        <Button className="btn_small align_left" onClick={onFlyoutOpen}>
          {t('button.storageLocation.add')}
        </Button>
      );
    }
    return '';
  };

  if (guideDetailDrawerStore.choosingLocationDisabled) {
    return null;
  }
  return (
    <div className="setting_wrapper flex">
      <div
        className={`txt_setting ${
          (guideDetailDrawerStore.storageLocationPath && guideDetailDrawerStore.storageLocationPath.storageLocations.length !== 0) ||
          (guideDetailDrawerStore.materialStorageLocationPaths && guideDetailDrawerStore.materialStorageLocationPaths.length !== 0)
            ? 'no_margin'
            : ''
        }`}
      >
        {t('removalStorageLocation')}
      </div>
      <div className="storage-locations-buttons">{renderStorageLocationAction()}</div>
    </div>
  );
});

export default SettingsStorageLocationBox;
