import React from 'react';
import LinkWrapper from 'components/LinkWrapper';
import './NavSectionButton.css';
import Button from 'components/Form/Button';

interface Props {
  icon: string;
  label: string;
  routePath?: string;
  currentPath?: string;
  isMoreNav?: boolean;
  isInPopover?: boolean;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const iconUrl = (iconName: string) => `images/${iconName}.svg`;

const NavSectionButton = ({ icon, label, routePath, currentPath, isMoreNav, isInPopover, onClick, className, disabled = false }: Props) => {
  const isActive = currentPath !== undefined && routePath === currentPath;
  const isInPopoverClassName = `${isInPopover ? 'btn_more_section button_function' : ''}`;
  const classNameString = `${
    isActive && !isMoreNav ? 'btn_section  active  max_width  w-inline-block' : 'btn_section  max_width  w-inline-block'
  }`;

  if (routePath) {
    return (
      <LinkWrapper
        replace
        routePath={routePath}
        className={isInPopover ? isInPopoverClassName : classNameString}
        autoWidth={routePath !== undefined}
        fullWidth={routePath === undefined}
        onClick={onClick}
      >
        <img src={iconUrl(icon)} alt="" className="image_section img_function" />
        <div>{label}</div>
      </LinkWrapper>
    );
  }

  const getClasses = () => {
    if (isInPopover) {
      return isInPopoverClassName;
    }
    if (className) {
      return className;
    }
    return className;
  };

  return (
    <Button className={getClasses()} onClick={onClick} disabled={disabled}>
      <img src={iconUrl(icon)} alt="" className="image_section img_function" />
      <div>{label}</div>
    </Button>
  );
};

export default NavSectionButton;
