/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { IsInt, IsString, ValidateNested, IsNotEmptyObject, IsOptional, IsBoolean, IsUUID } from 'class-validator';
import { Type } from 'class-transformer';
import { SimpleDepartmentDTO, DepartmentDTO } from './department';
import { UserDTO } from './user';
import 'reflect-metadata';
import { MaterialLikeIdDTO, MaterialLikeDTO } from './material';

export class GetGuidesDTO {
  @IsUUID()
  @IsOptional()
  departmentId?: string;

  @IsUUID()
  @IsOptional()
  functionalAreaId?: string;

  @IsUUID()
  @IsOptional()
  ownerId?: string;
}

export class GuideDTO {
  @IsUUID()
  guideId!: string;

  @IsString()
  name!: string;

  @IsInt()
  guideNumber!: number;

  @IsUUID()
  departmentId!: string;
}

export class FullGuideDTO extends GuideDTO {
  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => UserDTO)
  user!: UserDTO;

  @ValidateNested()
  @Type(() => DepartmentDTO)
  @IsOptional()
  department?: DepartmentDTO;
}

export class FullGuideDepartmentDTO extends FullGuideDTO {
  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => DepartmentDTO)
  department!: DepartmentDTO;
}

export class DepartmentGuidesDTO {
  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => SimpleDepartmentDTO)
  department!: SimpleDepartmentDTO;

  @ValidateNested({ each: true })
  @Type(() => GuideDTO)
  guides!: GuideDTO[];
}

export class UserGuidesDTO {
  @ValidateNested()
  @Type(() => UserDTO)
  user!: UserDTO;

  @ValidateNested({ each: true })
  @Type(() => DepartmentGuidesDTO)
  departments!: DepartmentGuidesDTO[];
}

export class NotesDTO {
  @IsOptional()
  @IsString()
  circular?: string;

  @IsOptional()
  @IsString()
  preparer?: string;

  @IsOptional()
  @IsString()
  sterile?: string;

  @IsBoolean()
  unpackOnInstruction!: boolean;
}

export class GuideMaterialLikeDTO extends MaterialLikeDTO {
  @IsUUID()
  guideMaterialId!: string;

  @IsUUID()
  @IsOptional()
  storageLocationId?: string;

  @IsInt()
  amount!: number;

  @ValidateNested()
  @IsNotEmptyObject()
  @Type(() => NotesDTO)
  notes!: NotesDTO;
}

export class CreateGuideMaterialDTO extends MaterialLikeIdDTO {
  @IsUUID()
  guideId!: string;

  @IsUUID()
  guideMaterialCategoryId!: string;

  @IsInt()
  amount!: number;

  @IsNotEmptyObject()
  @ValidateNested()
  @Type(() => NotesDTO)
  notes!: NotesDTO;
}

export class UpdateNotesDTO {
  @IsOptional()
  @IsString()
  circular?: string;

  @IsOptional()
  @IsString()
  preparer?: string;

  @IsOptional()
  @IsString()
  sterile?: string;

  @IsOptional()
  @IsBoolean()
  unpackOnInstruction?: boolean;
}

export class UpdateGuideMaterialDTO {
  @IsUUID()
  guideMaterialId!: string;

  @IsOptional()
  @IsUUID()
  guideMaterialCategoryId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => MaterialLikeIdDTO)
  material?: MaterialLikeIdDTO;

  @IsOptional()
  @IsInt()
  amount?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => UpdateNotesDTO)
  notes?: UpdateNotesDTO;

  @IsOptional()
  @IsUUID()
  storageLocationId?: string | null;
}

export class GroupedGuideMaterialsDTO {
  @IsString()
  category!: string;

  @IsUUID()
  categoryId!: string;

  @ValidateNested({ each: true })
  @Type(() => GuideMaterialLikeDTO)
  materials!: GuideMaterialLikeDTO[];
}

export class UpdateSortGuideMaterialsDTO {
  @IsUUID()
  categoryId!: string;

  @IsUUID(undefined, { each: true })
  guideMaterialIds!: string[];
}

export class GuideIdDTO {
  @IsUUID()
  guideId!: string;
}

export class DeleteGuideDTO {
  @IsUUID()
  @IsOptional()
  guideId?: string;

  @IsUUID()
  @IsOptional()
  ownerId?: string;

  @IsUUID()
  @IsOptional()
  departmentId?: string;
}

export class CreateGuideDTO {
  @IsUUID()
  departmentId!: string;

  @IsUUID()
  ownerId!: string;

  @IsString()
  name!: string;
}

export class UpdateGuideDTO {
  @IsUUID()
  guideId!: string;

  @IsOptional()
  @IsUUID()
  departmentId?: string;

  @IsOptional()
  @IsUUID()
  ownerId?: string;

  @IsOptional()
  @IsString()
  name?: string;
}

export class CloneGuideDTO {
  @IsUUID()
  sourceGuideId!: string;
}

export class RelinkGuideMaterialDTO {
  @IsOptional()
  @IsUUID()
  guideId?: string;

  @ValidateNested()
  @Type(() => MaterialLikeIdDTO)
  oldId!: MaterialLikeIdDTO;

  @ValidateNested()
  @Type(() => MaterialLikeIdDTO)
  newId!: MaterialLikeIdDTO;
}

export class CloneGuideMaterialsDTO {
  @IsUUID()
  sourceGuideId!: string;

  @IsUUID()
  targetGuideId!: string;
}

export class DeleteGuideMaterialDTO extends MaterialLikeIdDTO {
  @IsUUID()
  guideId!: string;
}
