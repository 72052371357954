import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from 'util/mobx/stores';
import FlyoutContainer from 'components/FlyoutContainer';
import CloneMaterialKnowledgeList from './CloneMaterialKnowledgeList';

export default observer(() => {
  const { t } = useTranslation('guide');
  const { guideStore, procedureStore, animationStore } = useStores();
  const [linkMaterialKnowledgeIds, setLinkMaterialKnowledgeIds] = useState<string[]>([]);
  const closePopUp = () => {
    guideStore.setShowCloneMaterialKnowledgeFlyout(false);
    guideStore.resetSelectedGuideOwnerFromGuidesCloneFlyout();
    setLinkMaterialKnowledgeIds([]);
  };

  const onSubmit = async () => {
    let procedures;
    if (guideStore.selectedGuide && linkMaterialKnowledgeIds.length > 0 && procedureStore.chapterIdForNewProcedures) {
      procedures = await procedureStore.bulkLinkMaterialKnowledgeToProcedure({
        guideId: guideStore.selectedGuide.guideId,
        guideChapterId: procedureStore.chapterIdForNewProcedures,
        position: procedureStore.positionForNewProcedures,
        linkMaterialKnowledgeIds
      });
    }
    closePopUp();
    if (procedures !== undefined) {
      animationStore.setElementIdToShake(procedures.map(procedure => procedure.guideProcedureId));
    }
  };

  const handleAddLinkMaterialKnowledgeId = (materialKnowledgeId?: string) => {
    if (materialKnowledgeId) {
      if (linkMaterialKnowledgeIds.includes(materialKnowledgeId)) {
        const linkMaterialKnowledgeIdsWithoutCurrentId = linkMaterialKnowledgeIds.filter(id => id !== materialKnowledgeId);
        setLinkMaterialKnowledgeIds(linkMaterialKnowledgeIdsWithoutCurrentId);
        return;
      }
      setLinkMaterialKnowledgeIds([...linkMaterialKnowledgeIds, materialKnowledgeId]);
    }
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_import.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={guideStore.isCloneMaterialKnowledgeFlyout}
      closePopUp={closePopUp}
      onSubmit={onSubmit}
      submitLabel={t('cloneMaterialKnowledge.button.submit')}
      isAllowedToSubmit={linkMaterialKnowledgeIds.length > 0}
      title={t('cloneMaterialKnowledge.headline')}
      cancelLabel={t('cloneMaterialKnowledge.button.cancel')}
    >
      <div className="flyout_wrapper_more_content" style={{ display: 'block', marginBottom: 0 }}>
        <div className="flyout_box full_height">
          <div>{t('cloneMaterialKnowledge.label.chooseMaterialKnowledge')}</div>
          <div className="menu_list_scrollable in_flyout">
            <div className="post_item_head_topic">
              <CloneMaterialKnowledgeList
                handleAddLinkMaterialKnowledgeId={handleAddLinkMaterialKnowledgeId}
                isKnowledgeSelected={materialKnowledgeId => {
                  return linkMaterialKnowledgeIds.includes(materialKnowledgeId);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </FlyoutContainer>
  );
});
