import React from 'react';
import Lottie from 'react-lottie';
import Button from 'components/Form/Button';
import animationData from './arrow_dots_vertical.json';
import './ArrowDotsVerticalIcon.css';

interface Props {
  direction?: boolean;
  handleClick?: () => void;
  onClick?: () => void;
  dotsOnly?: boolean;
}

export default ({ direction = false, handleClick, onClick, dotsOnly = false }: Props) => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData
  };

  function click<T>(event: React.MouseEvent<T>) {
    if (onClick) {
      onClick();
    }
    event.preventDefault();
    if (handleClick) handleClick();
    else console.log('WARNING: no click handler implemented / TODO');
  }

  const size = 40;

  if (dotsOnly) {
    return (
      <div className="btn_show_detail" onClick={event => click(event)}>
        <img src="images/icon_menu_dots.svg" alt="" className="image_dots_post image-dots-post" />
      </div>
    );
  }

  return (
    <>
      {/* Desktop */}
      <Button className="btn_arrow_menu" onClick={event => click(event)}>
        <Lottie options={defaultOptions} direction={direction ? -1 : 1} height={size} width={size} speed={1.5} />
      </Button>

      {/* Mobile */}
      <Button type="button" onClick={event => click(event)} className="mobile_btn_arrow_menu">
        <img src="images/icon_menu_dots.svg" style={{ filter: 'saturate(0%) brightness(167%)' }} alt="" className="image_dots" />
      </Button>
    </>
  );
};
