import React, { useEffect } from 'react';
import { useStores } from 'util/mobx/stores';
import { CreatePostContentElementDTO } from 'dto/contentElement';
import { getAnimationState } from 'util/animation';
import ChatForm from '../ChatForm';
import './ChatBar.css';

interface Props {
  placeholder: string;
  createPost: (post: CreatePostContentElementDTO) => void;
  boardOnly?: boolean;
}

const ChatBar = ({ placeholder, createPost, boardOnly = false }: Props) => {
  const { postStore } = useStores();
  const onBoardShow = () => postStore.setIsChatBoardFlyoutShown(true);
  useEffect(() => {
    return () => {
      postStore.setIsChatBarExpanded(null);
    };
  }, [postStore]);
  return (
    <div
      id="grid-guides-wrapper_chatbar"
      className={`wrapper_chatbar-copy wrapper-chatbar ${getAnimationState(postStore.isChatBarExpanded, 'expanded', 'collapsed')}`}
    >
      <div className="div-block-57">
        <div className="btn_board" onClick={onBoardShow}>
          <img src="images/icon_f-comment_16.svg" alt="" />
        </div>
      </div>
      {!boardOnly && (
        <div className="div-block-72">
          <ChatForm placeholder={placeholder} createPost={createPost} />
        </div>
      )}
    </div>
  );
};

export default ChatBar;
