import React from 'react';

import './SurgeryDetails.css';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import EmptyIndicator from 'components/EmptyIndicator';
import SurgeryRoomDateInfo from '../SurgeryRoomDateInfo';
import SurgeryPatient from '../SurgeryPatient';
import SurgeryBriefing from '../SurgeryListItem/SurgeryBriefing';
import SurgeryChatAndHistory from '../SurgeryChatAndHistory';

const SurgeryDetails = observer(() => {
  const { t } = useTranslation('surgery');
  const { surgeryStore } = useStores();
  const onAddGuide = () => {
    surgeryStore.setIsSelectGuideFlyoutOpen(true);
  };
  return (
    <div id="grid-guides-colum_right" className="colum_right in_operationen surgery-details">
      <>
        <div className={`w-layout-grid grid-4 ${!surgeryStore.selectedSurgeryBriefing ? 'contains-empty-message' : ''}`}>
          <EmptyIndicator message={t('emptyInfo')} isEmpty={!surgeryStore.selectedSurgeryBriefing}>
            {surgeryStore.selectedSurgeryBriefing ? (
              <>
                <div className="div-block-119">
                  <SurgeryRoomDateInfo mobileOnly />
                  <div className="bg-grey">
                    <div className="op-briefing-board">
                      {t('briefing.title')}:
                      <br />
                      {surgeryStore.selectedSurgeryBriefing.surgery.name}
                    </div>
                    <div className="post_item_head_topic">
                      <SurgeryPatient briefing={surgeryStore.selectedSurgeryBriefing} />
                    </div>
                    {surgeryStore.selectedSurgeryBriefing.surgery.doctorFullName && (
                      <div className="note_wrapper">
                        <div className="op_info_tag">
                          <div>
                            <strong>{t('briefing.user')}:</strong> {surgeryStore.selectedSurgeryBriefing.surgery.doctorFullName}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="h1">{t('briefing.guidesTitle')}</div>
                  <ListItemAdd className="list_item_material_add in_operation" onClick={onAddGuide}>
                    <InfoBox label={t('briefing.addGuide')} />
                  </ListItemAdd>
                  <SurgeryBriefing briefing={surgeryStore.selectedSurgeryBriefing} />
                </div>
                <SurgeryChatAndHistory />
              </>
            ) : (
              <></>
            )}
          </EmptyIndicator>
        </div>
      </>
    </div>
  );
});

export default SurgeryDetails;
