import React from 'react';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  children: JSX.Element[] | JSX.Element | null;
  onCreateFlyoutOpen: () => void;
}

const SettingsBlock = ({ title, children, onCreateFlyoutOpen }: Props) => {
  const { t } = useTranslation('settings');
  return (
    <div className="setting_block">
      <div className="h1">{title}</div>
      <ListItemAdd className="post_item_add post-item-add" onClick={onCreateFlyoutOpen}>
        <InfoBox label={t('administration.createNew')} />
      </ListItemAdd>
      {children}
    </div>
  );
};

export default SettingsBlock;
