import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import SideMenuIconButton from 'components/SideMenuIconButton';

const SingleInstrumentFunctionsRightMenu = observer(() => {
  const { t } = useTranslation('singleInstruments');
  const { materialStore, instrumentStore, searchStore, guideStore } = useStores();
  const { t: searchTranslation } = useTranslation('materialSearch');

  const handlerReplaceGuidesMaterial = async () => {
    searchStore.openSearchPanel(m => {
      if (instrumentStore.fullPackageItem?.template) {
        guideStore.replaceMaterialInGuide(m, { templateId: instrumentStore.fullPackageItem?.template.templateId }).then(() => {
          if (instrumentStore.fullPackageItem) {
            materialStore.loadMaterialGuides(instrumentStore.fullPackageItem.pack.instruments[0].material.materialId);
          }
        });
      }
    }, searchTranslation('searchItem.replace'));
  };

  const handlerDeleteGuidesMaterial = () => {
    if (instrumentStore.fullPackageItem) {
      const instrument = instrumentStore.fullPackageItem.pack.instruments[0];
      materialStore.deleteGuidesMaterial({
        materialId: instrument.material.materialId
      });
    }
  };

  return (
    <>
      <SideMenuIconButton
        handleClick={handlerReplaceGuidesMaterial}
        label={t('globalFunctions.rightMenu.replaceMaterial')}
        iconPath="icon_find_16.svg"
        disabled={instrumentStore.templateGuides.length === 0}
      />
      <SideMenuIconButton
        handleClick={handlerDeleteGuidesMaterial}
        red
        label={t('globalFunctions.rightMenu.deleteMaterial')}
        iconPath="icon_bin_16.svg"
        disabled={instrumentStore.templateGuides.length === 0}
      />
    </>
  );
});

export default SingleInstrumentFunctionsRightMenu;
