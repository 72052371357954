import React from 'react';
import { PostDTO } from 'dto/post';
import ImageCircle from 'components/ImageCircle';
import PostMediaList from 'components/PostItem/PostMediaList';
import { getDayTimeWording } from 'util/date';
import { useTranslation } from 'react-i18next';

interface Props {
  post: PostDTO;
}

const SurgeryPostItem = ({ post }: Props) => {
  return (
    <div className="post_item in_operationen">
      <div className="b_chat_content in_operation">
        <ImageCircle
          className="image_circle_40_post"
          size="40"
          user={post?.contentElement.user}
          highlight={false}
          noWrapper
          withUserInfoBoxLeft
        />

        <div className="txt_content">{post.contentElement.description}</div>
        <PostMediaList files={post.contentElement.contentElementMedias} />
        <div className="t2 bottom-margin">{getDayTimeWording(post.createdAt, useTranslation('date'), true)}</div>
      </div>
    </div>
  );
};

export default SurgeryPostItem;
