import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import LocationsColumn from './LocationsColumn';
import FunctionalAreaColumn from './FunctionalAreaColumn';
import DepartmentsColumn from './DepartmentsColumn';
import GuidesChaptersColumn from './GuidesChaptersColumn';
import MaterialCategoriesColumn from './MaterialCategoriesColumn';

const SettingsAdministration = observer(() => {
  const { settingsStore, appNavigationStore } = useStores();
  const { t } = useTranslation('settings');
  useEffect(() => {
    settingsStore.loadLocations();
    settingsStore.loadGuideChapters();
    settingsStore.loadMaterialCategories();
    settingsStore.setItemFormFlyoutTitles(t('formFlyout.updateTitle'), t('formFlyout.createTitle'));
    return () => {
      settingsStore.setSelectedLocation(null);
      settingsStore.setSelectedFunctionalArea(null);
    };
  }, [settingsStore, t]);

  appNavigationStore.useSubPageIdSetter('administration');

  const renderDepartments = () => {
    if (settingsStore.selectedFunctionalArea) {
      const selectedLocation = settingsStore.locations.find(l => l.locationId === settingsStore.selectedLocation?.locationId);
      return selectedLocation?.functionalAreas ? (
        <DepartmentsColumn
          selectedFunctionalAreaId={settingsStore.selectedFunctionalArea.functionalAreaId}
          functionalAreas={selectedLocation?.functionalAreas}
        />
      ) : null;
    }
    return null;
  };

  return (
    <>
      <div className="single_colum_content-copy">
        <LocationsColumn />
        {settingsStore.selectedLocation && (
          <FunctionalAreaColumn selectedLocationId={settingsStore.selectedLocation.locationId} locations={settingsStore.locations} />
        )}
        {renderDepartments()}
        {settingsStore.selectedFunctionalArea && (
          <GuidesChaptersColumn
            selectedFunctionalAreaId={settingsStore.selectedFunctionalArea.functionalAreaId}
            guideChapters={settingsStore.guideChapters}
          />
        )}
        {settingsStore.selectedFunctionalArea && (
          <MaterialCategoriesColumn
            selectedFunctionalAreaId={settingsStore.selectedFunctionalArea.functionalAreaId}
            materialCategories={settingsStore.materialCategories}
          />
        )}
      </div>
    </>
  );
});

export default SettingsAdministration;
