import React from 'react';
import { Link } from 'react-router-dom';
import { useStores } from 'util/mobx/stores';
import { PackageDTO } from 'dto/package';
import { GuideMaterialLikeDTO } from 'dto/guide';
import ImageCircle from 'components/ImageCircle';
import HoverWrapper from 'components/HoverWrapper';
import Popover from 'components/Popover';
import GetCameraImageButton from 'components/GetCameraImageButton';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import MaterialValueTags from 'components/MaterialValueTags';
import { PictureDTO } from 'dto/file';
import GuideSingleInstrumentMenu from './GuideSingleInstrumentMenu';

interface Props {
  singleInstrument: PackageDTO;
  guideData?: GuideMaterialLikeDTO;
  isClone?: boolean;
  filterListBy?: string;
}

const SingleInstrumentListItem = ({ singleInstrument, guideData, isClone = false, filterListBy }: Props) => {
  const { guideDetailDrawerStore, guideStore, instrumentStore, domainStore } = useStores();

  const onOpenPackageDetails = () => {
    if (!isClone) {
      guideDetailDrawerStore.setSelectedPackage(singleInstrument, guideData);
      guideDetailDrawerStore.setIsOpen(true);
    }
  };

  const onDeletePackageFromGuide = () => {
    if (guideStore.selectedGuide && guideData) {
      guideStore.deleteGuideMaterial({ guideMaterialId: guideData.guideMaterialId }, guideStore.selectedGuide.guideId);
    }
  };

  const onUpdateFile = async (fileId: string) => {
    await instrumentStore.updateMaterialInPackage(
      { pictureFileId: fileId, materialId: singleInstrument.instruments[0].material.materialId },
      singleInstrument.packageId
    );
    if (filterListBy === 'all') {
      await instrumentStore.loadTemplates();
    }
    if (filterListBy === domainStore.currentDepartment.id) {
      await instrumentStore.loadTemplates(domainStore.currentDepartment.id);
    }
    if (guideStore.selectedGuide) {
      guideStore.loadGuideMaterials(guideStore.selectedGuide.guideId);
    }
  };
  const images: PictureDTO[] = [];
  const { picture, pictureFileId, pictureThumbnail } = singleInstrument.instruments[0].material;
  if (picture && pictureFileId && pictureThumbnail) {
    images.push({ picture, pictureFileId, pictureThumbnail });
  }

  const instrument = singleInstrument.instruments[0];
  return (
    <HoverWrapper
      className={`list_item_material ${guideData ? 'no_dropdown' : 'margin-right'} image-border-container package-list-item-dropdown`}
    >
      {({ hover }) => (
        <>
          <div className="item_count">
            <div>{instrument.amount}</div>
          </div>
          {images.length > 0 ? (
            <ImageCircle pictures={images} onClick={guideData && onOpenPackageDetails} instrumentLvl={guideData && 'lvl1'} />
          ) : (
            <div className="image_wrapper_50">
              <div className="image_border image-border" />
              <GetCameraImageButton buttonStyle="icon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
              {!!guideData && (
                <div className="material_lvl1_line">
                  <div className="dot in_mat_list" />
                </div>
              )}
            </div>
          )}
          {guideData ? (
            <div className="material_info w-inline-block package-info">
              <div className="material_text material-text" onClick={onOpenPackageDetails}>
                <div>{instrument.material.name}</div>
              </div>
              <MaterialValueTags pack={singleInstrument} onClick={onOpenPackageDetails} />
              {!isClone ? (
                <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                  {({ handleClose, isOpen }) => (
                    <GuideSingleInstrumentMenu
                      handleClose={handleClose}
                      isOpen={!!isOpen}
                      handleOpenDetails={onOpenPackageDetails}
                      handleDelete={onDeletePackageFromGuide}
                    />
                  )}
                </Popover>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <Link
              to={`/single-instrument/overview?packageId=${singleInstrument.packageId}`}
              className="material_info w-inline-block package-info"
            >
              <div className="material_text material-text">
                <div>{instrument.material.name}</div>
              </div>
              <MaterialValueTags pack={singleInstrument} />
              <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list image-arrow-list" />
            </Link>
          )}
        </>
      )}
    </HoverWrapper>
  );
};

export default SingleInstrumentListItem;
