import { observable, action } from 'mobx';

import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { ContentType, ProcessingStatus } from 'dto/file';

export default class FlyoutStore {
  @observable
  imageToView?: ContentElementMediaDTO | null = null;

  @observable
  pdfToView?: ContentElementMediaDTO | null = null;

  @observable
  isCreateSingleMaterialFlyoutOpen = false;

  @observable
  isCreateMaterialSetFlyoutOpen = false;

  @observable
  isHistoryFlyoutOpen = false;

  @observable
  showGuideItemDetailDrawer = false;

  @observable
  isDeleteMaterialSetFlyoutOpen = false;

  @observable
  guideItemInDrawerType?: 'material' | 'package';

  @action
  setImagesToView = (image: string | ContentElementMediaDTO | null) => {
    if (image != null && typeof image === 'string') {
      this.imageToView = {
        contentType: ContentType.Picture,
        status: ProcessingStatus.Finished,
        fileId: 'null',
        fullUrl: image,
        originalFileName: ''
      };
    } else {
      this.imageToView = image;
    }
  };

  @action
  setPdfToView = (pdfToView: ContentElementMediaDTO | null) => {
    this.pdfToView = pdfToView;
  };

  @action
  setIsCreateSingleMaterialFlyoutOpen = (isCreateSingleMaterialFlyoutOpen: boolean) => {
    this.isCreateSingleMaterialFlyoutOpen = isCreateSingleMaterialFlyoutOpen;
  };

  @action
  setIsCreateMaterialSetFlyoutOpen = (isCreateMaterialSetFlyoutOpen: boolean) => {
    this.isCreateMaterialSetFlyoutOpen = isCreateMaterialSetFlyoutOpen;
  };

  @action
  setIsHistoryFlyoutOpen = (isHistoryFlyoutOpen: boolean) => {
    this.isHistoryFlyoutOpen = isHistoryFlyoutOpen;
  };

  @action
  setIsDeleteMaterialSetFlyoutOpen(status: boolean) {
    this.isDeleteMaterialSetFlyoutOpen = status;
  }

  @action
  setShowItemDetailDrawer(showGuideItemDetailDrawer: boolean) {
    this.showGuideItemDetailDrawer = showGuideItemDetailDrawer;
    if (!showGuideItemDetailDrawer) {
      this.guideItemInDrawerType = undefined;
    }
  }
}
