import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import FlyoutContainer from 'components/FlyoutContainer';
import Form, { InputField, DatePicker, Select, TimePicker } from 'components/Form';

import { useStores } from 'util/mobx/stores';
import { FormikValues } from 'formik';
import { SurgeryFormValues } from 'stores/surgeryStore';
import { getCurrentDate } from 'util/date';
import CollapsibleBox from 'components/CollapsibleBox';

import './SurgeryFormFlyout.css';
import { getGenderDropdownOptions } from 'util/userUtils';

const SurgeryFormFlyout = observer(() => {
  const { surgeryStore, domainStore } = useStores();
  const { t } = useTranslation('surgery');
  const { t: genderT } = useTranslation('gender');
  const { shortName: functionalAreaShortName } = domainStore.currentFunctionalArea;

  const initialValues: SurgeryFormValues = {
    name: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.name : '',
    surgeryRoomId: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.surgeryRoomId : '',
    surgeryDate: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.surgeryDate : getCurrentDate(),
    firstName: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.firstName : undefined,
    lastName: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.lastName : undefined,
    patientNumber: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.patientNumber : undefined,
    caseNumber: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.caseNumber : undefined,
    surgeryTimeStart: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.surgeryTimeStart : undefined,
    surgeryTimeEnd: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.surgeryTimeEnd : undefined,
    comment: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.comment : undefined,
    gender: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.gender : undefined,
    birthday: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.birthday : undefined,
    doctorFullName: surgeryStore.selectedSurgeryToEdit ? surgeryStore.selectedSurgeryToEdit.doctorFullName : undefined
  };

  const [isAllowedToSubmit, setIsAllowedToSubmit] = useState(false);

  const onFlyoutClose = () => surgeryStore.setIsSurgeryFormFlyoutOpen(false);

  const bindAllowedToSubmit = (isDirty: boolean, values: FormikValues) => {
    if (values.name && values.surgeryRoomId && values.surgeryDate && isDirty) {
      setIsAllowedToSubmit(true);
    }
  };

  let submitMyForm: () => void;

  const bindSubmitForm = (submitForm: () => void) => {
    submitMyForm = submitForm;
  };

  const handleSubmitMyForm = () => {
    if (submitMyForm) submitMyForm();
  };

  const getTitle = () => {
    if (surgeryStore.selectedSurgeryToEdit) return t('surgeryFormFlyout.titleUpdate', { functionalAreaShortName });
    return t('surgeryFormFlyout.titleCreate', { functionalAreaShortName });
  };

  const onSubmit = async (data: SurgeryFormValues) => {
    if (surgeryStore.selectedSurgeryToEdit?.surgeryId) {
      await surgeryStore.updateSurgery({
        ...data,
        surgeryId: surgeryStore.selectedSurgeryToEdit.surgeryId
      });
    } else {
      await surgeryStore.createSurgery(data);
    }
    setIsAllowedToSubmit(false);
    onFlyoutClose();
  };

  return (
    <FlyoutContainer
      icon={
        surgeryStore.selectedSurgeryToEdit ? (
          <img src="images/icon_edit.jpg" width="50" alt="" className="image_circle_40" />
        ) : (
          <img src="images/icon_new_1.jpg" width="50" alt="" className="image_circle_40" />
        )
      }
      isOpen={surgeryStore.isSurgeryFormFlyoutOpen}
      closePopUp={onFlyoutClose}
      cancelLabel={t('surgeryFormFlyout.buttons.cancel')}
      onSubmit={handleSubmitMyForm}
      submitLabel={
        surgeryStore.selectedSurgeryToEdit ? t('surgeryFormFlyout.buttons.submitUpdate') : t('surgeryFormFlyout.buttons.submitCreate')
      }
      isAllowedToSubmit={isAllowedToSubmit}
      title={getTitle()}
    >
      <Form
        isOnlyChangedValues={false}
        initialValues={initialValues}
        bindSubmitForm={bindSubmitForm}
        bindAllowedToSubmit={bindAllowedToSubmit}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <div className="div-block-131">
              <div className="flyout_box">
                <div>{t('surgeryFormFlyout.labels.name')}</div>
                <InputField name="name" placeholder={t('surgeryFormFlyout.labels.namePlaceholder')} />
              </div>
              <div className="flyout_box">
                <div>{t('surgeryFormFlyout.labels.room')}</div>
                <InputField
                  className="input_field top-margin"
                  mappedOptions={surgeryStore.roomsDropdownOptions}
                  name="surgeryRoomId"
                  component={Select}
                  placeholder={t('surgeryFormFlyout.labels.roomPlaceholder')}
                />
              </div>
              <div className="flyout_box">
                <div>{t('surgeryFormFlyout.labels.date')}</div>
                <InputField name="surgeryDate" component={DatePicker} placeholder={t('surgeryFormFlyout.labels.datePlaceholder')} />
              </div>
              <div className="flyout_box no_padding_top_bottom">
                <div className="post_item_head_topic">
                  <CollapsibleBox
                    headClassName="dropdown_content_head in_op_planer"
                    head={<div>{t('surgeryFormFlyout.extendMaterData')}</div>}
                  >
                    <div className="content_holder in_op_planer">
                      <div className="div-block-132">
                        <div className="flyout_box no_padding_left">
                          <div>{t('surgeryFormFlyout.labels.patientName')}</div>
                          <InputField name="lastName" placeholder={t('surgeryFormFlyout.labels.patientNamePlaceholder')} />
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{t('surgeryFormFlyout.labels.patientFirstName')}</div>
                          <InputField name="firstName" placeholder={t('surgeryFormFlyout.labels.patientFirstNamePlaceholder')} />
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{t('surgeryFormFlyout.labels.dateOfBirth')}</div>
                          <InputField
                            name="birthday"
                            component={DatePicker}
                            placeholder={t('surgeryFormFlyout.labels.dateOfBirthPlaceholder')}
                          />
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{t('surgeryFormFlyout.labels.patientId')}</div>
                          <InputField name="patientNumber" placeholder={t('surgeryFormFlyout.labels.patientIdPlaceholder')} />
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{t('surgeryFormFlyout.labels.gender')}</div>
                          <InputField
                            className="input_field top-margin"
                            mappedOptions={getGenderDropdownOptions(genderT)}
                            name="gender"
                            component={Select}
                            placeholder={t('surgeryFormFlyout.labels.genderPlaceholder')}
                          />
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{t('surgeryFormFlyout.labels.caseNumber')}</div>
                          <InputField name="caseNumber" placeholder={t('surgeryFormFlyout.labels.caseNumberPlaceholder')} />
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{t('surgeryFormFlyout.labels.time')}</div>
                          <div className="surgery-time-box">
                            <InputField
                              name="surgeryTimeStart"
                              component={TimePicker}
                              className="time-left"
                              placeholder={t('surgeryFormFlyout.labels.timePlaceholder')}
                            />
                            <InputField
                              className="time-right"
                              name="surgeryTimeEnd"
                              component={TimePicker}
                              placeholder={t('surgeryFormFlyout.labels.timePlaceholder')}
                            />
                          </div>
                        </div>
                        <div className="flyout_box no_padding_left">
                          <div>{t('surgeryFormFlyout.labels.operator')}</div>
                          <InputField name="doctorFullName" placeholder={t('surgeryFormFlyout.labels.operatorPlaceholder')} />
                        </div>
                        <div className="flyout_box no_padding_left_right">
                          <div>{t('surgeryFormFlyout.labels.specialRemark')}</div>
                          <InputField viewOnly name="comment" placeholder={t('surgeryFormFlyout.labels.specialRemarkPlaceholder')} />
                        </div>
                      </div>
                    </div>
                  </CollapsibleBox>
                </div>
              </div>
            </div>
          );
        }}
      </Form>
    </FlyoutContainer>
  );
});

export default SurgeryFormFlyout;
