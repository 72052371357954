import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import MaterialDrawerDetailNav from 'components/MaterialDrawerDetailNav';
import { GuideMaterialLikeDTO } from 'dto/guide';
import { InstrumentDTO } from 'dto/instrument';
import { CSSTransition } from 'react-transition-group';
import { Can, subjectArea } from 'casl/setupCaslAbility';
import Button from 'components/Form/Button';
import CreateMaterialSynonymFlyout from 'components/MaterialKnowledge/CreateMaterialSynonymFlyout';
import { subject } from '@casl/ability';
import { SurgeryGuideMaterialLikeDTO } from 'dto/surgeryGuide';
import GuideSettings from '../GuideSettings';
import FixedTopPackageBox from '../MaterialDrawerContent/FixedTopMaterialBox';
import MaterialHead from '../MaterialDrawerContent/MaterialHead';
import DrawerInstrumentContent from './DrawerInstrumentContent';

interface Props {
  instrument: InstrumentDTO;
  guideTemplate?: GuideMaterialLikeDTO;
  surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO;
  onUpdateGuideMaterial: (guideMaterial?: GuideMaterialLikeDTO, surgeryGuideMaterial?: SurgeryGuideMaterialLikeDTO) => void;
}

const InstrumentDrawerContent = observer(({ instrument, guideTemplate, surgeryGuideMaterial, onUpdateGuideMaterial }: Props) => {
  const { materialStore, instrumentStore, guideDetailDrawerStore, searchStore, domainStore } = useStores();
  const [isShow, setIsShow] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(window.innerWidth >= 991);
  const { t } = useTranslation('guideSettings');

  useEffect(() => {
    if (!materialStore.materialDetail) {
      materialStore.loadMaterial(instrument.material.materialId);
    }
  });

  const handleContentWrapperScroll = (event: React.UIEvent<HTMLElement>) => {
    if (!isShow && event.currentTarget.scrollTop >= 300) {
      setIsShow(true);
    } else if (isShow && event.currentTarget.scrollTop <= 299 && event.currentTarget.scrollTop !== 0) {
      setIsShow(false);
    }
  };

  const onUpdateFile = async (fileId: string) => {
    await instrumentStore.updateMaterial({ materialId: instrument.material.materialId, pictureFileId: fileId });
    guideDetailDrawerStore.reloadSelectedInstrument();

    if (searchStore.isSearchPanelOpen) {
      searchStore.refreshSearch();
    }
  };

  return (
    <div className="div-block-14">
      <div className="div-block-12">
        <CreateMaterialSynonymFlyout
          isOpen={guideDetailDrawerStore.isCreateMaterialSynonymOpen}
          onClose={() => guideDetailDrawerStore.closeCreateMaterialSynonymFlyout()}
        />
        <div className="mat_content_wrapper" onScroll={handleContentWrapperScroll}>
          <FixedTopPackageBox material={instrument.material} isShow={isShow} onUpdateFile={onUpdateFile} />
          <div className="div-block-21" onScroll={handleContentWrapperScroll}>
            <MaterialHead material={instrument.material} onUpdateFile={onUpdateFile} />
            {materialStore.materialDetail && <DrawerInstrumentContent key={instrument.material.materialId} instrument={instrument} />}
          </div>
        </div>
        {guideTemplate && (
          <Can I="see" this={subject(subjectArea.materialSettings, { departmentId: domainStore.currentDepartment.id })}>
            <CSSTransition in={isSettingsOpen} timeout={500} classNames="settings-drawer" unmountOnExit>
              <GuideSettings
                guideMaterial={guideTemplate}
                surgeryGuideMaterial={surgeryGuideMaterial}
                hideStorageLocation
                onSettingsClose={() => setIsSettingsOpen(false)}
              />
            </CSSTransition>
          </Can>
        )}
      </div>

      <div className="div-block-13">
        <MaterialDrawerDetailNav
          detailLink={`/instrument/overview?instrumentId=${instrument.instrumentId}`}
          knowledgeLink={`/instrument/content?instrumentId=${instrument.instrumentId}`}
          onShowSettings={() => setIsSettingsOpen(true)}
          onUpdateFile={onUpdateFile}
          isGuideMaterialLike={!!guideTemplate}
        />
        <Button onClick={() => onUpdateGuideMaterial(guideTemplate, surgeryGuideMaterial)} className="btn_big_cancel active">
          <div>{t('button.close')}</div>
        </Button>
      </div>
    </div>
  );
});

export default InstrumentDrawerContent;
