import { TFunction } from 'i18next';
import { Gender } from 'dto/user';

export const getGenderDropdownOptions = (t: TFunction) => [
  {
    label: t('female'),
    value: Gender.Female
  },
  {
    label: t('male'),
    value: Gender.Male
  },
  {
    label: t('diverse'),
    value: Gender.Diverse
  }
];
