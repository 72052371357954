import React from 'react';
import MaterialKnowledge from 'components/MaterialKnowledge';
import { useStores } from 'util/mobx/stores';
import { observer } from 'mobx-react';
import { SetType } from 'dto/materialSet';
import DrawerStorageLocationContent from '../../DrawerStorageLocationContent';

const Content = observer(() => {
  const { materialSetsStore, guideDetailDrawerStore } = useStores();

  return materialSetsStore.materialSetDetail ? (
    <div className="material_content">
      <div className="material-name">{materialSetsStore.materialSetDetail.name}</div>
      {materialSetsStore.materialSetDetail.type !== SetType.Medicals ? <DrawerStorageLocationContent /> : <></>}
      <MaterialKnowledge
        isCollapsible
        materialLikeId={{ materialSetId: materialSetsStore.materialSetDetail.materialSetId }}
        onCreateSynonym={() => guideDetailDrawerStore.openCreateMaterialSynonymFlyout()}
      />
    </div>
  ) : (
    <></>
  );
});

export default Content;
