import React from 'react';
import { HistoryDTO } from 'dto/history';
import ImageCircle from 'components/ImageCircle';
import { getDayTimeWording } from 'util/date';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLProps<HTMLDivElement> {
  item: HistoryDTO;
  description: string;
  icon: JSX.Element;
  className?: string;
  showLink?: boolean;
}

const SurgeryHistoryItem = ({ item, description, icon, className = '', showLink = false, ...rest }: Props) => {
  const translate = useTranslation('date');
  return (
    <div className={`list_item_book list-item ${className}`} {...rest}>
      <div className="image_wrapper_50">
        <ImageCircle user={item.user} size="50" highlight={false} withUserInfoBoxRight />
        {icon}
      </div>
      <div className="book_info">
        <div>
          <div className="t2 bottom-margin">{getDayTimeWording(item.createdAt, translate)} </div>
          <div>{description}</div>
        </div>
      </div>
      {showLink && <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />}
    </div>
  );
};

export default SurgeryHistoryItem;
