import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import ListItemAdd from 'components/ListItemAdd';
import InfoBox from 'components/InfoBox';
import { StorageLocationListItem } from 'components/ListItems';
import { SimpleStorageLocationDTO } from 'dto/storageLocation';

interface Props {
  index: number;
  hideAddButtons?: boolean;
  whiteBorder?: boolean;
}

const ListByIndex = observer(({ index, hideAddButtons = false, whiteBorder = false }: Props) => {
  const { storageLocationStore } = useStores();
  const { t } = useTranslation('storageLocations');
  if (!storageLocationStore.storageLocationsBuffer[index]) {
    return null;
  }
  const handleCreateLocation = () => {
    storageLocationStore.setSelectedParentId(storageLocationStore.storageLocationsBuffer[index].parentStorageLocationId);
    storageLocationStore.setSelectedIndexToCreate(index);
    storageLocationStore.setIsCreateFlyoutOpen(true);
  };

  const checkActive = (location: SimpleStorageLocationDTO) => {
    if (location.storageLocationId === storageLocationStore.selectedStorageLocation?.parentStorageLocationId) {
      return true;
    }
    if (location.storageLocationId === storageLocationStore.selectedStorageLocation?.storageLocationId) {
      return true;
    }
    return false;
  };

  const selectStorageLocation = (storageLocation: SimpleStorageLocationDTO) => {
    if (storageLocationStore.selectedStorageLocation?.storageLocationId === storageLocation.storageLocationId) {
      storageLocationStore.goToPreviousIndex();
      return;
    }
    storageLocationStore.setSelectedStorageLocation(storageLocation, index);
  };

  const getHeaderByIndex = () => {
    if (index === 0) {
      return (
        <>
          <div className="h3">{t('startingPoint')}</div>
          {!hideAddButtons && (
            <ListItemAdd className="post_item_add in_list lageort" onClick={handleCreateLocation}>
              <InfoBox label={t('button.createStartPoint')} />
            </ListItemAdd>
          )}
        </>
      );
    }
    return (
      <>
        <div className="h3">{t('startingPointLocation')}</div>
        {!hideAddButtons && (
          <ListItemAdd className="post_item_add in_list lageort" onClick={handleCreateLocation}>
            <InfoBox label={t('button.createStartPointLocation')} />
          </ListItemAdd>
        )}
      </>
    );
  };

  return (
    <div className="div-block-140">
      {getHeaderByIndex()}
      <div className={`storage-location-items-list ${storageLocationStore.selectedStorageLocation ? 'with-back-button' : ''}`}>
        {storageLocationStore.storageLocationsBuffer[index].storageLocations.map(storageLocation => (
          <StorageLocationListItem
            storageLocation={storageLocation}
            key={storageLocation.storageLocationId}
            isSelected={checkActive(storageLocation)}
            onClick={() => selectStorageLocation(storageLocation)}
            whiteBorder={whiteBorder}
          />
        ))}
      </div>
    </div>
  );
});

export default ListByIndex;
