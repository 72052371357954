import { CreateStorageItemDTO, SimpleStorageItemDTO, UpdateStorageItemDTO, UpdateSortStorageItemDTO } from 'dto/storageItem';
import { post, del, patch, put } from './common';
import { validate, validateBool } from './validation';

export const createStorageItem = async (storageItem: CreateStorageItemDTO): Promise<SimpleStorageItemDTO> => {
  const newValue = await post('/storage-location/storage-item', storageItem).then(response => response.data);
  return validate(SimpleStorageItemDTO, newValue);
};

export const deleteStorageItem = async (storageItemId: string): Promise<boolean> => {
  return del('/storage-location/storage-item', { storageItemId }).then(response => validateBool(response.data));
};

export const updateStorageItem = async (updateFields: UpdateStorageItemDTO): Promise<SimpleStorageItemDTO> => {
  const updatedValue = await patch(`/storage-location/storage-item`, updateFields).then(response => response.data);
  return validate(SimpleStorageItemDTO, updatedValue);
};

export const reorderStorageItems = async (reorderedStorageItems: UpdateSortStorageItemDTO) => {
  return put('/storage-location/storage-item/positions', reorderedStorageItems).then(response => validateBool(response.data));
};
