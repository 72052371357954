import React, { useState } from 'react';
import { SurgeryDTO } from 'dto/surgery';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import moment from 'moment';
import HoverWrapper from 'components/HoverWrapper';
import GenderIcon from 'components/GenderIcon';
import Popover from 'components/Popover';
import Picture from 'components/Picture';
import GetCameraImageButton from 'components/GetCameraImageButton';
import ArrowDotsVerticalIcon from 'components/animationIcons/ArrowDotsVerticalIcon';
import CollapsibleBox from 'components/CollapsibleBox';
import { getSurgeryBriefing } from 'api/surgery';
import { BriefingDTO } from 'dto/briefing';
import { SurgeryRoomDTO } from 'dto/surgeryRoom';
import SurgeryListItemStats from './SurgeryListItemStats';
import SurgeryListItemMenu from './SurgeryListItemMenu';
import SurgeryBriefing from './SurgeryBriefing';

import './SurgeryListItem.css';

type Props = RouteComponentProps & {
  surgery: SurgeryDTO;
  index: number;
  room: SurgeryRoomDTO;
  withStats?: boolean;
  noMenu?: boolean;
  noLabels?: boolean;
  selected?: boolean;
};

const SurgeryListItem = withRouter(
  ({ surgery, index, room, withStats = false, noMenu = false, noLabels = false, selected = false, history }: Props) => {
    const { t } = useTranslation('surgery');
    const [briefing, setBriefing] = useState<BriefingDTO | undefined>();
    const { surgeryStore, domainStore } = useStores();

    const { shortName: functionalAreaShortName } = domainStore.currentFunctionalArea;

    const onChange = () => {
      surgeryStore.selectSurgeryToEdit({ ...surgery, surgeryRoomId: room.surgeryRoomId });
      surgeryStore.setIsSurgeryFormFlyoutOpen(true);
    };

    const onDelete = () => {
      surgeryStore.selectSurgeryToEdit({ ...surgery, surgeryRoomId: room.surgeryRoomId });
      surgeryStore.setIsDeleteSurgeryFlyoutOpen(true);
    };

    const onChangeContentVisibility = async (isVisible: boolean) => {
      if (isVisible) {
        const surgeryBriefing = await getSurgeryBriefing({
          functionalAreaId: domainStore.currentFunctionalArea.id,
          surgeryId: surgery.surgeryId,
          includeStatistic: true
        });
        setBriefing(surgeryBriefing);
      }
    };

    const onSelectSurgery = () => {
      history.push(`/surgery/briefing?surgeryId=${surgery.surgeryId}`);
      surgeryStore.setIsPlannerDrawerOpen(false);
    };

    const onUpdateFile = (patientPictureFileId: string) => {
      return surgeryStore.updateSurgery({ surgeryId: surgery.surgeryId, patientPictureFileId });
    };

    return (
      <HoverWrapper className={`list_item_operation surgery-list-item list-item ${selected ? 'active' : ''}`}>
        {({ hover }) => (
          <>
            <div className="div-block-112 in_op-planer" onClick={onSelectSurgery}>
              <div>
                {surgery.surgeryTimeStart && surgery.surgeryTimeEnd ? (
                  <>
                    {moment(surgery.surgeryTimeStart).format('HH:mm')} - {moment(surgery.surgeryTimeEnd).format('HH:mm')}
                  </>
                ) : (
                  <>
                    {t('surgeryListItem.alternativeHeadline', { functionalAreaShortName })} {index + 1}
                  </>
                )}
              </div>
              <div className="time_line">
                <div className="dot in_operation dot-in-operation" />
              </div>
              <div className="infbox_coments">
                <div>{surgery.postCount}</div>
                <img src="images/f-comment.svg" alt="" className="image_comment" />
              </div>
            </div>
            <div className="div-block-111">
              <div className="image_wrapper_50">
                <div className="image_border image-border" />
                {surgery.patientPicture ? (
                  <Picture src={surgery.patientPicture.pictureThumbnail} width={50} alt="" className="image_circle_50" />
                ) : (
                  <GetCameraImageButton buttonStyle="userIcon" onUpdateFile={onUpdateFile} description="" picturesOnly isAddable />
                )}
              </div>
              <div className="operation_info">
                <div className="operation_detail with_hover" onClick={onSelectSurgery}>
                  <div className="patient_info_wrapper">
                    <div>
                      {surgery.lastName && surgery.firstName && (
                        <>
                          {surgery.lastName}, {surgery.firstName}
                        </>
                      )}
                      {surgery.lastName && surgery.firstName && surgery.birthday && <> | </>}
                      {surgery.birthday && <>*{moment(surgery.birthday).format('DD.MM.YYYY')}</>}
                    </div>
                    <GenderIcon gender={surgery.gender} className="image-arrow-list" />
                  </div>
                  <div>{surgery.name}</div>
                </div>
                {!noLabels &&
                  surgery.surgeryGuideNumbers.map(surgeryGuideNumber => (
                    <div className="material_note" key={surgeryGuideNumber}>
                      <div>
                        {t('surgeryListItem.caseNumber')}: {surgeryGuideNumber}
                      </div>
                    </div>
                  ))}
                {noMenu ? (
                  <img src="images/icon_arrow_normal.svg" alt="" className="image_arrow_list image-arrow-list" />
                ) : (
                  <div className="div-block-121">
                    {surgery.statistic && <SurgeryListItemStats stats={surgery.statistic} />}
                    <div className="btn_show_detail">
                      <Popover trigger={<ArrowDotsVerticalIcon direction={!hover} />}>
                        {({ handleClose, isOpen }) => (
                          <SurgeryListItemMenu
                            handleClose={handleClose}
                            isOpen={!!isOpen}
                            handleOpenDetails={onChange}
                            handleDelete={onDelete}
                          />
                        )}
                      </Popover>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {withStats && (
              <CollapsibleBox
                onChangeVisibility={onChangeContentVisibility}
                noArrow
                headClassName="div-block-158 surgery-list-expand"
                head={<div>{t('surgeryListItem.expand')}</div>}
              >
                {briefing ? <SurgeryBriefing briefing={briefing} /> : <></>}
              </CollapsibleBox>
            )}
          </>
        )}
      </HoverWrapper>
    );
  }
);

export default SurgeryListItem;
