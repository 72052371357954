import { FullPackageDTO, UpdatePackageDTO } from 'dto/package';
import { get, patch } from './common';

import { validate } from './validation';

export const getPackage = async (packageId: string): Promise<FullPackageDTO> => {
  const templates = await get('/package', { packageId }).then(response => response.data);
  return validate(FullPackageDTO, templates);
};

export const updatePackage = async (packageToUpdate: UpdatePackageDTO): Promise<FullPackageDTO> => {
  const updatedPackage = await patch('/package', packageToUpdate).then(response => response.data);
  return validate(FullPackageDTO, updatedPackage);
};
